import React, { useEffect, useState } from 'react'

import { Button, Input, message, Select } from 'antd'
import { transactionForDepositByJuniorReq } from '../../../../services/pools/poolsWrite';
import { useDispatch, useSelector } from 'react-redux';
import { amountConversion, getAmount, getDenomBalance } from '../../../../utils/coin';
import { comdex } from '../../../../config/network';
import { setBalanceRefresh } from '../../../../action/account';
import { getPoolInfoRequest } from '../../../../services/pools/poolsRead';
import { useParams } from 'react-router-dom';
import { tokenIcon } from '../../../../utils/icons';


const Invest = () => {

  const dispatch = useDispatch();
  const { cpoolId } = useParams();

  const address = useSelector(state => state.account.address);
  const refreshBalance = useSelector(state => state.account.refreshBalance);
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const balances = useSelector(state => state.account.balances);

  const AvailableAssetBalance = amountConversion(getDenomBalance(balances, "usdc") || 0, comdex?.coinDecimals, 1000000);


  // Query 
  const fetchPoolInfo = (poolId) => {
    setLoading(true)
    getPoolInfoRequest(poolId).then((res) => {
      console.log(res, "getPoolInfoRequests");
      // setTotalReceivable(res?.[0])

      setLoading(false)

    }).catch((error) => {
      setLoading(false)
      console.log(error);

    })
  }

  useEffect(() => {
    if (cpoolId) {
      fetchPoolInfo(Number(cpoolId))
    }
  }, [cpoolId])



  const handleSubmit = (address, amount, pool_id, denom) => {
    setLoading(true)
    transactionForDepositByJuniorReq(address, amount, pool_id, denom, (error, result) => {
      if (error) {
        message.error(error?.rawLog || "Transaction Failed")
        setLoading(false)
        return;
      }
      message.success(
        // < Snack
        //     message={variables[lang].tx_success}
        //     explorerUrlToTx={comdex?.explorerUrlToTx}
        //     hash={result?.transactionHash}
        // />
        "Transaction Success"
      )
      dispatch(setBalanceRefresh(refreshBalance + 1))
      setLoading(false)
      console.log(result, "result");
    })
  }


  return (
    <div>
      <div className="pool_invest_main_container">
        <div className="pool_invest_container">

          <div className="div-invest">
            <div className="div-invest-17">
              <div className="div-invest-18">
                <div className="column-invest">
                  <div className="div-invest-19">
                    <div className="div-invest-20">
                      <div className="div-invest-22">Available {AvailableAssetBalance}</div>
                    </div>
                    <div className="div-invest-23">
                      <div className="column">
                        <Select placeholder="Select Token" defaultValue="token1" dropdownClassName="comdex_select_dropdown" style={{ width: 180 }} className='comdex_select' >
                          <Select.Option value="token1">
                            <div className="token_with_icons">
                              <div className="icons"><img src="https://dapps-coins.comdex.one/USDCoin.svg" alt="@icon" /></div>
                              <div className="name">
                                <div className="token_name">USDC</div>
                                <div className="chain_name">Noble</div>
                              </div>
                            </div>
                          </Select.Option>
                        </Select>
                      </div>

                      <Input placeholder="0.00" min={0} type='number' className='comdex_input' onChange={(e) => setAmount(e.target.value)} />
                    </div>
                    <p style={{ textAlign: "start", color: "hsla(0, 0%, 0%, 0.696)", margin: "15px 0 0 0" }}>By clicking “Invest” below I hereby agree to Senior Pool Agreement. </p>
                    <Button type="primary"
                      className='btn-primary swap-button mt-3 mb-3'
                      // onClick={() => handleSubmit(address, getAmount(amount, 1000000), Number(cpoolId), "usdc")}
                      loading={loading}
                      disabled={loading}
                    >
                      Invest
                    </Button>
                  </div>
                </div>
                <div className="column-invest--2" style={{ width: "100%", height: "100%" }}>
                  <div className="div-invest-29">
                    <div className="div-invest-30">
                      <div className="column-invest-3">
                        <div dangerouslySetInnerHTML={{ __html: tokenIcon }} />
                      </div>
                      <div className="column-invest-4">
                        <div className="div-invest-31">
                          <div className="div-invest-32">Total Invested</div>
                          <div className="div-invest-33 value">-</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Invest