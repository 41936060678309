import React, { useEffect, useState } from "react";
import { Layout, Menu, Skeleton, theme } from "antd";
// import Header from "../../../components/Header";
import { Card, Col, Row } from 'antd';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
// import { fiatIcon, payableIcon, receiveableIcon, txReceiveIcon, txSendIcon } from "../../../utils/icons";
import Router from "../../../Router";
import { Link, useLocation } from "react-router-dom";
import { contactIcon, dashboardIcon, invoiceIcon, portofolioIcon, proformaIcon, sidebarSwapIcon } from "../../../utils/icons";

const RwaLayout = () => {

    const { Content, Sider } = Layout;
    const location = useLocation();

    const [collapsed, setCollapsed] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState([location.pathname]);



    const handleMenuClick = (e) => {
        setSelectedKeys([e.key]);
    };


    const items = [
        {
            icon: <div className="sidebar_icon" dangerouslySetInnerHTML={{ __html: dashboardIcon }} />,
            path: "/rdashboard",
            name: "Dashboard",
        },
        {
            icon: <div className="sidebar_icon" dangerouslySetInnerHTML={{ __html: invoiceIcon }} />,
            path: "/rinvoice",
            name: "Invoice",
        },
        // {
        //     icon: <div className="sidebar_icon" dangerouslySetInnerHTML={{ __html: proformaIcon }} />,
        //     path: "/rkyc",
        //     name: "KYC",
        // },
        // {
        // icon: <div className="sidebar_icon" dangerouslySetInnerHTML={{ __html: sidebarSwapIcon }} />,
        //     path: "/rswap",
        //     name: "Swap",
        // },
        {
            icon: <div className="sidebar_icon" dangerouslySetInnerHTML={{ __html: contactIcon }} />,
            path: "/rcontacts",
            name: "Contacts",
        },
    ].map((item, index) => ({
        key: item?.path,
        icon: item?.icon,
        label: <>
            <Link to={item?.path}>
                {item?.name}
            </Link>
        </>,
    }));

    // Use media query to determine when to collapse the Sider
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 600) {
                setCollapsed(true);
            } else {
                setCollapsed(false);
            }
        };

        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Remove event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            {/* <Header /> */}
            <Layout hasSider>
                <Sider
                    theme="light"
                    collapsible={false}
                    collapsed={collapsed}
                    onCollapse={(value) => setCollapsed(value)}
                    className="comdex_sidebar"
                >
                    <Menu
                        theme="light"
                        mode="inline"
                        defaultOpenKeys={selectedKeys}
                        selectedKeys={[location.pathname]}
                        onClick={handleMenuClick}
                        items={items}
                    />
                </Sider>
                <Layout >
                    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
                        <Router />
                    </Content>
                </Layout>
            </Layout>
        </>
    );
};
export default RwaLayout;
