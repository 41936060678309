import { CosmWasmClient } from "cosmwasm";
import { comdex, proformaContractAddress } from '../../config/network'

const configin = {
    chainId: comdex?.chainId,
    rpcEndpoint: comdex?.rpc,
};

export const getContactInfo = async (address) => {
    const client = await CosmWasmClient.connect(configin.rpcEndpoint);
    const config = await client.queryContractSmart(proformaContractAddress, { "get_contact_info": { "address": address } });
    return await config;
}