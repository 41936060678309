import React, { useState } from 'react'
import { Card, Col, Row } from 'antd';

const SeniorPoolsList = ({ allSeniorPools }) => {
    const [loading, setLoading] = useState(true)

    const InfoSection = ({ title, content }) => (
        <div className="info-section">
            <div className="info-title">{title}</div>
            <div className="info-content">{content}</div>
        </div>
    );

    return (
        <div>
            <div className="senior_pool_single_card_main_container">
                <div className="senior_pool_single_card_container">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Card hoverable className='pool_card'>
                                <div className="single_pool_cards">
                                    <section className="card">
                                        <header className="card-header">
                                            <div className="pool-info">
                                                <img loading="lazy" srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/630811a377593be83c3c2554ccdea99bd6680dc03b9a5d8f7352490a33c5655b?apiKey=0d50848586c3436880ef47e44d650c56&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/630811a377593be83c3c2554ccdea99bd6680dc03b9a5d8f7352490a33c5655b?apiKey=0d50848586c3436880ef47e44d650c56&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/630811a377593be83c3c2554ccdea99bd6680dc03b9a5d8f7352490a33c5655b?apiKey=0d50848586c3436880ef47e44d650c56&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/630811a377593be83c3c2554ccdea99bd6680dc03b9a5d8f7352490a33c5655b?apiKey=0d50848586c3436880ef47e44d650c56&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/630811a377593be83c3c2554ccdea99bd6680dc03b9a5d8f7352490a33c5655b?apiKey=0d50848586c3436880ef47e44d650c56&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/630811a377593be83c3c2554ccdea99bd6680dc03b9a5d8f7352490a33c5655b?apiKey=0d50848586c3436880ef47e44d650c56&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/630811a377593be83c3c2554ccdea99bd6680dc03b9a5d8f7352490a33c5655b?apiKey=0d50848586c3436880ef47e44d650c56&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/630811a377593be83c3c2554ccdea99bd6680dc03b9a5d8f7352490a33c5655b?apiKey=0d50848586c3436880ef47e44d650c56&" alt="Pool emblem" className="pool-img" />
                                                <div className="pool-details">
                                                    <h2 className="pool-name">RWA Senior Pool</h2>
                                                    <p className="pool-category">US Treasuries</p>
                                                </div>
                                            </div>
                                            <div className="pool-status">
                                                <span className="status-label">Open</span>
                                            </div>
                                        </header>
                                        <div className="card-content">
                                            <InfoSection title="Liquidity" content="1,101,337 USDC" />
                                            <InfoSection title="Variable APY" content="5.00%" />
                                            <InfoSection title="APR" content="5.00%" />
                                        </div>
                                    </section>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default SeniorPoolsList