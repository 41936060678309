
import { combineReducers } from "redux";
import account from './accounts'
import asset from './asset'
import proforma from "./proforma";

const app = combineReducers({
    account,
    asset,
    proforma
});


export default app;