import { Button, Form, Upload } from 'antd';
import axios from 'axios';
import React, { useState } from 'react'
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';


const IPFSUploader = ({ handleChange, beforeUpload }) => {
    const [fileUrl, setFileUrl] = useState("");



    const handleSubmit = async (e) => {
        console.log(e,"e");
        e.preventDefault();
        // console.log(fileUrl, "fileUrl");
        console.log(e.target.files[0], "fileUrl");
        try {
            const fileData = new FormData();
            // fileData.append("file", fileUrl)
            fileData.append("file", e.target.files[0])

            const responseData = await axios.post(
                'https://api.pinata.cloud/pinning/pinFileToIPFS',
                fileData,
                {
                    headers: {
                        'pinata_api_key': "618b02839c5b5f9ae29e",
                        'pinata_secret_api_key': "2c78dda7fc920a04cdfb5fb0a66b0960bb2a8331a68384518632ee13417706b5",
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            console.log(responseData, "responseData");
            const fileDataUrls = "https://gateway.pinata.cloud/ipfs/" + responseData.data.IpfsHash;
            console.log(fileDataUrls, "fileDataUrls");


        } catch (error) {
            console.log(error, "IPFS Error");
        }
    }



    return (
        <div>

            {/* <form>

                <input type="file" onChange={(e) => setFileUrl(e.target.files[0])} />
                <button type='submit' onClick={handleSubmit}>Upload</button>
            </form> */}

            <Form.Item
                name="upload"
                label="Upload"
                valuePropName="fileList"
                rules={[{ required: true, message: 'Please input your username!' }]}
                className="custom-form-item"
                getValueFromEvent={(e) => e && e.fileList}
                // onChange={(e) => handleChange(e)}
                // onChange={(e) => setFileUrl(e.target.files[0])}
                onChange={(e) => handleSubmit(e) }
            >
                <Upload name="docUri" beforeUpload={beforeUpload} listType="picture">
                    {/* <Upload name="docUri" listType="picture"> */}
                    <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
            </Form.Item>


        </div>
    )
}

export default IPFSUploader