import { CosmWasmClient } from "cosmwasm";
import { comdex, proformaContractAddress } from '../../config/network'

const configin = {
    chainId: comdex?.chainId,
    rpcEndpoint: comdex?.rpc,
};

export const getPendingContactRequest = async (address) => {
    const client = await CosmWasmClient.connect(configin.rpcEndpoint);
    const config = await client.queryContractSmart(proformaContractAddress, { "get_pending_contact_requests": { "address": address } });
    return await config;
}

export const getSentContactRequest = async (address) => {
    const client = await CosmWasmClient.connect(configin.rpcEndpoint);
    const config = await client.queryContractSmart(proformaContractAddress, { "get_sent_contact_requests": { "address": address } });
    return await config;
}

export const getAllContactRequest = async (address) => {
    const client = await CosmWasmClient.connect(configin.rpcEndpoint);
    const config = await client.queryContractSmart(proformaContractAddress, { "get_all_contacts": { "address": address } });
    return await config;
}