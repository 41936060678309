import React, { useEffect, useState } from "react";
import {
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
} from "@ant-design/icons";
import Router from "../../../Router";
import { Link, useLocation } from "react-router-dom";
import { Layout, Menu } from "antd";
import { governanceIcon, portofolioIcon, proformaIcon, sideBarPoolsIcon, transferIcon } from "../../../utils/icons";

const CommoditiesLayout = () => {

    const { Content, Sider } = Layout;
    const location = useLocation();

    const [collapsed, setCollapsed] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState([location.pathname]);


    const handleMenuClick = () => {
        const currentPath = location.pathname;

        // Check if the current path is a pool details route
        if (currentPath.startsWith("/cpools/juniorpooldetails") || currentPath.startsWith("/cpools/seniorpooldetails")) {
            // If the current path is a pool details route, set the selected key to "/cpools"
            setSelectedKeys(["/cpools"]);
        } else if (currentPath.startsWith("/cgovernance/proposal")) {
            // If the current path is a governance proposal route, set the selected key to "/cgovernance"
            setSelectedKeys(["/cgovernance"]);
        } else {
            // For other cases, set the selected key to the current path
            setSelectedKeys([currentPath]);
        }
    };



    // useEffect to update selectedKeys after it has been changed
    useEffect(() => {
        handleMenuClick()
    }, [location.pathname]);


    const items = [
        {
            icon: <div className="sidebar_icon" dangerouslySetInnerHTML={{ __html: portofolioIcon }} />,
            path: "/cportfolio",
            name: "Portfolio",
        },
        {
            icon: <div className="sidebar_icon" dangerouslySetInnerHTML={{ __html: sideBarPoolsIcon }} />,
            path: "/cpools",
            name: "Pools",
        },
        // {
        //     icon: <div className="sidebar_icon" dangerouslySetInnerHTML={{ __html: proformaIcon }} />,
        //     path: "/ckyc",
        //     name: "KYC",
        // },
        {
            icon: <div className="sidebar_icon" dangerouslySetInnerHTML={{ __html: transferIcon }} />,
            path: "/ctransfer",
            name: "Transfer",
        },
        {
            icon: <div className="sidebar_icon" dangerouslySetInnerHTML={{ __html: governanceIcon }} />,
            path: "/cgovernance",
            name: "Governance",
        },
        // {
        //     icon: <div className="sidebar_icon" dangerouslySetInnerHTML={{ __html: governanceIcon }} />,
        //     path: "/cmarket",
        //     name: "Market",
        // },
        // {
        //     icon: <div className="sidebar_icon" dangerouslySetInnerHTML={{ __html: governanceIcon }} />,
        //     path: "/chypersign",
        //     name: "HyperSign Kyc",
        // },
    ].map((item, index) => ({
        key: item?.path,
        // icon: React.createElement(item?.icon),
        icon: item?.icon,
        label: <>
            <Link to={item?.path}>
                {item?.name}
            </Link>
        </>,
    }));

    // Use media query to determine when to collapse the Sider
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 600) {
                setCollapsed(true);
            } else {
                setCollapsed(false);
            }
        };

        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Remove event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <Layout hasSider>
                <Sider
                    theme="light"
                    collapsible={false}
                    collapsed={collapsed}
                    onCollapse={(value) => setCollapsed(value)}
                    className="comdex_sidebar"
                >
                    <Menu
                        theme="light"
                        mode="inline"
                        defaultOpenKeys={selectedKeys}
                        // selectedKeys={[location.pathname]}
                        selectedKeys={selectedKeys}
                        onClick={handleMenuClick}
                        items={items}
                    >
                    </Menu>
                </Sider>
                <Layout >
                    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
                        <Router />
                    </Content>
                </Layout>
            </Layout>
        </>
    );
};
export default CommoditiesLayout;
