import React from 'react'
import { Card } from 'antd';
import './index.scss'
import { commoditiesLogo, commodotiesIcon, dummyIcon, rwaPayLogo, product1Logo, product2Logo } from '../../utils/icons';
import { Link } from 'react-router-dom';
import RWALogo from '../../assets/images/RWA_logo.svg'
import CommoditiesLogo from '../../assets/images/comodities_logo.svg'
// import Logo_Banner from '../../assets/images/logo_light_mode.png'
import Logo_Banner from '../../assets/images/home_banner_light_logo.svg'
import AnatraCredLogo from '../../assets/images/app_logo_1_light.svg'
import AnatraFiLogo from '../../assets/images/app_logo_2_light.svg'
import Logo from '../../assets/images/shipFi_logo.svg'
import App1 from '../../assets/images/app1.png'
import Home1_Loader from '../../assets/Lottefile/home_1.json'
import Home2_Loader from '../../assets/Lottefile/home_2.json'
import Lottie from 'react-lottie';

const HomePage = () => {
    const { Meta } = Card;

    const App1_Options = {
        loop: true,
        autoplay: true,
        animationData: Home1_Loader,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    const App2_Options = {
        loop: true,
        autoplay: true,
        animationData: Home2_Loader,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    return (
        <>
            {/* <div className="homepage_main_container">
                <div className="home_page">
                    <div className="heading">Real World Asset </div>
                    <div className="cards_container">
                        <Link to="/rdashboard">
                            <div className="card">
                                <Card
                                    hoverable
                                    style={{
                                        width: 222,
                                        height: 222,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                                >
                                    <Meta title={
                                        <>
                                            <div className="icon">
                                                <img src={RWALogo} alt="RWAIcon" />
                                            </div>
                                        </>
                                    }
                                        description="RWA Payments"
                                        style={{ textAlign: "center" }}
                                    />
                                </Card>
                            </div>
                        </Link>

                        <Link to="/cportfolio">
                            <div className="card">
                                <Card
                                    hoverable
                                    style={{
                                        width: 222,
                                        height: 222,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                                >
                                    <Meta title={
                                        <>
                                            <div className="icon">
                                                <img src={CommoditiesLogo} alt="CommoditiesLogo" />
                                            </div>
                                        </>
                                    }
                                        description="RWA Lending"
                                        style={{ textAlign: "center" }} />
                                </Card>
                            </div>
                        </Link>
                    </div>
                </div>
            </div> */}

            <div className="homepage_main_container">
                <div className="homepage_container ">


                    {/* Banner Container  */}

                    <div className="banner_main_container">
                        <div className="banner_container">
                            <div className="heading">Streamlining finance - credit & payment</div>
                            <div className="para">Empowering users with seamless access to credit and payment capabilities</div>
                            {/* <div className="app_icon"> Only on <span><img src={Logo_Banner} alt="Logo" /></span></div> */}
                            <div className="app_icon"><span><img src={Logo_Banner} alt="Logo" /></span></div>
                        </div>
                    </div>

                    {/* Products Container  */}

                    <div className="products_main_container">
                        <div className="product_heading_title">Our Products</div>
                        <div className="productus_container">
                            {/* <Link to="/rdashboard" style={{ width: "100%" }}> */}
                            <Link to="/cportfolio" style={{ width: "100%" }}>
                                <Card hoverable className='landing_cards' style={{ cursor: "pointer" }}>
                                    <main className="product_main_container">
                                        <div className="product_container">
                                            <div className="content_left">

                                                <div className="product_title">
                                                    <div className="icon">
                                                        {/* <div dangerouslySetInnerHTML={{ __html: product1Logo }} /> */}
                                                        {/* <img src={AnatraCredLogo} alt="Image" /> */}
                                                        <img src={AnatraFiLogo} alt="Image" />
                                                    </div>
                                                </div>
                                                <div className="product_desc">Offers real revenue-based yields and enables DeFi participants to gain enhanced exposure to tangible asset-backed yields, broadening investment avenues on-chain.</div>
                                                <div className="product_cdn">Enter App  &nbsp; 	&gt;&gt;  </div>
                                            </div>
                                            <div className="content_right">
                                                {/* <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/a6f47596336e7e35f3a37e1eb233946ed446c6804456f3fb4b3ff349f5b41063?apiKey=0d50848586c3436880ef47e44d650c56&" alt="Hero image" className="hero-image" /> */}
                                                <Lottie
                                                    options={App1_Options}
                                                    height={180}
                                                    width={250}
                                                />
                                            </div>
                                        </div>
                                    </main>
                                </Card>
                            </Link>
                            {/* <Link to="/cportfolio" style={{ width: "100%" }}> */}
                            <Link to="/rdashboard" style={{ width: "100%" }}>
                                <Card hoverable className='landing_cards landing_cards_2' style={{ cursor: "pointer", height: "100%" }}>
                                    <main className="product_main_container">
                                        <div className="product_container">
                                            <div className="content_left">

                                                <div className="product_title">
                                                    <div className="icon">
                                                        {/* <div dangerouslySetInnerHTML={{ __html: product2Logo }} /> */}
                                                        {/* <img src={AnatraFiLogo} alt="Image" /> */}
                                                        <img src={AnatraCredLogo} alt="Image" />
                                                    </div>
                                                </div>
                                                <div className="product_desc">It offers instant cross-border settlements, both on and off-chain, eliminating delays and simplifying off-ramping for a seamless payment experience.</div>
                                                <div className="product_cdn">Enter App &nbsp; 	&gt;&gt; </div>
                                            </div>
                                            <div className="content_right">
                                                {/* <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/a6f47596336e7e35f3a37e1eb233946ed446c6804456f3fb4b3ff349f5b41063?apiKey=0d50848586c3436880ef47e44d650c56&" alt="Hero image" className="hero-image" /> */}
                                                <Lottie
                                                    options={App2_Options}
                                                    height={180}
                                                    width={250}
                                                />
                                            </div>
                                        </div>
                                    </main>
                                </Card>
                            </Link>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}

export default HomePage