import React, { useEffect, useRef } from 'react'
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import ReactHighcharts from 'react-highcharts';
import './index.scss'
import { Empty, Table } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import NoData from '../../../components/NoData';
import { fiatIcon, receiveableIcon, tokenIcon } from '../../../utils/icons';

const CPortofolio = () => {

    const lineChart1 = {
        chart: {
            // type: "line",
            type: 'spline',

            backgroundColor: null,
            height: "250",
            // width: "100",
            style: {
                fontFamily: 'Montserrat'
            },
            className: "dashboard_charts",
        },
        className: "dashboard_charts",
        credits: {
            enabled: false,
        },
        title: {
            text: null,
        },
        tooltip: {
            enabled: true,
        },
        legend: {
            enabled: false, // Disable the legend
        },
        plotOptions: {

            line: {
                showInLegend: false,
                lineWidth: 1,
                lineColor: "#516FB6",
                fillColor: false,
                marker: {
                    enabled: false,
                    symbol: 'circle',
                    radius: 8,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                }
            },
            spline: {
                lineWidth: 2,
                marker: {
                    enabled: false
                },
            }
        },
        yAxis: {
            // gridLineColor: "transparent",
            gridLineDashStyle: "dot",
            lineWidth: 0,
            lineColor: '#84CEE9',
            title: {
                text: null,
            },
            labels: {
                style: {
                    color: '#111111',
                    fontSize: 14,
                    fontWeight: 600
                }
            }
        },
        xAxis: {
            categories: ['05/11', '05/11', '05/11', '05/11', '05/11', '05/11', '05/11'],
            className: 'chart-x-lebel',
            lineWidth: 0,
            lineColor: '#84CEE9',
            labels: {
                style: {
                    color: '#111111',
                    fontSize: 14,
                    fontWeight: 600
                }
            }
        },
        series: [
            {
                data: [1800, 5100, 2800, 6500, 4000, 5500],
                lineColor: {
                    linearGradient: [0, '100%', 0, '20%'],
                    stops: [
                        // [0, '#0FBFFF00'],
                        [1, '#2D60FF']
                    ]
                },
                // enableMouseTracking: false,
            },
        ],
    };

    // const config = {



    // chart: {
    //     type: 'pie',
    //     animation: {
    //         duration: 1000,
    //         easing: 'easeOutBounce' // Custom animation effect
    //     }
    // },
    //     title: {
    //         text: 'Departamental Strength of the Company',
    //         align: 'left'
    //     },
    //     subtitle: {
    //         text: 'Custom animation of pie series',
    //         align: 'left'
    //     },
    //     tooltip: {
    //         pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    //     },
    //     accessibility: {
    //         point: {
    //             valueSuffix: '%'
    //         }
    //     },
    //     plotOptions: {
    //         pie: {
    //             allowPointSelect: true,
    //             // borderWidth: 2,
    //             cursor: 'pointer',
    //             dataLabels: {
    //                 enabled: true,
    //                 format: '<b>{point.name}</b><br>{point.percentage}%',
    //                 distance: 20
    //             }
    //         }
    //     },


    //     series: [{
    //         // Disable mouse tracking on load, enable after custom animation
    //         enableMouseTracking: false,
    //         animation: {
    //             duration: 2000
    //         },
    //         colorByPoint: true,
    //         // name: 'Brands',
    //         data: [{
    //             name: 'Customer Support',
    //             y: 21.3,
    //         }, {
    //             name: 'Development',
    //             y: 18.7
    //         }, {
    //             name: 'Sales',
    //             y: 20.2
    //         }, {
    //             name: 'Marketing',
    //             y: 14.2
    //         }, {
    //             name: 'Other',
    //             y: 25.6
    //         }]
    //     }]


    //     // chart: {
    //     //     type: 'pie',
    //     //     animation: {
    //     //         duration: 1000,
    //     //         easing: 'easeOutBounce' // Custom animation effect
    //     //     }
    //     // },
    //     // title: {
    //     //     text: 'Custom Entrance Animation'
    //     // },
    //     // plotOptions: {
    //     //     pie: {
    //     //         allowPointSelect: true,
    //     //         cursor: 'pointer',
    //     //         dataLabels: {
    //     //             enabled: true,
    //     //             format: '<b>{point.name}</b>: {point.percentage:.1f} %'
    //     //         }
    //     //     }
    //     // },
    //     // series: [{
    //     //     name: 'Brands',
    //     //     colorByPoint: true,
    //     //     data: [{
    //     //         name: 'Chrome',
    //     //         y: 61.41,
    //     //         sliced: true,
    //     //         selected: true
    //     //     }, {
    //     //         name: 'Internet Explorer',
    //     //         y: 11.84
    //     //     }, {
    //     //         name: 'Firefox',
    //     //         y: 10.85
    //     //     }, {
    //     //         name: 'Edge',
    //     //         y: 4.67
    //     //     }, {
    //     //         name: 'Safari',
    //     //         y: 4.18
    //     //     }, {
    //     //         name: 'Other',
    //     //         y: 7.05
    //     //     }]
    //     // }]
    // };

    const PieChart1 = {
        chart: {
            type: 'pie',
            margin: 10,
            height: 180,
            width: 250,
            backgroundColor: null,
            animation: {
                // duration: 1000,
                // easing: 'easeOutBounce' // Custom animation effect
            }
        },
        credits: {
            enabled: false,
        },
        title: {
            text: null,
        },
        plotOptions: {
            pie: {
                showInLegend: false,
                size: "110%",
                borderWidth: 2,
                innerSize: "74%",
                className: "pie-chart totalvalue-chart",
                dataLabels: {
                    enabled: false,
                    distance: -14,
                    style: {
                        fontsize: 50,
                    },
                },
            },
        },
        tooltip: {
            formatter: function () {
                return (
                    '<div style="text-align:center; font-weight:800; ">' +
                    // Number(calculateTotalVotes(amountConversion(Number(this.y) || 0, 6) || 0)) + " %" +
                    this.y + " %" +
                    "<br />" +
                    '<small style="font-size: 10px; font-weight:400;">' +
                    this.point.name +
                    "</small>" +
                    "</div>"
                );
            },
            useHTML: true,
            backgroundColor: "#232231",
            borderColor: "#fff",
            borderRadius: 10,
            zIndex: 99,
            style: {
                color: "#fff",
            },
        },
        series: [
            {
                states: {
                    hover: {
                        enabled: true,
                    },
                },
                name: "",
                // data: topProposalData && topProposalData?.map((item, index) => {
                //     if (index <= 3) {
                //         return ({
                //             name: item?.pair_name === "" ? `${denomToSymbol(item?.base_coin)}/${denomToSymbol(item?.quote_coin)} ` : item?.pair_name,
                //             y: Number(item?.total_vote),
                //             // color: getColor(index),
                //             // color: getColor(index),
                //         })
                //     }
                // })
                data: [
                    {
                        name: 'Customer Support',
                        y: 21.3,
                    },
                    {
                        name: 'Sales',
                        y: 20.2
                    }, {
                        name: 'Marketing',
                        y: 14.2
                    },
                ]
            },
        ],
    };

    const dataSource = [
        // {
        //     key: '1',
        //     token: 'LPTOKEN',
        //     tokenPrice: "1,932 USDC",
        //     position: "1,932 USDC",
        //     marketValue: "1,932 USDC",
        //     poolStatus: "1,932 USDC",
        // },
    ];

    const columns = [
        {
            title: 'Token',
            dataIndex: 'token',
            key: 'token',
        },
        {
            title: 'Token Price',
            dataIndex: 'tokenPrice',
            key: 'tokenPrice',
        },
        {
            title: 'Positions',
            dataIndex: 'position',
            key: 'position',
        },
        {
            title: 'Market Value',
            dataIndex: 'marketValue',
            key: 'marketValue',
        },
        {
            title: 'Pool Status',
            dataIndex: 'poolStatus',
            key: 'poolStatus',
        },
    ];

    const myPoolColumn = [
        {
            title: 'Pool Id',
            dataIndex: 'poolId',
            key: 'poolId',
            width: 150,
        },
        {
            title: 'Value Locked',
            dataIndex: 'valueLocked',
            key: 'valueLocked',
            width: 200,
        },
        {
            title: 'APR',
            dataIndex: 'apr',
            key: 'apr',
            width: 200,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 80,
        },
    ]

    const myPoolDataSource = [
        {
            key: '1',
            poolId: '#02',
            valueLocked: "1,722 USDC",
            apr: "5%",
            status: <>
                <div className="status_main_container">
                    <div className="status_container success_container">
                        Open
                    </div>
                </div>
            </>,
        },
        {
            key: '2',
            poolId: '#03',
            valueLocked: "1,138 USDC",
            apr: "6%",
            status: <>
                <div className="status_main_container">
                    <div className="status_container success_container">
                        Open
                    </div>
                </div>
            </>,
        },
    ];

    const investedDataSource = [
        {
            key: '1',
            poolId: '#03',
            valueLocked: "2,532 USDC",
            apr: "4%",
            status: <>
                <div className="status_main_container">
                    <div className="status_container success_container">
                        Open
                    </div>
                </div>
            </>,
        },
        {
            key: '2',
            poolId: '#04',
            valueLocked: "1,218 USDC",
            apr: "5%",
            status: <>
                <div className="status_main_container">
                    <div className="status_container failed_container">
                        Closed
                    </div>
                </div>
            </>,
        },
    ];

    // const CustomNoDataIcon = () => (
    //     <Empty
    //         image={NoData}
    //         imageStyle={{
    //             height: 110,
    //         }}
    //         description={<span></span>}
    //     />
    // );



    return (
        <>
            <div className="cportifolio_main_container">
                <div className="cportifolio_container">
                    <div className="cportofolio_overview_container ">
                        <div className="card_title" style={{ marginTop: "0", marginBottom: "1rem" }}>
                            Overview
                        </div>
                        <div className="card_container">
                            {/* <div className="title">Current Portfolio Value</div>
                            <div className="value">1,101,337 USDC</div>
                            <div className="cportofolio_overview_graph_container">
                                <HighchartsReact highcharts={Highcharts} options={lineChart1} />
                            </div> */}
                            <div className="row_container">
                                <div className="graph_box">
                                    <HighchartsReact highcharts={Highcharts} options={PieChart1} />
                                    {/* <ReactHighcharts config={config} highcharts={Highcharts} /> */}
                                </div>

                                <div className="all_asset_box">
                                    <div className="icon">
                                        <div dangerouslySetInnerHTML={{ __html: tokenIcon }} />
                                    </div>
                                    <div className="text">
                                        <div className="title">All Assets</div>
                                        <div className="value">
                                            <ul>
                                                <li>
                                                    <div className="left">
                                                        <div className="color_box" style={{ backgroundColor: `#00E272` }}></div>
                                                        <div className="token_name"><img src="https://dapps-coins.comdex.one/cmdx.svg" alt="Icon" /> CMDX</div>

                                                    </div>
                                                    <div className="right">
                                                        <div className="name">
                                                            <div className="token_quantity">344,568.90</div>
                                                            <div className="dollor_value">$459,90</div>
                                                        </div>

                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="left">
                                                        <div className="color_box" style={{ backgroundColor: `#2CAFFD` }}></div>
                                                        <div className="token_name"><img src="https://dapps-coins.comdex.one/harbor.svg" alt="Icon" /> HARBor</div>

                                                    </div>
                                                    <div className="right">
                                                        <div className="name">
                                                            <div className="token_quantity">344,568.90</div>
                                                            <div className="dollor_value">$459,90</div>
                                                        </div>

                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="left">
                                                        <div className="color_box" style={{ backgroundColor: `blue` }}></div>
                                                        <div className="token_name"><img src="https://dapps-coins.comdex.one/cmst.svg" alt="Icon" /> CMST</div>

                                                    </div>
                                                    <div className="right">
                                                        <div className="name">
                                                            <div className="token_quantity">344,568.90</div>
                                                            <div className="dollor_value">$459,90</div>
                                                        </div>

                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="total_value_box">
                                    <div className="icon">
                                        <div dangerouslySetInnerHTML={{ __html: receiveableIcon }} />
                                    </div>
                                    <div className="text">
                                        <div className="title">Total Value</div>
                                        <div className="value">$129,761</div>
                                    </div>
                                </div>

                                <div className="portofolio_box mr-5">
                                    <div className="icon">
                                        <div dangerouslySetInnerHTML={{ __html: fiatIcon }} />
                                    </div>
                                    <div className="text">
                                        <div className="title">My Portfolio</div>
                                        <div className="value">255,892,865 <span>USD</span></div>
                                    </div>
                                </div>





                            </div>

                        </div>
                    </div>


                    {/* Invested pool   */}
                    <div className="cportofolio_myPool_container">
                        <div className="card_title" >
                            Invested Pools
                        </div>
                        <div className="tbale_container">
                            <Table
                                dataSource={investedDataSource}
                                columns={myPoolColumn}
                                pagination={false}
                                className='custom_table'
                                locale={{ emptyText: <NoData /> }}

                            />
                        </div>
                    </div>
                    {/* My pools   */}
                    <div className="cportofolio_myPool_container">
                        <div className="card_title" >
                            My Pool
                        </div>
                        <div className="tbale_container">
                            <Table
                                dataSource={myPoolDataSource}
                                columns={myPoolColumn}
                                pagination={false}
                                className='custom_table'
                                locale={{ emptyText: <NoData /> }}

                            />
                        </div>
                    </div>

                    {/* Holdings   */}
                    <div className="cportofolio_holding_container">
                        <div className="card_title" >
                            Holdings
                        </div>
                        <div className="tbale_container">
                            <Table
                                dataSource={dataSource}
                                columns={columns}
                                pagination={false}
                                className='custom_table'
                                locale={{ emptyText: <NoData /> }}

                            />
                        </div>
                    </div>

                    {/* Allocation  */}
                    <div className="cportofolio_allocation_container">
                        <div className="card_title" >
                            Allocations
                        </div>
                        <div className="tbale_container">
                            <Table
                                dataSource={dataSource}
                                columns={columns}
                                pagination={false}
                                className='custom_table'
                                locale={{ emptyText: <NoData /> }}
                            />
                        </div>

                    </div>

                    {/* Transaction  */}

                    <div className="cportofolio_transaction_history_container">
                        <div className="card_title" >
                            Transaction History
                        </div>
                        <div className="tbale_container">
                            <Table
                                dataSource={dataSource}
                                columns={columns}
                                pagination={false}
                                className='custom_table'
                                locale={{ emptyText: <NoData /> }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CPortofolio