import { Button, Tabs } from 'antd';
import React from 'react'
import { Link } from 'react-router-dom';
import About from './about';
import Invest from './invest';
import Withdraw from './withdraw';
import Overview from './overview';
import Repayments from './repayments';
import PoolPortofolio from './portofolio';
import Risk from './risk';

const SenoirPoolDetails = () => {


    const onChange = (key) => {
        console.log(key);
    };

    const items = [

        {
            key: '1',
            label: 'About',
            children: <About />,
        },
        {
            key: '4',
            label: 'Overview',
            children: <Overview />,
        },
        {
            key: '2',
            label: 'Invest',
            children: <Invest />,
        },
        {
            key: '3',
            label: 'Withdraw',
            children: <Withdraw />,
        },
        // {
        //     key: '5',
        //     label: 'Repayments',
        //     children: <Repayments />,
        // },
        // {
        //     key: '6',
        //     label: 'Portfolio',
        //     children: <PoolPortofolio />,
        // },
        // {
        //     key: '7',
        //     label: 'Risk',
        //     children: <Risk />,
        // },
    ];



    return (
        <div>
            <div className="senior_pool_detail_main_container">
                <div className="senior_pool_detail_container">
                    <div className="div">
                        <div className="div-2">
                            <img
                                loading="lazy"
                                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/0d5eda23ca0e4773bdf3e86f6582e0c1a81acba6f2289fe2e0c4a7af3206320d?apiKey=78d75311bc524d2699f9343df12846a9&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/0d5eda23ca0e4773bdf3e86f6582e0c1a81acba6f2289fe2e0c4a7af3206320d?apiKey=78d75311bc524d2699f9343df12846a9&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0d5eda23ca0e4773bdf3e86f6582e0c1a81acba6f2289fe2e0c4a7af3206320d?apiKey=78d75311bc524d2699f9343df12846a9&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/0d5eda23ca0e4773bdf3e86f6582e0c1a81acba6f2289fe2e0c4a7af3206320d?apiKey=78d75311bc524d2699f9343df12846a9&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/0d5eda23ca0e4773bdf3e86f6582e0c1a81acba6f2289fe2e0c4a7af3206320d?apiKey=78d75311bc524d2699f9343df12846a9&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0d5eda23ca0e4773bdf3e86f6582e0c1a81acba6f2289fe2e0c4a7af3206320d?apiKey=78d75311bc524d2699f9343df12846a9&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/0d5eda23ca0e4773bdf3e86f6582e0c1a81acba6f2289fe2e0c4a7af3206320d?apiKey=78d75311bc524d2699f9343df12846a9&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/0d5eda23ca0e4773bdf3e86f6582e0c1a81acba6f2289fe2e0c4a7af3206320d?apiKey=78d75311bc524d2699f9343df12846a9&"
                                className="img"
                            />
                            <div className="div-3">
                                <div className="div-4">
                                    <div className="div-5">Senior Pool</div>
                                    <Link to="/cpools">
                                        <Button type="primary" className='btn-primary swap-button'>
                                            Back
                                        </Button>
                                    </Link>
                                </div>
                                <div className="div-7">by Treasury Capital SPC Limited</div>
                            </div>
                        </div>
                        <div className="div-8">
                            <Tabs
                                defaultActiveKey="1"
                                items={items}
                                onChange={onChange}
                                // className='div-9'
                                className='comdex_tabs'
                                style={{ width: "100%" }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SenoirPoolDetails