import React from 'react'
import './index.scss'
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import MarketOffers from './openMarket';
import { Tabs } from 'antd';
import OpenMarket from './openMarket';
import './index.scss';
import MyAuctions from './auctions';

const Market = () => {

    const onChange = (key) => {
        console.log(key);
    };

    const items = [
        {
            key: '1',
            label: 'Open Market',
            children: <OpenMarket />,
        },
        {
            key: '2',
            label: 'My Auctions',
            children: <MyAuctions />,
        },
    ];

    return (
        <>
            <div className="invoice_main_container">
                <div className="invoice_container">
                    <div className="invoice_tab_container">
                        <Tabs
                            defaultActiveKey="1"
                            items={items}
                            onChange={onChange}
                            className='comdex_tabs'
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Market