import { Button, message } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setProformaCurrentStep } from '../../../action/proforma';
import { transactionForCreateProforma } from '../../../services/Proforma/proformaContractWrite';

const Review = ({ setFormData, formData, transactionStatus, setTransactionStatus }) => {
    const dispatch = useDispatch();
    const proformaCurrentStep = useSelector(state => state.proforma.proformaCurrentStep);
    const address = useSelector(state => state.account.address);


    const handleBack = () => {
        dispatch(setProformaCurrentStep(proformaCurrentStep - 1))


    }
    const handleNext = () => {
        setTransactionStatus("processing")
        dispatch(setProformaCurrentStep(proformaCurrentStep + 1))

    }

    console.log(formData, "formData in review");



    const handleSubmit = () => {
        handleNext()
        transactionForCreateProforma(address, formData?.fullname, formData?.email, formData?.number, formData?.companyName, formData?.addressLine1, (error, result) => {
            if (error) {
                message.error(error?.rawLog || "Transaction Failed")
                // setLoading(false)
                setTransactionStatus("failed")
                return;
            }
            message.success(
                // < Snack
                //     message={variables[lang].tx_success}
                //     explorerUrlToTx={comdex?.explorerUrlToTx}
                //     hash={result?.transactionHash}
                // />
                "Transaction Success"
            )
            setTransactionStatus("success")
            console.log(result, "result");
            // dispatch(setAmountIn());
            // setVeHarbor(0)
            // setBalanceRefresh(refreshBalance + 1);
            // setLoading(false)
        })
    }


    return (
        <div>
            <div className="proforma_review_main_container">
                <div className="proforma_review_container">
                    <div className="row_container">
                        <div className="single_row">
                            <div className="title">Name</div>
                            <div className="value">{formData?.fullname}</div>
                        </div>
                        <div className="single_row">
                            <div className="title">Email</div>
                            <div className="value">{formData?.email}</div>
                        </div>
                        <div className="single_row">
                            <div className="title">Phone Number</div>
                            <div className="value">{formData?.number}</div>
                        </div>
                        <div className="single_row">
                            <div className="title">Company Name</div>
                            <div className="value">{formData?.companyName}</div>
                        </div>
                        <div className="single_row">
                            <div className="title">Address</div>
                            <div className="value">{formData?.addressLine1}</div>
                        </div>
                        {/* <div className="single_row">
                            <div className="title">Incorporation Documents</div>
                            <div className="value">Astra Solutions.pdf</div>
                        </div>
                        <div className="single_row">
                            <div className="title">UBO passport copies</div>
                            <div className="value">Astra Solutions.pdf</div>
                        </div>
                        <div className="single_row">
                            <div className="title">Proof of Business Address</div>
                            <div className="value">Astra Solutions.pdf</div>
                        </div>
                        <div className="single_row">
                            <div className="title">Proof of Business License </div>
                            <div className="value">Astra Solutions.pdf</div>
                        </div> */}
                    </div>
                    <div className='btn_container mt-5'>
                        <Button type="primary" onClick={handleBack} className='btn-primary mr-2' style={{ margin: 0 }}>
                            Back
                        </Button>
                        <Button type="primary" onClick={handleSubmit} className='btn-primary' style={{ margin: 0 }}>
                            Next
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Review