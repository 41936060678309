import { CosmWasmClient } from "cosmwasm";
import { comdex, proformaContractAddress } from '../../config/network'

const configin = {
    chainId: comdex?.chainId,
    rpcEndpoint: comdex?.rpc,
};

export const getTotalReceivables = async (address) => {
    const client = await CosmWasmClient.connect(configin.rpcEndpoint);
    const config = await client.queryContractSmart(proformaContractAddress, { "get_total_receivables": { "address": address } });
    return await config;
}

export const getTotalPayables = async (address) => {
    const client = await CosmWasmClient.connect(configin.rpcEndpoint);
    const config = await client.queryContractSmart(proformaContractAddress, { "get_total_payables": { "address": address } });
    return await config;
}
