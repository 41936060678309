import axios from "axios";
import { comdex } from "../../config/network";
import { API_URL } from "../../config/url";
import { createQueryClient } from "../helper";
import { CosmWasmClient } from "cosmwasm";
import { envConfig } from "../../config/envConfig";

export const fetchPaymentDetails = (address, callback) => {
    axios
      .get(`${API_URL}/pay_invoices/${address}`)
      .then((result) => {
        callback(null, result?.data);
      })
      .catch((error) => {
        callback(error?.message);
      });
  };

  export const fetchPaymentDetailsDateBounded = (address, fromDate, toDate, callback) => {
    axios
      .get(`${API_URL}/pay_invoices/${address}/${fromDate}/${toDate}`)
      .then((result) => {
        callback(null, result?.data);
      })
      .catch((error) => {
        callback(error?.message);
      });
  };