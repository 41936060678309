import { Button, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { ISSUER_DID, ISSUER_DID_KEY, ISSUER_DID_VERIFICATION_METHOD, ISSUER_DID_VERIFICATION_METHOD_KEY, KYC_ACCESS_TOKEN, KYC_ACCESS_TOKEN_KEY, SSI_ACCESS_TOKEN, SSI_ACCESS_TOKEN_KEY } from '../../../constants/common';
import { withSuccess } from 'antd/es/modal/confirm';

const Hypersign = ({ checked }) => {
    const widgetBaseUrl = "https://hypersign-kyc-widget.netlify.app";
    const kycTenantUrl = 'https://ent-4c71874.api.cavach.hypersign.id';
    const consentAPIpath = '/api/v1/e-kyc/verification/user-consent';

    const [idTokenGbl, setIdTokenGbl] = useState("");

    

    const handleHyperSignWidget = () => {
        const url = getWidgetUrl();
        console.log(url, "URL");
        const popup = window.open(url, 'Popup Window', 'width=850,height=870');

    };
    const getKYCAccessToken = () => {
        return KYC_ACCESS_TOKEN_KEY;
    };

    const getWidgetUrl = () => {
        const issuerDID = ISSUER_DID_KEY;
        const issuerDIDVerificationMethod = ISSUER_DID_VERIFICATION_METHOD_KEY;
        const reason = "The Verifier needs these credentials to allow you access";
        const cavachAccessToken = getKYCAccessToken();
        const ssiAccessToken = SSI_ACCESS_TOKEN_KEY;

        const presentationRequest = {
            "query": [
                {
                    "type": "QueryByExample",
                    "credentialQuery": [
                        {
                            "example": {
                                "type": "PersonhoodCredential",
                                "credentialSchema": {
                                    "id": "sch:hid:testnet:z6Mkvtd73dDgg7HU8wLCmXbe2RAHPAU1Ex1VUXCFtPV7u36i:1.0"
                                }
                            },
                            "trustedIssuer": [
                                {
                                    "required": true,
                                    "issuer": "did:hid:testnet:zCyAz2wfKjAaWE4FW75KxpZh2wuo9kRAUZyV2xEe93cKr"
                                }
                            ]
                        }
                    ]
                },
                {
                    "type": "QueryByExample",
                    "credentialQuery": [
                        {
                            "example": {
                                "type": "PassportCredential",
                                "credentialSchema": {
                                    "id": "sch:hid:testnet:z6MkgMXXQL7YD7BufNLbjrwueoj4nmih9xujJ6aozJDmzFWx:1.0"
                                }
                            },
                            "trustedIssuer": [
                                {
                                    "required": true,
                                    "issuer": "did:hid:testnet:zCyAz2wfKjAaWE4FW75KxpZh2wuo9kRAUZyV2xEe93cKr"
                                }
                            ]
                        }
                    ]
                }
            ],
            "reason": reason,
            "issuerDID": issuerDID,
            "issuerDIDVerificationMethod": issuerDIDVerificationMethod,
            "domain": window.location.href,
            "logoUrl": "https://i.ibb.co/xmxv8kw/Screenshot-2024-03-12-at-3-18-37-PM.png"
        };

        const presentationRequestStr = JSON.stringify(presentationRequest);
        const prBase64 = btoa(presentationRequestStr);
        const finalUrl = `${widgetBaseUrl}?kycAccessToken=${cavachAccessToken}&ssiAccessToken=${ssiAccessToken}&pr=${prBase64}`;
        return finalUrl;
    };

    const fetchUserData = async (tokenID) => {
        try {
            const idToken = tokenID;
            const url = `${kycTenantUrl}${consentAPIpath}?idToken=${idToken}`;
            const headers = {
                'Authorization': 'Bearer ' + getKYCAccessToken(),
                'content-type': 'application/json'
            };

            console.log(url, "url for fetch user data");
            const resp = await fetch(url, { headers });
            const json = await resp.json();
            console.log(json, 'fetchUserData');
        } catch (e) {
            console.error(e, "fetchUserData error");
        }
    };

    // fetchUserData("vp:hid:z4ZskMa8z9fSuEFC2Q61FkeZuuW9XVxQnhBfFVbyocmsm")
    useEffect(() => {
        const handleReceiveMessage = (event) => {
            try {
                const data = JSON.parse(event?.data);
                console.log(data, "Data for fetch user token id");
                console.log("Event listener entered");
                if (data.status === 'success') {
                    setIdTokenGbl(data.idToken);
                    fetchUserData(data.idToken);
                    message.success("Kyc Successfull")
                } else if (data.status === 'fail') {
                    alert("Failed to verify the user, please try again!");
                    message.error("Kyc Failed")
                }
            } catch (error) {
                console.error("Error parsing JSON data:", error);
                // Handle the error accordingly, for example:
                // alert("Invalid data received from the popup!");
            }
        };

        window.addEventListener('message', handleReceiveMessage);

        return () => {
            window.removeEventListener('message', handleReceiveMessage);
        };

    }, []);

    return (
        <div>
            <Button disabled={!checked} onClick={() => handleHyperSignWidget()} type='primary'>Start</Button>
        </div>
    );
}

export default Hypersign;
