import React, { useEffect, useState } from 'react'
import './index.scss'
import { Link } from 'react-router-dom'
import { getAllPools } from '../../../services/pools/poolsRead'
import { useSelector } from 'react-redux'
import { amountConversionWithComma } from '../../../utils/coin'
import { comdex } from '../../../config/network'
import SeniorPoolsList from './seniorPool'
import JuniorPoolList from './juniorPool'
import { poolIcon, tokenIcon } from '../../../utils/icons'
import { Spin } from 'antd'
import CardSkeletonLoading from '../../../components/CardSkelton'

const CPools = () => {

  const address = useSelector(state => state.account.address);
  const [loading, setLoading] = useState(false);
  const [allJuniorPools, setAllJuniorPools] = useState([]);
  const [allSeniorPools, setAllSeniorPools] = useState([]);

  // Query 
  const fetchAllPools = (address) => {
    setLoading(true)
    getAllPools(address).then((res) => {
      console.log(res, "All Pools");
      // setTotalReceivable(res?.[0])
      setAllJuniorPools(res?.data)
      setLoading(false)

    }).catch((error) => {
      setLoading(false)
      console.log(error);

    })
  }


  useEffect(() => {
    if (address) {
      fetchAllPools(address)
    }
  }, [address])


  return (
    <div className="div-pool-1 mb-3">
      <div className="div-pool-2">
        <div className="div-pool-3">
          <div className="column-pool">
            <div className="div-pool-4">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/75f7a375393a191c47c87776dc19782617ba31fdabdf46360935ba9b38ea48f8?apiKey=78d75311bc524d2699f9343df12846a9&"
                className="img-pool"
              />
              <div className="div-pool-5">
                <div className="div-pool-6">Value locked</div>
                <div className="div-pool-7">1,336,346 USD</div>
              </div>
            </div>
          </div>
          <div className="column-pool-2">
            <div className="div-pool-8">
              {/* <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/66b37fb4-9a5d-47e3-ad50-dce06728d905?apiKey=78d75311bc524d2699f9343df12846a9&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/66b37fb4-9a5d-47e3-ad50-dce06728d905?apiKey=78d75311bc524d2699f9343df12846a9&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/66b37fb4-9a5d-47e3-ad50-dce06728d905?apiKey=78d75311bc524d2699f9343df12846a9&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/66b37fb4-9a5d-47e3-ad50-dce06728d905?apiKey=78d75311bc524d2699f9343df12846a9&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/66b37fb4-9a5d-47e3-ad50-dce06728d905?apiKey=78d75311bc524d2699f9343df12846a9&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/66b37fb4-9a5d-47e3-ad50-dce06728d905?apiKey=78d75311bc524d2699f9343df12846a9&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/66b37fb4-9a5d-47e3-ad50-dce06728d905?apiKey=78d75311bc524d2699f9343df12846a9&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/66b37fb4-9a5d-47e3-ad50-dce06728d905?apiKey=78d75311bc524d2699f9343df12846a9&"
                className="img-pool"
              /> */}
              <div dangerouslySetInnerHTML={{ __html: poolIcon }} />
              <div className="div-pool-9">
                <div className="div-pool-10">Credit Pools</div>
                <div className="div-pool-11">{allJuniorPools?.length || 0}</div>
              </div>
            </div>
          </div>
          {/*<div className="column-pool-3">
            <div className="div-pool-12">
              {/* <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/e6566507-1676-4af2-bf54-276767678320?apiKey=78d75311bc524d2699f9343df12846a9&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/e6566507-1676-4af2-bf54-276767678320?apiKey=78d75311bc524d2699f9343df12846a9&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e6566507-1676-4af2-bf54-276767678320?apiKey=78d75311bc524d2699f9343df12846a9&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/e6566507-1676-4af2-bf54-276767678320?apiKey=78d75311bc524d2699f9343df12846a9&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/e6566507-1676-4af2-bf54-276767678320?apiKey=78d75311bc524d2699f9343df12846a9&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e6566507-1676-4af2-bf54-276767678320?apiKey=78d75311bc524d2699f9343df12846a9&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/e6566507-1676-4af2-bf54-276767678320?apiKey=78d75311bc524d2699f9343df12846a9&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/e6566507-1676-4af2-bf54-276767678320?apiKey=78d75311bc524d2699f9343df12846a9&"
                className="img-pool"
              />
             <div dangerouslySetInnerHTML={{ __html: tokenIcon }} />
              <div className="div-pool-13">
                <div className="div-pool-14">Tokens</div>
                <div className="div-pool-15">1</div>
              </div>
            </div>
          </div>*/}
        </div>
      </div>
      <div className="div-pool-16">Senior Pool</div>
      <div className="div-pool-17">
        <div className="div-pool-18">


          {/* {allJuniorPools && allJuniorPools?.length > 0 && allJuniorPools?.map((item) => {
            return (
              <div key={item?.pool_id}>
                <Link to={"/cpools/cpooldetails/" + item?.pool_id}>
                  <div className="column-pool-4">
                    <div className="div-pool-19">
                      <div className="div-pool-20">
                        <div className="div-pool-21">
                          <img
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/e7e2a54a144abda318bccd3f97bdcd0957d85f4fd3fca9c63bf4e366b0387527?apiKey=78d75311bc524d2699f9343df12846a9&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/e7e2a54a144abda318bccd3f97bdcd0957d85f4fd3fca9c63bf4e366b0387527?apiKey=78d75311bc524d2699f9343df12846a9&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e7e2a54a144abda318bccd3f97bdcd0957d85f4fd3fca9c63bf4e366b0387527?apiKey=78d75311bc524d2699f9343df12846a9&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/e7e2a54a144abda318bccd3f97bdcd0957d85f4fd3fca9c63bf4e366b0387527?apiKey=78d75311bc524d2699f9343df12846a9&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/e7e2a54a144abda318bccd3f97bdcd0957d85f4fd3fca9c63bf4e366b0387527?apiKey=78d75311bc524d2699f9343df12846a9&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e7e2a54a144abda318bccd3f97bdcd0957d85f4fd3fca9c63bf4e366b0387527?apiKey=78d75311bc524d2699f9343df12846a9&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/e7e2a54a144abda318bccd3f97bdcd0957d85f4fd3fca9c63bf4e366b0387527?apiKey=78d75311bc524d2699f9343df12846a9&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/e7e2a54a144abda318bccd3f97bdcd0957d85f4fd3fca9c63bf4e366b0387527?apiKey=78d75311bc524d2699f9343df12846a9&"
                            className="img-pool-2"
                          />
                          <div className="div-pool-22">
                            <div className="div-pool-23">
                              {item?.pool_name}
                            </div>
                            <div className="div-pool-24">{item?.borrower_name}</div>
                          </div>
                        </div>
                        <div className="div-pool-25">Open</div>
                      </div>
                      <div className="div-pool-26">
                        <div className="div-pool-27">
                          <div className="div-pool-28">Value Locked</div>
                          <div className="div-pool-29">{amountConversionWithComma(Number(item?.junior_tranche?.principal_deposited) + Number(item?.senior_tranche?.principal_deposited), comdex?.coinDecimals) || 0} USDC</div>
                        </div>
                        <div className="div-pool-30">
                          <div className="div-pool-31">APR</div>
                          <div className="div-pool-32">{Number(item?.credit_line?.interest_apr) / 100}% </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            )
          })} */}


          <div className="pool_list_main_container">
            <div className="pool_list_container">
              <div className="senior_pool_list_container">
                <Link to={"/cpools/seniorpooldetails/" + 1}> <SeniorPoolsList allSeniorPools={allSeniorPools} /></Link>
              </div>
              {loading ? <div className="m-3"><CardSkeletonLoading /></div> :
                <div className="junior_pool_list_container">
                  <div className="div-pool-16 mb-3">Pools</div>
                  {/* {allJuniorPools && allJuniorPools?.length > 0 && allJuniorPools?.map((item, index) => {
                    return <React.Fragment key={index}>
                      <Link to={"/cpools/junoirpooldetails/" + item?.pool_id}><JuniorPoolList item={item} /></Link>
                    </React.Fragment>
                  })} */}

                  <JuniorPoolList poolList={allJuniorPools} />

                </div>
              }
            </div>
          </div>



        </div>
      </div>
    </div>
  )
}

export default CPools