import { Button, Input, message, Select } from 'antd'
import React, { useState } from 'react'
import { transactionForDrawdownReq, transactionForLockJuniorCapital, transactionForLockPool } from '../../../../services/pools/poolsWrite';
import { useDispatch, useSelector } from 'react-redux';
import { amountConversion, amountConversionWithComma, getAmount, getDenomBalance } from '../../../../utils/coin';
import { comdex } from '../../../../config/network';
import { setBalanceRefresh } from '../../../../action/account';
import { tokenIcon } from '../../../../utils/icons';
import { commaSeparator } from '../../../../utils/number';

const Drawdown = ({ poolData, cpoolId, AvailableAssetBalance, address, refreshBalance, balances }) => {
  const dispatch = useDispatch();

  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (address, pool_id, amount) => {
    setLoading(true)
    transactionForDrawdownReq(address, pool_id, amount, (error, result) => {
      if (error) {
        message.error(error?.rawLog || "Transaction Failed")
        setLoading(false)
        return;
      }
      message.success("Transaction Success")
      dispatch(setBalanceRefresh(refreshBalance + 1))
      setLoading(false)
    })
  }

  const handleLockJuniorCapital = (address, pool_id) => {
    setLoading(true)
    transactionForLockJuniorCapital(address, pool_id, (error, result) => {
      if (error) {
        message.error(error?.rawLog || "Transaction Failed")
        setLoading(false)
        return;
      }
      console.log(result, "handleLockJuniorCapital");
      message.success("Transaction Success")
      dispatch(setBalanceRefresh(refreshBalance + 1))
      setLoading(false)
    })
  }

  const handleLockPool = (address, pool_id) => {
    setLoading(true)
    transactionForLockPool(address, pool_id, (error, result) => {
      if (error) {
        message.error(error?.rawLog || "Transaction Failed")
        setLoading(false)
        return;
      }
      message.success("Transaction Success")
      console.log(result, "handleLockPool");
      dispatch(setBalanceRefresh(refreshBalance + 1))
      setLoading(false)
    })
  }

  return (
    <div className="div-invest">
      <div className="div-invest-17">
        <div className="div-invest-18">
          <div className="column-invest">
            <div className="div-invest-19">
              <div className="div-invest-20">
                <div className="div-invest-22">Available {amountConversionWithComma(poolData?.available_to_draw || 0, comdex?.coinDecimals, poolData?.asset_info?.decimal)} {poolData?.asset_info?.name}</div>
              </div>
              <div className="div-invest-23">
                <div className="column">
                  <Select suffixIcon={false} placeholder="Select Token" defaultValue="token1" dropdownClassName="comdex_select_dropdown" style={{ width: 180 }} className='comdex_select' >
                    <Select.Option value="token1">
                      <div className="token_with_icons">
                        <div className="icons"><img src="https://dapps-coins.comdex.one/USDCoin.svg" alt="@icon" /></div>
                        <div className="name">
                          <div className="token_name">{poolData?.asset_info?.name}</div>
                          <div className="chain_name">Noble</div>
                        </div>
                      </div>
                    </Select.Option>
                  </Select>
                </div>

                <Input placeholder="0.00" min={0} type='number' className='comdex_input' onChange={(e) => setAmount(e.target.value)} />
              </div>
              <div className="btn_container">
                {poolData?.junior_capital_locked === false &&
                  <Button type="primary" className='btn-primary swap-button mt-5 mb-2' onClick={() => handleLockJuniorCapital(address, Number(cpoolId))}
                    loading={loading}
                    disabled={
                      loading
                    }
                  >
                    Lock Junior Capital
                  </Button>
                }

                {poolData?.junior_capital_locked === true && poolData?.pool_locked === false &&
                  <Button type="primary" className='btn-primary swap-button mt-5 mb-2' onClick={() => handleLockPool(address, Number(cpoolId))}
                    loading={loading}
                    disabled={
                      loading
                    }
                  >
                    Lock Pool
                  </Button>
                }

                {poolData?.junior_capital_locked === true && poolData?.pool_locked === true &&
                  <Button type="primary" className='btn-primary swap-button mt-5 mb-2' onClick={() => handleSubmit(address, Number(cpoolId), getAmount(amount, poolData?.asset_info?.decimal))}
                    loading={loading}
                    disabled={
                      loading ||
                      !AvailableAssetBalance ||
                      !amount
                    }
                  >
                    Drawdown
                  </Button>
                }
                {/* <Button type="primary" className='btn-primary swap-button mt-5 mb-2' onClick={() => handleSubmit(address, Number(cpoolId), getAmount(amount, poolData?.asset_info?.decimal))}
                  loading={loading}
                  disabled={
                    loading ||
                    !AvailableAssetBalance ||
                    !amount
                  }
                >
                  Drawdown
                </Button> */}
                {/* <Button type="primary" className='btn-primary swap-button mt-5 mb-2' onClick={() => handleLockJuniorCapital(address, Number(cpoolId))}
                  loading={loading}
                  disabled={
                    loading
                  }
                >
                  Lock Junior Capital
                </Button> */}
                {/* <Button type="primary" className='btn-primary swap-button mt-5 mb-2' onClick={() => handleLockPool(address, Number(cpoolId))}
                  loading={loading}
                  disabled={
                    loading
                  }
                >
                  Lock Pool
                </Button> */}
              </div>


            </div>
          </div>

          <div className="column-2" style={{ width: "100%", height: "100%" }}>
            <div className="div-29">
              <div className="div-30">
                <div dangerouslySetInnerHTML={{ __html: tokenIcon }} />
                <div className="div-31">
                  <div className="div-32">Total Available</div>
                  <div className="div-33">{amountConversionWithComma(poolData?.available_to_draw || 0, comdex?.coinDecimals, poolData?.asset_info?.decimal)} {poolData?.asset_info?.name}</div>
                </div>
              </div>
              <div className="div-34">
                <div dangerouslySetInnerHTML={{ __html: tokenIcon }} />
                <div className="div-35">
                  <div className="div-36">Total Drawn</div>
                  <div className="div-37">{amountConversionWithComma(poolData?.drawn || 0, comdex?.coinDecimals, poolData?.asset_info?.decimal)} {poolData?.asset_info?.name}</div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Drawdown