import React from 'react'
import { Button, Checkbox, Form, Input, Select, Upload } from 'antd';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setProformaCurrentStep } from '../../../action/proforma';


const Documents = () => {
        const dispatch = useDispatch();
    const proformaCurrentStep = useSelector(state => state.proforma.proformaCurrentStep)

    const { Option } = Select;

    const onFinish = (values) => {
        console.log('Success:', values);
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleBack=()=>{
        dispatch(setProformaCurrentStep(proformaCurrentStep - 1))

        
    }
    const handleNext=()=>{
        dispatch(setProformaCurrentStep(proformaCurrentStep + 1))

    }
    return (
        <div>
            <div className="proforma_documents_main_container">
                <div className="proforma_documents_container">
                    <Form name="basic"
                        // style={{ maxWidth: 600, }}
                        layout="vertical"
                        initialValues={{ remember: true, }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        className='comdex_form'
                    >
                        <Form.Item
                            label="Incorporation Documents"
                            name="incorporationDocuments"
                            valuePropName="fileList"
                            rules={[{ required: true, message: 'Please input your username!' }]}
                            className="custom-form-item"
                        >
                            <Upload name="logo" action="/upload.do" listType="picture">
                                <Button icon={<UploadOutlined />}>Click to upload</Button>
                            </Upload>

                        </Form.Item>

                        <Form.Item
                            label="UBO passport copies"
                            name="uboPassportCopies"
                            valuePropName="fileList"
                            rules={[{ required: true, message: 'Please input your username!' }]}
                            className="custom-form-item"
                        >
                            <Upload name="logo" action="/upload.do" listType="picture">
                                <Button icon={<UploadOutlined />}>Click to upload</Button>
                            </Upload>

                        </Form.Item>

                        <Form.Item
                            label="Proof of Business Address"
                            name="proofOfBusinessAddress"
                            valuePropName="fileList"
                            rules={[{ required: true, message: 'Please input your username!' }]}
                            className="custom-form-item"
                        >
                            <Upload name="logo" action="/upload.do" listType="picture">
                                <Button icon={<UploadOutlined />}>Click to upload</Button>
                            </Upload>

                        </Form.Item>
                        <Form.Item
                            label="Proof of Business License "
                            name="proofOfBusinessLicence"
                            valuePropName="fileList"
                            rules={[{ required: true, message: 'Please input your username!' }]}
                            className="custom-form-item"
                        >
                            <Upload name="logo" action="/upload.do" listType="picture">
                                <Button icon={<UploadOutlined />}>Click to upload</Button>
                            </Upload>

                        </Form.Item>
                        <Form.Item
                            label="Proof of Business Address"
                            name="proofOfBusinessAddress"
                            valuePropName="fileList"
                            rules={[{ required: true, message: 'Please input your username!' }]}
                            className="custom-form-item"
                        >
                            <Upload name="logo" action="/upload.do" listType="picture">
                                <Button icon={<UploadOutlined />}>Click to upload</Button>
                            </Upload>

                        </Form.Item>


                        <Form.Item className='btn_container mt-5'>
                            <Button type="primary" onClick={handleBack} className='btn-primary mr-2'>
                                Back
                            </Button>
                            <Button type="primary" onClick={handleNext} className='btn-primary'>
                                Next
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>

        </div>
    )
}

export default Documents