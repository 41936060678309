import Decimal from 'decimal.js';
import { comdex } from '../config/network';
import { commaSeparator } from './number';

export const getAmount = (selectedAmount, coinDecimals) => {
    const decimalSelectedAmount = new Decimal(selectedAmount || 0);
    const decimalCoinDecimals = new Decimal(coinDecimals || 10 ** comdex.coinDecimals);
    const formattedAmount = decimalSelectedAmount.mul(decimalCoinDecimals);
    return formattedAmount.toString();
};

export const amountConversionWithComma = (amount, decimals, chainDecimals) => {
    const result = Number(amount) / (chainDecimals || 10 ** comdex.coinDecimals);
    return commaSeparator(Math.floor(result * Math.pow(10, decimals || comdex.coinDecimals)) / Math.pow(10, decimals || comdex.coinDecimals));
};

export const amountConversion = (amount, decimals, chainDecimals) => {
    const result = Number(amount) / ( chainDecimals || 10 ** comdex.coinDecimals);
    return String(Math.floor(result * Math.pow(10, decimals || comdex.coinDecimals)) / Math.pow(10, decimals || comdex.coinDecimals));
};

export const getDenomBalance = (balances, denom) =>
    balances &&
    balances.length > 0 &&
    balances.find((item) => item.denom === denom) &&
    balances.find((item) => item.denom === denom).amount;