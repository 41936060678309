import React from 'react'
import { fiatIcon, payableIcon, receiveableIcon } from "../../../../utils/icons";
import { Card, Col, Row } from 'antd';

const Overview = () => {
  return (
    <div>
      <div className="pool_overview_main_container">
        <div className="pool_overview_container">
          <div className="top_container">
            <div className="cards_container">
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <Card  bordered={false}>
                    <div className="dashboard_stats_card_container">
                      <div className="dashboard_stats_card">
                        <div className="left">
                          <div className="icon">
                            <div dangerouslySetInnerHTML={{ __html: fiatIcon }} />
                          </div>
                        </div>
                        <div className="right">
                          <div className="name">Total Pool Assets</div>
                          <div className="value">$1,500,000</div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <Card  bordered={false}>
                    <div className="dashboard_stats_card_container">
                      <div className="dashboard_stats_card">
                        <div className="left">
                          <div className="icon">
                            <div dangerouslySetInnerHTML={{ __html: fiatIcon }} />
                          </div>
                        </div>
                        <div className="right">
                          <div className="name">Utilization Rate</div>
                          <div className="value">100.00%</div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <Card  bordered={false}>
                    <div className="dashboard_stats_card_container">
                      <div className="dashboard_stats_card">
                        <div className="left">
                          <div className="icon">
                            <div dangerouslySetInnerHTML={{ __html: payableIcon }} />
                          </div>
                        </div>
                        <div className="right">
                          <div className="name">Total Loss Rate</div>
                          <div className="value">0.10%</div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <Card  bordered={false}>
                    <div className="dashboard_stats_card_container">
                      <div className="dashboard_stats_card">
                        <div className="left">
                          <div className="icon">
                            <div dangerouslySetInnerHTML={{ __html: receiveableIcon }} />
                          </div>
                        </div>
                        <div className="right">
                          <div className="name">Next Payment</div>
                          <div className="value">JUNE 01, 2024</div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Overview