import { Empty } from 'antd'
import React from 'react'
import NoDataIcon from '../../assets/images/no_data.png'


const NoData = () => {
    return (
        <Empty
            image={NoDataIcon}
            imageStyle={{
                height: 110,
            }}
            description={<span></span>}
        />
    )
}

export default NoData