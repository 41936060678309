import React, { useEffect, useState } from 'react'
import { Avatar, List, Radio, Space, Card, Button, Modal } from 'antd';
import { getSentContactRequest } from '../../../services/contacts/contactsContractRead';
import ViewInfo from './viewInfo';
import { useSelector } from 'react-redux';
import NoData from '../../../assets/images/no_data.png'

const RequestSent = () => {
    const address = useSelector(state => state.account.address);


    const [sentContacts, setSentContacts] = useState("");
    const [loading, setLoading] = useState(false)



    const data = sentContacts && sentContacts?.map((item) => {
        return {
            title: <>
                <div className="request_title_main_container">
                    <div className="request_title_container">
                        <Avatar
                            size={64}
                            src="https://api.dicebear.com/7.x/miniavs/svg?seed=1"
                            style={{
                                backgroundColor: '#f56a00',
                                padding: "2px"
                            }}
                        />
                    </div>
                </div>
            </>,
            content: <>
                <div className="request_content_main_container">
                    <div className="request_contenet_container">
                        <div className="title_container">
                            <div className="request_title_main_container">
                                <div className="request_title_container">
                                    <div className="avatar_container">
                                        <Avatar
                                            size={44}
                                            src="https://api.dicebear.com/7.x/miniavs/svg?seed=1"
                                            style={{
                                                backgroundColor: '#f56a00',
                                                padding: "2px"
                                            }}
                                        />
                                    </div>
                                    <div className="title_box_container">
                                        <div className="title_box">
                                            <div className="heading">{item?.name}</div>
                                            <div className="para">&nbsp;</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="contente_container">
                            <div className="contente_container_main">
                                {/* <div className="date_container">
                                    <div className="heading">Date</div>
                                    <div className="value">13-07-2023</div>
                                </div> */}
                                <div className="from_container">
                                    <div className="heading">From</div>
                                    <div className="value">{item?.company_name}</div>
                                </div>
                                <div className="button_container">
                                    <ViewInfo className={'copy_address_btn'} companyAddress={item?.address} companyName={item?.company_name} />
                                    {/* <Button className='contact_btn'>Add</Button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
    })



    // Query 
    const fetchSentContactRequest = (address) => {
        setLoading(true)
        getSentContactRequest(address).then((res) => {
            console.log(res, "res");
            setSentContacts(res)
            setLoading(false)
        }).catch((error) => {
            console.log(error);
            setLoading(false)
        })
    }

    useEffect(() => {
        if (address) {
            fetchSentContactRequest(address)
        }
    }, [address])


    return (
        <div>
            <List
                grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 2,
                    md: 4,
                    lg: 4,
                    xl: 5,
                    xxl: 3,
                }}
                // pagination={{
                //     pageSize: 4, // Number of items per page
                // }}
                pagination={false}
                dataSource={data}
                loading={loading}
                locale={{
                    emptyText: <div className="no_data_main_container">
                        <div className="no_data_container">
                            <div className="icon_container">
                                <img src={NoData} alt="NoData" />
                            </div>
                        </div>
                    </div>
                }}
                renderItem={(item) => (
                    <List.Item>
                        <Card >{item?.content}</Card>
                    </List.Item>
                )}
            />
        </div>
    )
}

export default RequestSent