import React, { useEffect, useState } from "react";
import './index.scss';
import { Button, Card, Col, Popover, Row, Spin } from 'antd';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { fiatIcon, payableIcon, receiveableIcon, txReceiveIcon, txSendIcon } from "../../../utils/icons";
import { amountConversion, getDenomBalance } from "../../../utils/coin";
import { useSelector } from "react-redux";
import { comdex } from "../../../config/network";
import { commaSeparator, formatNumber } from "../../../utils/number";
import { getTotalPayables, getTotalReceivables } from "../../../services/dashboard/dashboardContractRead";
import Banner from "./banner";
import { getContactInfo } from "../../../services/Proforma/proformaContractRead";
import { fetchPaymentDetails, fetchPaymentDetailsDateBounded } from "../../../services/dashboard/query";
// import { Badge, Calendar } from 'antd';
import Loader from '../../../assets/Lottefile/LOADER.json'
import Lottie from "react-lottie";
import NoData from '../../../assets/images/no_data.png'

const Dashboard = () => {

    const balances = useSelector(state => state.account.balances);
    const address = useSelector(state => state.account.address);


    const processingOptions = {
        loop: true,
        autoplay: true,
        animationData: Loader,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    const lineChart1 = {
        chart: {
            // type: "line",
            type: 'spline',
            backgroundColor: null,
            height: "370",
            // width: "100",
            style: {
                fontFamily: 'Montserrat'
            },
            className: "dashboard_charts",
        },
        className: "dashboard_charts",
        credits: {
            enabled: false,
        },
        title: {
            text: null,
        },
        tooltip: {
            enabled: true,
        },
        legend: {
            enabled: false, // Disable the legend
        },
        plotOptions: {
            line: {
                showInLegend: false,
                lineWidth: 1,
                lineColor: "#516FB6",
                fillColor: false,
                marker: {
                    enabled: false,
                    symbol: 'circle',
                    radius: 8,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                }
            },
            spline: {
                lineWidth: 2,
                marker: {
                    enabled: false
                },
            }
        },
        yAxis: {
            // gridLineColor: "transparent",
            // gridLineColor: "dotted",
            gridLineDashStyle: "dot",
            lineWidth: 0,
            lineColor: '#84CEE9',
            title: {
                text: null,
            },
            labels: {
                style: {
                    color: '#111111',
                    fontSize: 14,
                    fontWeight: 600
                }
            }
        },
        xAxis: {
            categories: ['05/11', '05/11', '05/11', '05/11', '05/11', '05/11', '05/11'],
            className: 'chart-x-lebel',
            lineWidth: 0,
            lineColor: '#84CEE9',
            labels: {
                style: {
                    color: '#111111',
                    fontSize: 14,
                    fontWeight: 600
                }
            }
        },
        series: [
            {
                data: [3800, 4100, 4000, 5500, 6000, 2500],
                lineColor: {
                    linearGradient: [0, '100%', 0, '20%'],
                    stops: [
                        // [0, '#0FBFFF00'],
                        [1, '#03BC0A']
                    ]
                },
            },
            {
                data: [1800, 2100, 2800, 6500, 4000, 5500],
                lineColor: {
                    linearGradient: [0, '100%', 0, '20%'],
                    stops: [
                        // [0, '#0FBFFF00'],
                        [1, '#FFBB38']
                    ]
                },
            },
        ],
    };

    const Data = [
        {
            type: 'warning',
            content: 'This is warning event',
        },
        {
            type: 'success',
            content: 'This is very long usual event......',
        },
        {
            type: 'error',
            content: 'This is error event 1.',
        },
        {
            type: 'error',
            content: 'This is error event 2.',
        },
        {
            type: 'error',
            content: 'This is error event 3.',
        },
        {
            type: 'error',
            content: 'This is error event 4.',
        },
    ];
    const getListData = (value) => {
        let listData;
        switch (value.date()) {
            case 8:
                listData = [
                    {
                        type: 'warning',
                        content: 'This is warning event.',
                    },
                    {
                        type: 'success',
                        content: 'This is usual event.',
                    },
                ];
                break;
            case 10:
                listData = [
                    {
                        type: 'warning',
                        content: 'This is warning event.',
                    },
                    {
                        type: 'success',
                        content: 'This is usual event.',
                    },
                    {
                        type: 'error',
                        content: 'This is error event.',
                    },
                ];
                break;
            case 15:

                // <Popover content={Data} title="Title" trigger="click">
                [
                    {
                        type: "Success",
                        content: <Button>Click me</Button>
                    }
                ]
                // </Popover>
                break;
            default:
        }
        return listData || [];
    };

    const getMonthData = (value) => {
        if (value.month() === 8) {
            return 1394;
        }
    };

    const monthCellRender = (value) => {
        const num = getMonthData(value);
        return num ? (
            <div className="notes-month">
                <section>{num}</section>
                <span>Backlog number</span>
            </div>
        ) : null;
    };

    const dateCellRender = (value) => {
        const listData = getListData(value);
        return (
            <ul className="events">
                {listData.map((item) => (
                    <li key={item.content}>
                        <Badge status={item.type} text={item.content} />
                    </li>
                ))}
            </ul>
        );
    };

    const cellRender = (current, info) => {
        if (info.type === 'date') return dateCellRender(current);
        if (info.type === 'month') return monthCellRender(current);
        return info.originNode;
    };


    const [value, onChange] = useState(new Date());
    const [totalReceivable, setTotalReceivable] = useState(0)
    const [totalPayable, setTotalPayable] = useState(0)
    const [contactinfo, setContactinfo] = useState("");
    const [loading, setLoading] = useState(true);

    const AvailableAssetBalance = amountConversion(getDenomBalance(balances, "usdc") || 0, comdex?.coinDecimals, 1000000);


    // Query 
    const fetchTotalReceivables = (address) => {
        getTotalReceivables(address).then((res) => {
            setTotalReceivable(res?.[0])

        }).catch((error) => {
            console.log(error, "Error in getTotalReceivables");

        })
    }

    const fetchTotalPayables = (address) => {
        getTotalPayables(address).then((res) => {
            setTotalPayable(res?.[0])

        }).catch((error) => {
            console.log(error, "Error in getTotalPayables");

        })
    }

    // Query 
    const fetchContactInfo = (address) => {
        setLoading(true)
        getContactInfo(address).then((res) => {
            // setTotalReceivable(res?.[0])
            setContactinfo(res)
            setLoading(false)

        }).catch((error) => {
            setLoading(false)
            setContactinfo()
            console.log(error);
        })
    }


    const queryPaymentDetails = (address) => {
        fetchPaymentDetails(address, (error, result) => {
            if (error) {
                console.log(error, "Error in fetchPaymentDetails");
                return;
            }
            if (result) {
                // setCoingekoPrice(result)
                console.log(result, "fetchPaymentDetails");
            }
        });
    };

    const queryPaymentDetailsDateBounded = (address, fromDate, toDate) => {
        fetchPaymentDetailsDateBounded(address, fromDate, toDate, (error, result) => {
            if (error) {
                console.log(error, "Error in fetchPaymentDetails");
                return;
            }
            if (result) {
                // setCoingekoPrice(result)
                console.log(result, "fetchPaymentDetails");
            }
        });
    };



    useEffect(() => {
        if (address) {
            fetchContactInfo(address)
        }
    }, [address])

    useEffect(() => {
        if (address) {
            fetchTotalReceivables(address)
            fetchTotalPayables(address)
            queryPaymentDetails(address)
        }
    }, [address])



    return (
        <>
            {/* <Header /> */}
            <div className="dashboard_main_container">
                <div className="banner_container mb-4">
                    {address && (loading ? <div className="loader">
                        <Lottie
                            options={processingOptions}
                            height={50}
                            width={150}
                        />
                    </div>
                        : !contactinfo && <Banner />)}
                    {/* {!contactinfo && <Banner />} */}
                </div>
                <div className="dashboard_container">
                    <div className="left_container">
                        <div className="top_container">
                            <div className="cards_container">
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} sm={24} md={12} lg={8}>
                                        <Card hoverable bordered={false}>
                                            <div className="dashboard_stats_card_container">
                                                <div className="dashboard_stats_card">
                                                    <div className="left">
                                                        <div className="icon">
                                                            <div dangerouslySetInnerHTML={{ __html: fiatIcon }} />
                                                        </div>
                                                    </div>
                                                    <div className="right">
                                                        <div className="name">Fiat Balance</div>
                                                        <div className="value">${formatNumber(AvailableAssetBalance || 0)}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={8}>
                                        <Card hoverable bordered={false}>
                                            <div className="dashboard_stats_card_container">
                                                <div className="dashboard_stats_card">
                                                    <div className="left">
                                                        <div className="icon">
                                                            <div dangerouslySetInnerHTML={{ __html: receiveableIcon }} />
                                                        </div>
                                                    </div>
                                                    <div className="right">
                                                        <div className="name">Receivables</div>
                                                        <div className="value">${commaSeparator(amountConversion(totalReceivable?.amount || 0, comdex?.coinDecimals, 1000000))}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={8}>
                                        <Card hoverable bordered={false}>
                                            <div className="dashboard_stats_card_container">
                                                <div className="dashboard_stats_card">
                                                    <div className="left">
                                                        <div className="icon">
                                                            <div dangerouslySetInnerHTML={{ __html: payableIcon }} />
                                                        </div>
                                                    </div>
                                                    <div className="right">
                                                        <div className="name">Payables</div>
                                                        <div className="value">${commaSeparator(amountConversion(totalPayable?.amount || 0, comdex?.coinDecimals, 100000))}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="bottom_container">
                            <div className="title">Receivables & Payables</div>
                            <div className="graph_container">
                                <HighchartsReact highcharts={Highcharts} options={lineChart1} />
                            </div>
                        </div>
                    </div>
                    <div className="right_container">
                        <div className="top_right">
                            <Calendar onChange={onChange} className="comdex_calender" value={value} />
                            {/* <Calendar cellRender={cellRender} /> */}
                            {/* <Calendar cellRender={cellRender} /> */}
                        </div>
                        <div className="bottom_right">
                            <div className="transaction_main_container">
                                <div className="transaction_container">
                                    <div className="header_container">
                                        <div className="title">Transactions</div>
                                        <div className="button_container">
                                            <button>View All</button>
                                        </div>
                                    </div>

                                    {/* <div className="transaction_row_container card_container mb-2">
                                        <div className="transaction_row">
                                            <div className="left_box">
                                                <div className="icon">
                                                    <div dangerouslySetInnerHTML={{ __html: txSendIcon }} />
                                                </div>
                                                <div className="name">Aster Solutions Ind.</div>
                                            </div>
                                            <div className="right_box">
                                                <div className="amount">$3,460</div>
                                            </div>
                                        </div>
                                    </div> */}

                                    {/* No Data  */}

                                    <div className="no_data_main_container">
                                        <div className="no_data_container">
                                            <div className="icon_container">
                                                <img src={NoData} alt="NoData" />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <Footer style={{ textAlign: "center" }}>
                        Comdex ©{new Date().getFullYear()}
                    </Footer> */}


        </>
    );
};
export default Dashboard;
