import React from 'react'
import { Tabs } from 'antd';
import NewInvoice from './newInvoice';
import PendingInvoice from './pendinInvoice';
import ExecutedInvoice from './executedInvoice';
import IPFSUploader from './IPFSUploader';

const RInvoice = () => {

    const onChange = (key) => {
        console.log(key);
    };

    const items = [
        {
            key: '1',
            label: 'New',
            children: <NewInvoice />,
        },
        {
            key: '2',
            label: 'Pending',
            children: <PendingInvoice />,
        },
        {
            key: '3',
            label: 'Executed',
            children: <ExecutedInvoice />,
        },
    ];

    return (
        <>
            <div className="invoice_main_container">
                <div className="invoice_container mb-4">
                    <div className="invoice_tab_container">
                        {/* <IPFSUploader /> */}
                        <Tabs
                            defaultActiveKey="1"
                            items={items}
                            onChange={onChange}
                            className='comdex_tabs'
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default RInvoice;