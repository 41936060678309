export const envConfig = {
    rpc: "https://devnet.rpc.comdex.one",
    rest: "https://devnet.rest.comdex.one",
    chainId: "test-1",
    coinDenom: "CMDX",
    coinMinimalDenom: "ucmdx",
    coinDecimals: 6,
    prefix: "comdex",
    coinType: 118,
    coinGeckoId: "comdex",
    chainName: "Comdex RWA Test Chain",
    explorerUrlToTx: "https://dev-explorer.comdex.one/transactions/{txHash}",
    apiUrl: " https://rwa-api.zenscape.one/",
    comdexStakingUrl: "https://comdex.omniflix.co/stake",
    webSocketApiUrl: "wss://devnet.rpc.comdex.one/websocket",
    symbol: "CMDX",
};

