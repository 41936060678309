import { CosmjsOfflineSigner } from '@leapwallet/cosmos-snap-provider';
import { comdex } from '../config/network';



export const KeplrWallet = async (chainID = comdex.chainId) => {
    let walletType = localStorage.getItem("loginType");
    if (walletType === "metamask") {
        const offlineSigner = new CosmjsOfflineSigner(comdex?.chainId);
        const accounts = await offlineSigner.getAccounts();
        return [offlineSigner, accounts];
    } else {
        walletType === "keplr"
            ? await window.keplr.enable(chainID)
            : await window.leap.enable(chainID);

        const offlineSigner =
            walletType === "keplr"
                ? await window.getOfflineSignerAuto(chainID)
                : await window?.leap?.getOfflineSignerAuto(chainID);
        const accounts = await offlineSigner.getAccounts();
        return [offlineSigner, accounts];
    }
};