import React, { useEffect, useState } from 'react'
import { Tabs, Button } from 'antd';
import PoolInvest from './poolsInvest';
import PoolInterest from './interestPayment';
import Repayments from './rePayments';
import Drawdown from './drawdown';
import { getPoolInfoRequest, getPoolRepaymentInfo } from '../../../../services/pools/poolsRead';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { amountConversion, getDenomBalance } from '../../../../utils/coin';
import { comdex } from '../../../../config/network';
import Withdraw from './withdraw';

const PoolDetails = () => {

  const { cpoolId } = useParams();
  const dispatch = useDispatch();


  const [poolData, setPoolData] = useState({});
  const [poolRepaymentData, setPoolrepaymentData] = useState()

  const address = useSelector(state => state.account.address);
  const refreshBalance = useSelector(state => state.account.refreshBalance);
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const balances = useSelector(state => state.account.balances);

  const AvailableAssetBalance = amountConversion(getDenomBalance(balances, poolData?.asset_info?.denom) || 0, comdex?.coinDecimals, 1000000);


  // Query 
  const fetchPoolInfo = (poolId) => {
    setLoading(true)
    getPoolInfoRequest(poolId).then((res) => {
      // setTotalReceivable(res?.[0])
      setPoolData(res)
      setLoading(false)

    }).catch((error) => {
      setLoading(false)
      console.log(error);

    })
  }
  const fetchPoolRepaymentInfo = (poolId) => {
    setLoading(true)
    getPoolRepaymentInfo(poolId).then((res) => {
      // setTotalReceivable(res?.[0])
      console.log(res, "getPoolRepaymentInfo");
      setPoolrepaymentData(res)
      setLoading(false)

    }).catch((error) => {
      setLoading(false)
      console.log(error);

    })
  }

  useEffect(() => {
    if (cpoolId) {
      fetchPoolInfo(Number(cpoolId))
      fetchPoolRepaymentInfo(Number(cpoolId))
    }
  }, [cpoolId, refreshBalance])


  const onChange = (key) => {
    console.log(key);
  };

  console.log(poolData, "Single poolData");

  const items = [

    {
      key: '1',
      label: 'Invest',
      children: <PoolInvest
        poolData={poolData}
        cpoolId={cpoolId}
        AvailableAssetBalance={AvailableAssetBalance}
        address={address}
        refreshBalance={refreshBalance}
        balances={balances}
      />,
    },
    {
      key: '2',
      label: 'Drawdown',
      children: <Drawdown
        poolData={poolData}
        cpoolId={cpoolId}
        AvailableAssetBalance={AvailableAssetBalance}
        address={address}
        refreshBalance={refreshBalance}
        balances={balances}
      />,
    },
    {
      key: '3',
      label: 'Interest Payment',
      children: <PoolInterest
        poolData={poolData}
        cpoolId={cpoolId}
        AvailableAssetBalance={AvailableAssetBalance}
        address={address}
        refreshBalance={refreshBalance}
        balances={balances}
      />,
    },
    {
      key: '5',
      label: 'Withdraw',
      children: <Withdraw
        poolData={poolData}
        cpoolId={cpoolId}
        AvailableAssetBalance={AvailableAssetBalance}
        address={address}
        refreshBalance={refreshBalance}
      />,
    },
    {
      key: '4',
      label: 'Repayments',
      children: <Repayments
        poolRepaymentData={poolRepaymentData}
        poolData={poolData}
      />,
    },
  ];

  return (
    <>
      <div className="div">
        <div className="div-2">
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/0d5eda23ca0e4773bdf3e86f6582e0c1a81acba6f2289fe2e0c4a7af3206320d?apiKey=78d75311bc524d2699f9343df12846a9&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/0d5eda23ca0e4773bdf3e86f6582e0c1a81acba6f2289fe2e0c4a7af3206320d?apiKey=78d75311bc524d2699f9343df12846a9&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0d5eda23ca0e4773bdf3e86f6582e0c1a81acba6f2289fe2e0c4a7af3206320d?apiKey=78d75311bc524d2699f9343df12846a9&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/0d5eda23ca0e4773bdf3e86f6582e0c1a81acba6f2289fe2e0c4a7af3206320d?apiKey=78d75311bc524d2699f9343df12846a9&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/0d5eda23ca0e4773bdf3e86f6582e0c1a81acba6f2289fe2e0c4a7af3206320d?apiKey=78d75311bc524d2699f9343df12846a9&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0d5eda23ca0e4773bdf3e86f6582e0c1a81acba6f2289fe2e0c4a7af3206320d?apiKey=78d75311bc524d2699f9343df12846a9&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/0d5eda23ca0e4773bdf3e86f6582e0c1a81acba6f2289fe2e0c4a7af3206320d?apiKey=78d75311bc524d2699f9343df12846a9&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/0d5eda23ca0e4773bdf3e86f6582e0c1a81acba6f2289fe2e0c4a7af3206320d?apiKey=78d75311bc524d2699f9343df12846a9&"
            className="img"
          />
          <div className="div-3">
            <div className="div-4">
              <div className="div-5">{poolData?.pool_name}</div>
              <Link to="/cpools"> <Button type="primary" className='btn-primary swap-button'>
                Back
              </Button>
              </Link>
            </div>
            <div className="div-7">{poolData?.borrower_name}</div>
          </div>
        </div>
        <div className="div-8">
          <Tabs
            defaultActiveKey="1"
            items={items}
            onChange={onChange}
            // className='div-9'
            className='comdex_tabs'
            style={{ width: "100%" }}

          />
        </div>
      </div>
    </>

  )
}

export default PoolDetails;