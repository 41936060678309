import axios from "axios";
import { COINGECKO_API_URL } from "../../constants/common";
import { CosmWasmClient } from "cosmwasm";
import { comdex, proformaContractAddress } from '../../config/network'

const configin = {
    chainId: comdex?.chainId,
    rpcEndpoint: comdex?.rpc,
};


export const fetchAllBalances = (address, callback) => {
    axios
        .get(`${comdex?.rest}/cosmos/bank/v1beta1/balances/${address}`)
        .then((result) => {
            callback(null, result?.data);
        })
        .catch((error) => {
            callback(error?.message);
        });
};

export const fetchCoingeckoPrices = (callback) => {
    axios
        .get(COINGECKO_API_URL)
        .then((result) => {
            callback(null, result?.data);
        })
        .catch((error) => {
            callback(error?.message);
        });
};


export const getWhiteListedAsset = async () => {
    const client = await CosmWasmClient.connect(configin.rpcEndpoint);
    const config = await client.queryContractSmart(proformaContractAddress, { "get_config": {} });
    return await config;
}