import { ACCOUNT_ADDRESS_SET, ACCOUNT_BALANCES_SET, BALANCE_REFRESH_SET } from "../constants/account"

export const setAccountAddress = (value) => {
    return {
        type: ACCOUNT_ADDRESS_SET,
        value,
    };
};

export const setAccountBalances = (value) => {
    return {
        type: ACCOUNT_BALANCES_SET,
        value
    };
};

export const setBalanceRefresh = (value) => {
    return {
        type: BALANCE_REFRESH_SET,
        value,
    };
};