import { SigningCosmWasmClient } from "@cosmjs/cosmwasm-stargate";
import { comdex, proformaContractAddress } from "../../config/network";
import { KeplrWallet } from "../helper";
import { getAmount } from "../../utils/coin";


const customFees = {
    exec: {
        amount: [{ amount: "500000", denom: "ucmdx" }],
        gas: "300000",
    },
}



export const transactionForCreateInovice = async (address, payee_address, receivable, amountPaid, serviceType, docUrl, denom, callback) => {

    const httpUrl = comdex?.rpc;
    let walletAddress = address;
    const handleMsg = {
        "create_invoice":
        {
            "payee_address": payee_address,
            "receivable": {
                amount: receivable,
                denom: denom
            },
            "amount_paid": {
                amount: amountPaid,
                denom: denom
            },
            "service_type": serviceType,
            "doc_uri": docUrl,

        }
    };

    let totalAmount = receivable + amountPaid

    const fundsValues = [
        {
            "denom": denom,
            "amount": getAmount(totalAmount)
        }
    ]


    const [offlineSigner] = await KeplrWallet(comdex?.chainId);

    await SigningCosmWasmClient.connectWithSigner(
        httpUrl,
        offlineSigner)
        .then((client) => {
            client.signAndBroadcast(
                walletAddress,
                [{
                    typeUrl: "/cosmwasm.wasm.v1.MsgExecuteContract",
                    value: {
                        sender: walletAddress,
                        contract: proformaContractAddress,
                        msg: new TextEncoder().encode(JSON.stringify(handleMsg)),
                        // funds: fundsValues
                    }
                }],
                customFees.exec,
            ).then((response) => {
                if (!response?.code) {
                    callback(null, response)

                }
                else {
                    console.log(response?.rawLog);
                    callback(response)

                }

            }).catch((err) => {
                console.log(err);
                callback(err)
            })
        }).catch((error) => {
            callback(error)
        });

}


export const transactionForPayInovice = async (address, invoiceId, payableAmount, denom, callback) => {

    const httpUrl = comdex?.rpc;
    let walletAddress = address;
    const handleMsg = {
        "pay_invoice":
        {
            "invoice_id": invoiceId
        }
    };

    // let totalAmount = receivable + amountPaid

    const fundsValues = [
        {
            "denom": denom,
            "amount": payableAmount
            // "amount": getAmount(payableAmount)
        }
    ]


    const [offlineSigner] = await KeplrWallet(comdex?.chainId);

    await SigningCosmWasmClient.connectWithSigner(
        httpUrl,
        offlineSigner)
        .then((client) => {
            client.signAndBroadcast(
                walletAddress,
                [{
                    typeUrl: "/cosmwasm.wasm.v1.MsgExecuteContract",
                    value: {
                        sender: walletAddress,
                        contract: proformaContractAddress,
                        msg: new TextEncoder().encode(JSON.stringify(handleMsg)),
                        funds: fundsValues
                    }
                }],
                customFees.exec,
            ).then((response) => {
                if (!response?.code) {
                    callback(null, response)

                }
                else {
                    console.log(response?.rawLog);
                    callback(response)

                }

            }).catch((err) => {
                console.log(err);
                callback(err)
            })
        }).catch((error) => {
            callback(error)
        });

}
export const transactionForAcceptInovice = async (address, invoiceId, callback) => {

    const httpUrl = comdex?.rpc;
    let walletAddress = address;
    const handleMsg = {
        "accept_invoice":
        {
            "invoice_id": invoiceId
        }
    };

    // let totalAmount = receivable + amountPaid



    const [offlineSigner] = await KeplrWallet(comdex?.chainId);

    await SigningCosmWasmClient.connectWithSigner(
        httpUrl,
        offlineSigner)
        .then((client) => {
            client.signAndBroadcast(
                walletAddress,
                [{
                    typeUrl: "/cosmwasm.wasm.v1.MsgExecuteContract",
                    value: {
                        sender: walletAddress,
                        contract: proformaContractAddress,
                        msg: new TextEncoder().encode(JSON.stringify(handleMsg)),
                        // funds: fundsValues
                    }
                }],
                customFees.exec,
            ).then((response) => {
                if (!response?.code) {
                    callback(null, response)

                }
                else {
                    console.log(response?.rawLog);
                    callback(response)

                }

            }).catch((err) => {
                console.log(err);
                callback(err)
            })
        }).catch((error) => {
            callback(error)
        });

}