import React from 'react'
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { Table } from 'antd';
import { amountConversion, amountConversionWithComma } from '../../../../utils/coin';
import { comdex } from '../../../../config/network';
import { commaSeparator } from '../../../../utils/number';
import NoData from '../../../../components/NoData';

const Repayments = ({ poolData, poolRepaymentData }) => {

    const lineChart1 = {
        chart: {
            // type: "line",
            type: 'spline',

            backgroundColor: null,
            height: "250",
            // width: "100",
            style: {
                fontFamily: 'Montserrat'
            },
            className: "dashboard_charts",
        },
        className: "dashboard_charts",
        credits: {
            enabled: false,
        },
        title: {
            text: null,
        },
        tooltip: {
            enabled: true,
        },
        legend: {
            enabled: false, // Disable the legend
        },
        plotOptions: {

            line: {
                showInLegend: false,
                lineWidth: 1,
                lineColor: "#516FB6",
                fillColor: false,
                marker: {
                    enabled: false,
                    symbol: 'circle',
                    radius: 8,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                }
            },
            spline: {
                lineWidth: 2,
                marker: {
                    enabled: false
                },
            }
        },
        yAxis: {
            // gridLineColor: "transparent",
            gridLineDashStyle: "dot",
            lineWidth: 0,
            lineColor: '#84CEE9',
            title: {
                text: null,
            },
            labels: {
                style: {
                    color: '#111111',
                    fontSize: 14,
                    fontWeight: 600
                }
            }
        },
        xAxis: {
            categories: ['05/11', '05/11', '05/11', '05/11', '05/11', '05/11', '05/11'],
            className: 'chart-x-lebel',
            lineWidth: 0,
            lineColor: '#84CEE9',
            labels: {
                style: {
                    color: '#111111',
                    fontSize: 14,
                    fontWeight: 600
                }
            }
        },
        series: [
            {
                data: [1800, 5100, 2800, 6500, 4000, 5500],
                lineColor: {
                    linearGradient: [0, '100%', 0, '20%'],
                    stops: [
                        // [0, '#0FBFFF00'],
                        [1, '#2D60FF']
                    ]
                },
                // enableMouseTracking: false,
            },
        ],
    };


    console.log(poolRepaymentData, "poolRepaymentData");

    // const dataSource = [
    //     {
    //         key: '1',
    //         token: 'LPTOKEN',
    //         tokenPrice: "1,932 USDC",
    //         position: "1,932 USDC",
    //         marketValue: "1,932 USDC",
    //         poolStatus: "1,932 USDC",
    //     },
    //     {
    //         key: '2',
    //         token: 'LPTOKEN',
    //         tokenPrice: "1,932 USDC",
    //         position: "1,932 USDC",
    //         marketValue: "1,932 USDC",
    //         poolStatus: "1,932 USDC",
    //     },
    //     {
    //         key: '3',
    //         token: 'LPTOKEN',
    //         tokenPrice: "1,932 USDC",
    //         position: "1,932 USDC",
    //         marketValue: "1,932 USDC",
    //         poolStatus: "1,932 USDC",
    //     },
    //     {
    //         key: '4',
    //         token: 'LPTOKEN',
    //         tokenPrice: "1,932 USDC",
    //         position: "1,932 USDC",
    //         marketValue: "1,932 USDC",
    //         poolStatus: "1,932 USDC",
    //     },
    // ];


    // const handleTimeStamp = (time) => {
    //     const nanosecondsTimestamp = Number(time);
    //     const millisecondsTimestamp = nanosecondsTimestamp / 1000000;
    //     let date = new Date(millisecondsTimestamp);
    //     const utcDate = date.toUTCString();
    //     const formattedDate = utcDate.toLocaleDateString("en-US", { day: "2-digit", month: "short", year: "numeric" });
    //     console.log(formattedDate, 'formattedDate');
    //     return formattedDate;
    // }

    const handleTimeStamp = (time) => {
        const nanosecondsTimestamp = Number(time);
        const millisecondsTimestamp = nanosecondsTimestamp / 1000000;
        let date = new Date(millisecondsTimestamp);
        const day = date.getUTCDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getUTCFullYear();
        const formattedDate = `${day} ${month} ${year}`;
        console.log(formattedDate, 'formattedDate');
        return formattedDate;
    }


    const handleAmountExpected = (item) => {
        let principal_repaid = Number(item?.principal_repaid)
        let interest_repaid = Number(item?.interest_repaid)
        let principal_pending = Number(item?.principal_pending)
        let interest_pending = Number(item?.interest_pending)

        let AmountExpected = principal_repaid + interest_repaid + principal_pending + interest_pending;
        AmountExpected = amountConversion(AmountExpected || 0, comdex?.coinDecimals, poolData?.asset_info?.decimal)
        return AmountExpected
    }

    const handleAmountPaid = (item) => {
        let principal_repaid = Number(item?.principal_repaid)
        let interest_repaid = Number(item?.interest_repaid)


        let AmountPaid = principal_repaid + interest_repaid;
        AmountPaid = amountConversion(AmountPaid || 0, comdex?.coinDecimals, poolData?.asset_info?.decimal)
        return AmountPaid
    }

    const handleAmountDue = (item) => {
        let AmountDue = Number(handleAmountExpected(item)) + Number(handleAmountPaid(item));
        console.log(AmountDue, "AmountDue");
        AmountDue = amountConversionWithComma(AmountDue || 0, comdex?.coinDecimals, poolData?.asset_info?.decimal)
        return AmountDue
    }


    const dataSource = poolRepaymentData && poolRepaymentData?.length > 0 && poolRepaymentData?.map((item, index) => {
        return {
            key: index,
            token: poolData?.asset_info?.name,
            paymentdate: handleTimeStamp(item?.timestamp),
            // amountExpected: `${handleAmountExpected(item) || 0}  ${poolData?.asset_info?.name}`,
            amountExpected: `${commaSeparator(handleAmountExpected(item) || 0)}  ${poolData?.asset_info?.name}`,
            amountPaid: `${commaSeparator(handleAmountPaid(item) || 0)}  ${poolData?.asset_info?.name}`,
            amountDue: `${handleAmountDue(item) || 0}  ${poolData?.asset_info?.name}`
        }
    })

    const columns = [
        {
            title: 'Source',
            dataIndex: 'token',
            key: 'paymentdate',
        },
        {
            title: 'Payment Date(UTC)',
            dataIndex: 'paymentdate',
            key: 'tokenPrice',
        },
        {
            title: 'Amount Expected',
            dataIndex: 'amountExpected',
            key: 'amountExpected',
        },
        {
            title: 'Amount Paid',
            dataIndex: 'amountPaid',
            key: 'amountPaid',
        },
        {
            title: 'Amount Due',
            dataIndex: 'amountDue',
            key: 'amountDue',
        },
    ];

    return (
        <>
            <div className="cportifolio_main_container">
                <div className="cportifolio_container">
                    {/* <div className="cportofolio_overview_container ">
                        <div className="card_title" style={{ marginTop: "0" }}>
                            Repayment details
                        </div>
                    </div> */}
                    <div className="cportofolio_holding_container">
                        {/* <div className="card_title" >
                            Repayment details
                        </div> */}
                        <div className="tbale_container">
                            <Table
                                dataSource={dataSource}
                                columns={columns}
                                pagination={false}
                                className='custom_table'
                                 locale={{ emptyText: <NoData /> }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Repayments