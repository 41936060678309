import React from 'react'
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { Table } from 'antd';

const Repayments = () => {

  const lineChart1 = {
    chart: {
      // type: "line",
      type: 'spline',

      backgroundColor: null,
      height: "250",
      // width: "100",
      style: {
        fontFamily: 'Montserrat'
      },
      className: "dashboard_charts",
    },
    className: "dashboard_charts",
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    tooltip: {
      enabled: true,
    },
    legend: {
      enabled: false, // Disable the legend
    },
    plotOptions: {

      line: {
        showInLegend: false,
        lineWidth: 1,
        lineColor: "#516FB6",
        fillColor: false,
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 8,
          states: {
            hover: {
              enabled: false
            }
          }
        }
      },
      spline: {
        lineWidth: 2,
        marker: {
          enabled: false
        },
      }
    },
    yAxis: {
      // gridLineColor: "transparent",
      gridLineDashStyle: "dot",
      lineWidth: 0,
      lineColor: '#84CEE9',
      title: {
        text: null,
      },
      labels: {
        style: {
          color: '#111111',
          fontSize: 14,
          fontWeight: 600
        }
      }
    },
    xAxis: {
      categories: ['05/11', '05/11', '05/11', '05/11', '05/11', '05/11', '05/11'],
      className: 'chart-x-lebel',
      lineWidth: 0,
      lineColor: '#84CEE9',
      labels: {
        style: {
          color: '#111111',
          fontSize: 14,
          fontWeight: 600
        }
      }
    },
    series: [
      {
        data: [1800, 5100, 2800, 6500, 4000, 5500],
        lineColor: {
          linearGradient: [0, '100%', 0, '20%'],
          stops: [
            // [0, '#0FBFFF00'],
            [1, '#2D60FF']
          ]
        },
        // enableMouseTracking: false,
      },
    ],
  };

  const dataSource = [
    {
      key: '1',
      token: 'LPTOKEN',
      tokenPrice: "1,932 USDC",
      position: "1,932 USDC",
      marketValue: "1,932 USDC",
      poolStatus: "1,932 USDC",
    },
    {
      key: '2',
      token: 'LPTOKEN',
      tokenPrice: "1,932 USDC",
      position: "1,932 USDC",
      marketValue: "1,932 USDC",
      poolStatus: "1,932 USDC",
    },
    {
      key: '3',
      token: 'LPTOKEN',
      tokenPrice: "1,932 USDC",
      position: "1,932 USDC",
      marketValue: "1,932 USDC",
      poolStatus: "1,932 USDC",
    },
    {
      key: '4',
      token: 'LPTOKEN',
      tokenPrice: "1,932 USDC",
      position: "1,932 USDC",
      marketValue: "1,932 USDC",
      poolStatus: "1,932 USDC",
    },
  ];

  const columns = [
    {
      title: 'Source',
      dataIndex: 'token',
      key: 'token',
    },
    {
      title: 'Payment Date',
      dataIndex: 'tokenPrice',
      key: 'tokenPrice',
    },
    {
      title: 'Amount Expected',
      dataIndex: 'position',
      key: 'position',
    },
    {
      title: 'Amount Paid',
      dataIndex: 'position',
      key: 'position',
    },
  ];

  return (
    <>
      <div className="cportifolio_main_container">
        <div className="cportifolio_container">
          <div className="cportofolio_overview_container ">
            <div className="card_title" style={{ marginTop: "0", textAlign: "start" }}>
              Repayment details
            </div>
          </div>
          <div className="cportofolio_holding_container">
            {/* <div className="card_title" >
              Payment details
            </div> */}
            <div className="tbale_container">
              <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                className='custom_table'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Repayments