import { Button, Modal } from 'antd';
import React, { useState } from 'react'

const ViewInfo = ({ companyAddress, companyName, className }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <div style={{ width: "100%" }}>
            <Button className={className ? 'contact_btn' + " " + className : "contact_btn"} onClick={showModal}>View Info</Button>
            <div className="modal_main_container">
                <div className="modal_container">
                    <Modal title="Contact Details"
                        open={isModalOpen}
                        onOk={handleOk}
                        centered
                        width={600}
                        footer={false}
                        className='contact_modal'
                        onCancel={handleCancel}
                    >
                        <div className="view_info_main_container">
                            <div className="view_info_container">
                                <div className="info_row">
                                    <div className="heading_box">
                                        Company Name
                                    </div>
                                    <div className="input_box">
                                        {companyName}
                                    </div>
                                </div>
                                <div className="info_row">
                                    <div className="heading_box">
                                        Wallet Address
                                    </div>
                                    <div className="input_box">
                                        {companyAddress}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        </div>
    )
}

export default ViewInfo