import * as PropTypes from "prop-types";
import { Button, Input, Select } from 'antd'
import React, { useState, useEffect } from 'react'
import './index.scss'
import { connect, useSelector } from 'react-redux';
import { amountConversionWithComma, getDenomBalance } from "../../../utils/coin";
import { comdex } from "../../../config/network";
// import { SquidWidget } from '@0xsquid/widget';
import { SquidWidget } from '@0xsquid/widget';
import Loader from '../../../assets/Lottefile/LOADER.json'
import Lottie from "react-lottie";


const CTransfer = () => {
    const [loading, setLoading] = useState(true);

    const processingOptions = {
        loop: true,
        autoplay: true,
        animationData: Loader,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    // useEffect(() => {
    //     setTimeout(() => {
    //         setLoading(false)
    //     }, 3000);
    // }, [])


    // if (loading) {
    //     return (
    //         <div className="wrap_mai_container">
    //             <div className="wrap">
    //                 {/* <div class="spinner spinner-1">Loading...</div> */}
    //                 <Lottie
    //                     options={processingOptions}
    //                     height={150}
    //                     width={150}
    //                 />
    //             </div>
    //         </div>
    //     )
    // }
    return (
        <>
            {/* <div className="transfer_main_container">Transfer Page</div> */}

            {/* <div className="App comdex-widget" style={{ padding: "2rem 0", width: "auto", height: "auto" }}>

                <SquidWidget config={
                    {
                        integratorId: "comdex-swap-widget",
                        companyName: "comdex",
                        slippage: 1,
                        infiniteApproval: false,
                        instantExec: false,
                        apiUrl: "https://api.0xsquid.com",
                        priceImpactWarnings: {
                            warning: 3,
                            critical: 5,
                        },
                        mainLogoUrl: "",
                        initialFromChainId: 250,
                        initialToChainId: 1,
                        "style": {
                            "displayDivider": true,
                            "roundedBtn": "5px",
                            "roundedBox": "5px",
                            "roundedDropDown": "7px",
                            "neutralContent": "#959BB2",
                            "baseContent": "#E8ECF2",
                            "base100": "#10151B",
                            "base200": "#272D3D",
                            "base300": "#171D2B",
                            "error": "#ED6A5E",
                            "warning": "#FFB155",
                            "success": "#62C555",
                            "primary": "#71B4BD",
                            "secondary": "#71B4BD",
                            "secondaryContent": "#191C29",
                            "neutral": "#191C29",
                        },
                        // Mark fantom as not coming soon
                        // comingSoonChainIds: [
                        //     // Mainnet
                        //     42161,
                        //     56,
                        //     "cosmoshub-4",
                        //     "crescent-1",
                        //     "injective-1",
                        //     "juno-1",
                        //     "kaiyo-1",
                        //     "osmosis-1",
                        //     "secret-4",
                        //     "phoenix-1",
                        //     "agoric-3",
                        //     "mantle-1",
                        //     "axelar-dojo-1",
                        //     "comdex-1",
                        //     "evmos_9001-2",
                        //     "fetchhub-4",
                        //     "kichain-2",
                        //     "regen-1",
                        //     "umee-1",
                        // ]
                    }} />
            </div> */}

            <div className="transfer_main_container">
                <div className="transfer_container">
                    {loading &&
                        <div className="wrap_mai_container">
                            <div className="wrap">
                                {/* <div class="spinner spinner-1">Loading...</div> */}
                                <Lottie
                                    options={processingOptions}
                                    height={150}
                                    width={150}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>

            <div className="App comdex-widget" style={{ padding: "0rem 0 2rem 0", width: "auto", height: "auto" }}>



                <SquidWidget config={
                    {
                        companyName: "comdex",
                        slippage: 1,
                        infiniteApproval: false,
                        instantExec: false,
                        apiUrl: "https://api.0xsquid.com",
                        priceImpactWarnings: {
                            warning: 3,
                            critical: 5,
                        },
                        mainLogoUrl: "",
                        initialFromChainId: 250,
                        initialToChainId: 1,
                        // "style": {
                        //     "displayDivider": true,
                        //     "roundedBtn": "5px",
                        //     "roundedBox": "5px",
                        //     "roundedDropDown": "7px",
                        //     "neutralContent": "#959BB2",
                        //     "baseContent": "#E8ECF2",
                        //     "base100": "#10151B",
                        //     "base200": "#272D3D",
                        //     "base300": "#171D2B",
                        //     "error": "#ED6A5E",
                        //     "warning": "#FFB155",
                        //     "success": "#62C555",
                        //     "primary": "#71B4BD",
                        //     "secondary": "#71B4BD",
                        //     "secondaryContent": "#191C29",
                        //     "neutral": "#191C29",
                        // },


                        style: {
                            // neutralContent: "#9DA7B1",
                            neutralContent: "#343c6a",
                            // neutralContent: "#ffbb38",
                            baseContent: "#343c6a",
                            // baseContent: "red",
                            base100: "#dbe3fb",
                            // base200: "#202230",
                            base200: "#F0F4FF",
                            // base300: "#161522",
                            base300: "#FFFF",
                            error: "#ED6A5E",
                            warning: "#FFB155",
                            success: "#62C555",
                            primary: "#2d60ff",
                            // secondary: "#37394C",
                            secondary: "#2d60ff",
                            // secondaryContent: "#343c6a",
                            // secondaryContent: "#ffbb38",
                            secondaryContent: "#ffbb38",
                            neutral: "#fff",
                            roundedBtn: "24px",
                            roundedBox: "20px",
                            roundedDropDown: "0px",
                            displayDivider: false,
                            advanced: {
                                transparentWidget: false,
                            },
                        },


                        // Mark fantom as not coming soon
                        // comingSoonChainIds: [
                        //     // Mainnet
                        //     42161,
                        //     56,
                        //     "cosmoshub-4",
                        //     "crescent-1",
                        //     "injective-1",
                        //     "juno-1",
                        //     "kaiyo-1",
                        //     "osmosis-1",
                        //     "secret-4",
                        //     "phoenix-1",
                        //     "agoric-3",
                        //     "mantle-1",
                        //     "axelar-dojo-1",
                        //     "comdex-1",
                        //     "evmos_9001-2",
                        //     "fetchhub-4",
                        //     "kichain-2",
                        //     "regen-1",
                        //     "umee-1",
                        // ]
                    }} />
            </div>
        </>
    )
}

const stateToProps = (state) => {
    return {
        address: state.account.address,
        balances: state.account.balances,
        refreshBalance: state.account.refreshBalance,
        assetMap: state.asset.map,
    };
};

const actionsToProps = {
};

export default connect(stateToProps, actionsToProps)(CTransfer);
// export default CTransfer