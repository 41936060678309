import { SET_ASSET_LIST } from "../constants/asset";
import { combineReducers } from "redux";


const assetList = (state = "", action) => {
    if (action.type === SET_ASSET_LIST) {
        return action.list;
    }

    return state;
};

const map = (state = {}, action) => {
    if (action.type === SET_ASSET_LIST) {
        return action.map;
    }

    return state;
};

export default combineReducers({
    assetList,
    map
});