import React, { useEffect, useState } from 'react'
import { Button, Table,Modal } from 'antd';
import { useSelector } from 'react-redux';
import { getExecutedInvoice } from '../../../services/inovice/invoiceContractRead';
import { truncateString } from '../../../utils/string';
import { commaSeparator } from '../../../utils/number';
import { amountConversion } from '../../../utils/coin';
import { comdex } from '../../../config/network';
import NoData from '../../../components/NoData';

const ExecutedInvoice = () => {

  const address = useSelector(state => state.account.address);
  const [totalSendInvoice, setTotalSendInvoice] = useState([])
  const [totalReceiveInvoice, setTotalReceiveInvoice] = useState([])
  const [loading, setLoading] = useState(false)
  const IpfsUrl = "https://gateway.pinata.cloud/ipfs/"
  const [isModalOpen, setIsModalOpen] = useState(false);
  var [nfturl,setNFTUrl] = useState("");
  console.log("nfturlllll",nfturl)

  const showModal = (url) => {
    setNFTUrl(IpfsUrl+url)
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Query 
  const fetchTotalExecutedInvoice = (address) => {
    setLoading(true)
    getExecutedInvoice(address).then((res) => {
      console.log(res, "Executed res");
      // setTotalReceivable(res?.[0])
      setTotalSendInvoice(res?.sent_invoices)
      setTotalReceiveInvoice(res?.received_invoices)
      setLoading(false)

    }).catch((error) => {
      setLoading(false)
      console.log(error);

    })
  }
  useEffect(() => {
    if (address) {
      fetchTotalExecutedInvoice(address)
    }
  }, [address])

  const sentDataSource = totalSendInvoice && totalSendInvoice?.map((item, index) => {
    return {
      key: index,
      status: <>
        <div className="status_main_container">
          <div className="status_container">
            PAID
          </div>
        </div>
      </>,
      wallet: truncateString(item?.contact?.address, 6, 6),
      contact: item?.contact?.name,
      company: item?.contact?.company_name,
      serviceType: <p style={{ textTransform: "uppercase" }}>{item?.invoice?.service_type}</p>,
      advancePayment: `${commaSeparator(amountConversion(item?.invoice?.amount_paid?.amount || 0, comdex?.coinDecimals, 1000000))} USDC`,
      due: `${commaSeparator(amountConversion(item?.invoice?.due_amount?.amount || 0, comdex?.coinDecimals, 1000000))} USDC`,
      invoice: <button  onClick={() => showModal(item?.invoice?.doc_uri)}><p style={{ color: "#0287EA" }}>View</p> </button>,
    }
  })

  const sentDataSources = [
    {
      key: 1,
      status: <>
        <div className="status_main_container">
          <div className="status_container">
            Executed
          </div>
        </div>
      </>,
      wallet: "comdexfd......643jmfj",
      contact: "Sync_Scale",
      company: "SyncScale",
      serviceType: "Goods",
      advancePayment: "67,987 USDT",
      due: "67,987 USDT",
      invoice: "Astra Solutions.pdf",
    },
    {
      key: 2,
      status: <>
        <div className="status_main_container">
          <div className="status_container">
            Executed
          </div>
        </div>
      </>,
      wallet: "comdexfd......643jmfj",
      contact: "Sync_Scale",
      company: "SyncScale",
      serviceType: "Goods",
      advancePayment: "67,987 USDT",
      due: "67,987 USDT",
      invoice: "Astra Solutions.pdf",
    },
    {
      key: 3,
      status: <>
        <div className="status_main_container">
          <div className="status_container">
            Executed
          </div>
        </div>
      </>,
      wallet: "comdexfd......643jmfj",
      contact: "Sync_Scale",
      company: "SyncScale",
      serviceType: "Goods",
      advancePayment: "67,987 USDT",
      due: "67,987 USDT",
      invoice: "Astra Solutions.pdf",
    },
    {
      key: 4,
      status: <>
        <div className="status_main_container">
          <div className="status_container">
            Executed
          </div>
        </div>
      </>,
      wallet: "comdexfd......643jmfj",
      contact: "Sync_Scale",
      company: "SyncScale",
      serviceType: "Goods",
      advancePayment: "67,987 USDT",
      due: "67,987 USDT",
      invoice: "Astra Solutions.pdf",
    },
    {
      key: 5,
      status: <>
        <div className="status_main_container">
          <div className="status_container">
            Executed
          </div>
        </div>
      </>,
      wallet: "comdexfd......643jmfj",
      contact: "Sync_Scale",
      company: "SyncScale",
      serviceType: "Goods",
      advancePayment: "67,987 USDT",
      due: "67,987 USDT",
      invoice: "Astra Solutions.pdf",
    },

  ];

  const receivedDataSource = totalReceiveInvoice && totalReceiveInvoice?.map((item, index) => {
    console.log("ipfsurlddd",IpfsUrl + item?.invoice?.doc_uri);
    return {
      key: index,
      status: <>
        <div className="status_main_container">
          <div className=" reveived_status_container">
            PAID
          </div>
        </div>
      </>,
      wallet: truncateString(item?.contact?.address, 6, 6),
      contact: item?.contact?.name,
      company: item?.contact?.company_name,
      serviceType: <p style={{ textTransform: "uppercase" }}>{item?.invoice?.service_type}</p>,
      advancePayment: `${commaSeparator(amountConversion(item?.invoice?.amount_paid?.amount || 0, comdex?.coinDecimals, 1000000))} USDC`,
      due: `${commaSeparator(amountConversion(item?.invoice?.due_amount?.amount || 0, comdex?.coinDecimals, 1000000))} USDC`,
      invoice: <button  onClick={() => showModal(item?.invoice?.doc_uri)}><p style={{ color: "#0287EA" }}>View</p> </button>,
    }
  })

  const receivedDataSources = [
    {
      key: 1,
      status: <>
        <div className="status_main_container">
          <div className=" reveived_status_container">
            Executed
          </div>
        </div>
      </>,
      wallet: "comdexfd......643jmfj",
      contact: "Sync_Scale",
      company: "SyncScale",
      serviceType: "Goods",
      advancePayment: "67,987 USDT",
      due: "67,987 USDT",
      invoice: "Astra Solutions.pdf",
      pay: <>
        <div className="pay_btn_container">
          <div className="pay_btn">
            <Button className="ant-btn-sm ">Pay</Button>
          </div>
        </div>
      </>
    },
    {
      key: 2,
      status: <>
        <div className="status_main_container">
          <div className="reveived_status_container">
            Executed
          </div>
        </div>
      </>,
      wallet: "comdexfd......643jmfj",
      contact: "Sync_Scale",
      company: "SyncScale",
      serviceType: "Goods",
      advancePayment: "67,987 USDT",
      due: "67,987 USDT",
      invoice: "Astra Solutions.pdf",
      pay: <>
        <div className="pay_btn_container">
          <div className="pay_btn">
            <Button className="ant-btn-sm ">Pay</Button>
          </div>
        </div>
      </>
    },
    {
      key: 3,
      status: <>
        <div className="status_main_container">
          <div className="reveived_status_container">
            Executed
          </div>
        </div>
      </>,
      wallet: "comdexfd......643jmfj",
      contact: "Sync_Scale",
      company: "SyncScale",
      serviceType: "Goods",
      advancePayment: "67,987 USDT",
      due: "67,987 USDT",
      invoice: "Astra Solutions.pdf",
      pay: <>
        <div className="pay_btn_container">
          <div className="pay_btn">
            <Button className="ant-btn-sm ">Pay</Button>
          </div>
        </div>
      </>
    },
    {
      key: 4,
      status: <>
        <div className="status_main_container">
          <div className="reveived_status_container">
            Executed
          </div>
        </div>
      </>,
      wallet: "comdexfd......643jmfj",
      contact: "Sync_Scale",
      company: "SyncScale",
      serviceType: "Goods",
      advancePayment: "67,987 USDT",
      due: "67,987 USDT",
      invoice: "Astra Solutions.pdf",
      pay: <>
        <div className="pay_btn_container">
          <div className="pay_btn">
            <Button className="ant-btn-sm ">Pay</Button>
          </div>
        </div>
      </>
    },
    {
      key: 5,
      status: <>
        <div className="status_main_container">
          <div className="reveived_status_container">
            Executed
          </div>
        </div>
      </>,
      wallet: "comdexfd......643jmfj",
      contact: "Sync_Scale",
      company: "SyncScale",
      serviceType: "Goods",
      advancePayment: "67,987 USDT",
      due: "67,987 USDT",
      invoice: "Astra Solutions.pdf",
      pay: <>
        <div className="pay_btn_container">
          <div className="pay_btn">
            <Button className="ant-btn-sm ">Pay</Button>
          </div>
        </div>
      </>
    },

  ];

  const columns = [
    {
      title: "Status",
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: "Wallet",
      dataIndex: 'wallet',
      key: 'wallet',
    },
    {
      title: "Contact",
      dataIndex: 'contact',
      key: 'contact',
    },
    {
      title: "Company",
      dataIndex: 'company',
      key: 'company',
    },

    {
      title: "Service Type",
      dataIndex: 'serviceType',
      key: 'serviceType',
    },

    {
      title: "Advance Payment",
      dataIndex: 'advancePayment',
      key: 'advancePayment',
    },
    {
      title: "Due",
      dataIndex: 'due',
      key: 'due',
    },
    {
      title: "Invoice",
      dataIndex: 'invoice',
      key: 'invoice',
    },

  ];

  const receivedColumns = [
    {
      title: "Status",
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: "Wallet",
      dataIndex: 'wallet',
      key: 'wallet',
    },
    {
      title: "Contact",
      dataIndex: 'contact',
      key: 'contact',
    },
    {
      title: "Company",
      dataIndex: 'company',
      key: 'company',
    },

    {
      title: "Service Type",
      dataIndex: 'serviceType',
      key: 'serviceType',
    },

    {
      title: "Advance Payment",
      dataIndex: 'advancePayment',
      key: 'advancePayment',
    },
    {
      title: "Due",
      dataIndex: 'due',
      key: 'due',
    },
    {
      title: "Invoice",
      dataIndex: 'invoice',
      key: 'invoice',
    },
    // {
    //   title: "Pay",
    //   dataIndex: 'pay',
    //   key: 'pay',
    // },

  ];

  return (
    <div>
      <div>
        <div className="executed_invoice_main_container">
          <div className="executed_invoice_container">
            <div className="executed_inovice_sent_container">
              <h2>Sent</h2>
              <Table
                dataSource={sentDataSource}
                columns={columns}
                pagination={false}
                loading={loading}
                className='custom_table'
                locale={{ emptyText: <NoData /> }}
              />
            </div>
            <div className="executed_inovice_receive_container">
              <h2>Received</h2>
              <Table
                dataSource={receivedDataSource}
                columns={receivedColumns}
                pagination={false}
                loading={loading}
                locale={{ emptyText: <NoData /> }}
                className='custom_table'
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
          className={'modal__wrap'}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          centered={true}
          footer={null}
          header={null}
        >
          <iframe
            src={nfturl}
            frameBorder="0"
            width={'100%'}
            height={'700px'}
            id="ipfs__iframe"
            style={{ borderRadius: '10px', background: '#030b1e' }}
          />
    </Modal>
    </div>
  )
}

export default ExecutedInvoice