import { Button } from 'antd';
import React from 'react'
import Lottie from "react-lottie";
import processingAnimation from '../../../assets/Lottefile/processing.json';
import successAnimation from '../../../assets/Lottefile/success.json';
import failedAnimation from '../../../assets/Lottefile/failed.json';
import { Link } from 'react-router-dom';


const Submit = ({ transactionStatus, setTransactionStatus }) => {

    const processingOptions = {
        loop: true,
        autoplay: true,
        animationData: processingAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    const successOptions = {
        loop: true,
        autoplay: true,
        animationData: successAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    const failedOptions = {
        loop: true,
        autoplay: true,
        animationData: failedAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    return (
        <div>
            <div className="proforma_submit_main_container">
                <div className="proforma_submit_container">
                    <div className="animation_container">
                        {transactionStatus === "processing" && <>
                            <Lottie
                                options={processingOptions}
                                height={200}
                                width={200}
                            />
                        </>
                        }
                        {transactionStatus === "success" && <>
                            <Lottie
                                options={successOptions}
                                height={200}
                                width={200}
                            />
                        </>
                        }
                        {transactionStatus === "failed" && <>
                            <Lottie
                                options={failedOptions}
                                height={200}
                                width={200}
                            />
                        </>
                        }

                    </div>
                    <div className="text_container">
                        {transactionStatus === "processing" && <>
                            <div className="heading">Please Wait</div>
                            <div className="para">Your form is currently being processed.</div>
                        </>
                        }
                        {transactionStatus === "success" && <>
                            <div className="heading">Thank You!</div>
                            <div className="para">You form have been submitted.</div>
                        </>
                        }
                        {transactionStatus === "failed" && <>
                            <div className="heading">Sorry</div>
                            <div className="para">Your form has not been submitted.</div>
                        </>
                        }

                    </div>
                    <div className="button_container">
                        <Link to='/rdashboard'><Button type='primary' className='btn-primary'>Go To Dashboard</Button></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Submit;