import React, { useEffect, useState } from 'react'
import './index.scss';
import { Button, Form, Input, Select, Spin, Upload, message } from 'antd';
import { UploadOutlined, CaretDownOutlined } from '@ant-design/icons';
import axios from 'axios';
import { transactionForCreateInovice } from '../../../services/inovice/inovicecontractWrite';
import { useSelector } from 'react-redux';
import { getAmount } from '../../../utils/coin';
import { getAllContactRequest } from '../../../services/contacts/contactsContractRead';

const NewInvoice = () => {

    const address = useSelector(state => state.account.address);

    const [form] = Form.useForm();
    const { Option } = Select;
    const [fileList, setFileList] = useState([]);
    const [ipfsHash, setIpfsHash] = useState("");
    const [loading, setLoading] = useState(false);
    const [allContacts, setAllContacts] = useState("");
    const [formData, setFormData] = useState({
        contactName: '',
        companyName: '',
        payeeAddress: '',
        serviceType: '',
        docUri: null,
        advancePayment: '',
        receivable: '',
    });

    const asset = {
        denom: "usdc",
    }

    const onFinish = (values) => {
        console.log('Success:', values);
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleChange = (name, value) => {
        setFormData((prevFormData) => {
            const updatedFormData = { ...prevFormData, [name]: value };
            return updatedFormData;
        });

    };

    const beforeUpload = (file) => {
        // Extract the file name
        const uploadedFileName = file;

        handleChange('docUri', uploadedFileName);
        setFileList((prevList) => {
            const updatedFile = { ...prevList[0], status: 'done' };
            return [updatedFile];
        });

        // Return false to prevent automatic upload
        return false;
    };

    const handleIpfsUpload = async (e) => {
        try {
            const file = e.target.files[0];
            setFileList([{ uid: file.uid, name: file.name, status: 'uploading', percent: 0 }]);

            const fileData = new FormData();
            fileData.append('file', file);

            const responseData = await axios.post(
                'https://api.pinata.cloud/pinning/pinFileToIPFS',
                fileData,
                {
                    headers: {
                        'pinata_api_key': '618b02839c5b5f9ae29e',
                        'pinata_secret_api_key': '2c78dda7fc920a04cdfb5fb0a66b0960bb2a8331a68384518632ee13417706b5',
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setFileList((prevList) => {
                            const updatedFile = { ...prevList[0], percent: percentCompleted };
                            return [updatedFile];
                        });
                    },
                }
            );

            const fileDataUrls = 'https://gateway.pinata.cloud/ipfs/' + responseData.data.IpfsHash;

            beforeUpload(responseData.data.IpfsHash);

            setIpfsHash(fileDataUrls)

            // Set the status to 'done' after successful upload
            setFileList((prevList) => {
                const updatedFile = { ...prevList[0], status: 'done' };
                return [updatedFile];
            });

        } catch (error) {
            console.log(error, 'IPFS Error');

            // Set the status to 'error' if there's an error during upload
            setFileList((prevList) => {
                const updatedFile = { ...prevList[0], status: 'error' };
                return [updatedFile];
            });
        }
    };



    const handleSubmit = (address, payeeAddress, receivable, amountPaid, serviceType, docUrl, denom) => {
        setLoading(true);
        transactionForCreateInovice(address, payeeAddress, receivable, amountPaid, serviceType, docUrl, denom, (error, result) => {
            if (error) {
                message.error(error?.rawLog || "Transaction Failed");
                setLoading(false);
                return;
            }
            message.success("Transaction Success");
            setFormData({
                contactName: '',
                companyName: '',
                payeeAddress: '',
                serviceType: '',
                docUri: null,
                advancePayment: '',
                receivable: '',
            });
            form.resetFields();
            setFileList([])
            setLoading(false);
        });
    };

    // Query 
    const fetchAllContactRequest = (address) => {
        setLoading(true)
        getAllContactRequest(address).then((res) => {
            setAllContacts(res)
            setLoading(false)
        }).catch((error) => {
            console.log(error);
            setLoading(false)
        })
    }

    useEffect(() => {
        if (address) {
            fetchAllContactRequest(address)
        }
    }, [address])

    const updateCompanyNameAndPayee = (value) => {
        let findCompany = allContacts && allContacts?.length > 0 && allContacts?.find((item) => item?.name === value);
        handleChange("companyName", findCompany?.company_name)
        handleChange("payeeAddress", findCompany?.address)
    }

    return (
        <div className='invoice_form_main_container'>
            <div className="invoice_form_container">
                <div className="contact-form-container">
                    <Form name="basic"
                        layout="vertical"
                        form={form}
                        initialValues={{ remember: true, }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        className='comdex_form'
                    >
                        <Form.Item
                            label="Contact Name"
                            name="contactName"
                            rules={[{ required: true, message: 'Please input your username!' }]}
                            className="custom-form-item"
                            placeholder="Please select a country"
                        >
                            <Select loading={loading} onChange={
                                (value) => {
                                    handleChange("contactName", value)
                                    updateCompanyNameAndPayee(value)
                                }

                            }
                                suffixIcon={< CaretDownOutlined style={{ color: "#2d60ff", fontSize: '20px' }} />}
                                value={formData?.contactName}
                                name="payeeAddress" placeholder="Select Payee" popupClassName="comdex_select_dropdown" allowClear>
                                {allContacts && allContacts?.length > 0 && allContacts?.map((item, index) => {
                                    return <Option value={item?.name} key={index} >{item?.name}</Option>
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Company Name"
                            name="companyName"
                            className="custom-form-item"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input!',
                                },
                            ]}
                            onChange={(e) => handleChange(e.target.name, e.target.value)}

                        >
                            <Input disabled name="companyName" placeholder="Company Name" value={formData?.companyName} defaultValue={formData?.companyName} />
                            <input style={{ display: "none" }} type="text" name="companyName" placeholder="Company Name" value={formData?.companyName} />
                        </Form.Item>

                        <Form.Item
                            label="Payee"
                            name="payeeAddress"
                            rules={[{ required: true, message: 'Please input payee address!' }]}
                            className="custom-form-item"
                            onChange={(e) => handleChange(e.target.name, e.target.value)}
                        >
                            <Input name="payeeAddress" disabled placeholder='Payee Address' value={formData?.payeeAddress} defaultValue={formData?.payeeAddress} />
                            <input style={{ display: "none" }} type="text" name="payeeAddress" placeholder='Payee Address' defaultValue={formData?.payeeAddress} />
                        </Form.Item>

                        <Form.Item
                            label="Service Type"
                            name="serviceType"
                            rules={[{ required: true, message: 'Please select service type!' }]}
                            className="custom-form-item"

                        >
                            <Select value={formData?.serviceType} suffixIcon={< CaretDownOutlined style={{ color: "#2d60ff", fontSize: '20px' }} />} onChange={(value) => handleChange("serviceType", value)} name="serviceType" placeholder="Select Service" popupClassName="comdex_select_dropdown" allowClear>
                                <Option value="unspecified">Unspecified</Option>
                                <Option value="goods">Goods</Option>
                                <Option value="service">Service</Option>
                            </Select>
                        </Form.Item>



                        <Form.Item
                            label="Advance Payment (USDC)"
                            name="advancePayment"
                            rules={[{ required: true, message: 'Please enter amount!' }]}
                            className="custom-form-item"
                            placeholder="Please select a country"
                            onChange={(e) => handleChange(e.target.name, e.target.value)}
                        >
                            <Input name="advancePayment" type='number' placeholder="Advance Payment" value={formData?.advancePayment} />
                        </Form.Item>
                        <Form.Item
                            label="Total Receivable (USDC)"
                            name="receivable"
                            rules={[{ required: true, message: 'Please enter amount!' }]}
                            className="custom-form-item"
                            placeholder="Please select a country"
                            onChange={(e) => handleChange(e.target.name, e.target.value)}
                        >
                            <Input name="receivable" type='number' placeholder="Due Payment" value={formData?.receivable} />
                        </Form.Item>

                        <Form.Item
                            name="upload"
                            label="Upload"
                            valuePropName="fileList"
                            rules={[{ required: true, message: 'Please input your username!' }]}
                            className="custom-form-item"
                            getValueFromEvent={(e) => e && e.fileList}
                            onChange={(e) => handleIpfsUpload(e)}
                            value={formData?.docUri}

                        >
                            <Upload name="docUri" listType='picture' >
                                <Button name="docUri" icon={<UploadOutlined />}>Click to upload</Button>
                            </Upload>
                            {fileList[0]?.status === 'uploading' && (
                                <div style={{ marginTop: '8px' }}>
                                    <Spin /> Uploading... ({fileList[0]?.percent}%)
                                </div>
                            )}
                            {fileList[0]?.status === 'done' && (
                                <div style={{ marginTop: '8px', wordWrap: "break-word" }}>
                                    Ipfs Hash: {<a href={ipfsHash} target='_blank'>{ipfsHash}</a>}
                                </div>
                            )}
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 8, span: 16, }}>
                            <Button type="primary"
                                onClick={() => handleSubmit(address, formData?.payeeAddress, getAmount(formData?.receivable, 1000000), getAmount(formData?.advancePayment, 1000000), formData?.serviceType, formData?.docUri, asset?.denom)}
                                className='btn-primary swap-button '
                                loading={loading}
                                disabled={loading}
                            >
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default NewInvoice