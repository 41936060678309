
import CGovernance from "./containers/COMMODITIES/Governance";
import CPools from "./containers/COMMODITIES/Pools";
import CPortofolio from "./containers/COMMODITIES/Portfolio";
import HomePage from "./containers/Homepage";
import Contacts from "./containers/RWA/Contacts";
import Dashboard from "./containers/RWA/Dsahboard";
import RInvoice from "./containers/RWA/Inovice";
import Proforma from "./containers/RWA/Proforma";
import Swap from "./containers/RWA/Swap";
import PoolInvest from "./containers/COMMODITIES/Pools/juniorPool/poolsInvest";
import PoolInterest from "./containers/COMMODITIES/Pools/juniorPool/interestPayment";
import PoolDetails from "./containers/COMMODITIES/Pools/juniorPool/poolDetails";
import Repayments from "./containers/COMMODITIES/Pools/juniorPool/rePayments";
import Drawdown from "./containers/COMMODITIES/Pools/juniorPool/drawdown";
import CTransfer from "./containers/COMMODITIES/Transfer";
import SenoirPoolDetails from "./containers/COMMODITIES/Pools/seniorPool/senoirPoolDetails";
import SingleProposal from "./containers/COMMODITIES/Governance/singleProposal";
import Market from "./containers/COMMODITIES/Market";
import Auctions from "./containers/COMMODITIES/Market/auctions";
import PlaceBid from "./containers/COMMODITIES/Market/placebidpopup";
import Hypersign from "./containers/COMMODITIES/Hypersign";

const routes = [
    {
        path: "/homepage",
        element: <HomePage />,
    },
    {
        path: "/rdashboard",
        element: <Dashboard />,
    },
    {
        path: "/rinvoice",
        element: <RInvoice />,
    },
    {
        path: "/rkyc",
        element: <Proforma />,
    },
    {
        path: "/rswap",
        element: <Swap />,
    },
    {
        path: "/ctransfer",
        element: <CTransfer />,
    },
    {
        path: "/ckyc",
        element: <Proforma />,
    },
    {
        path: "/rcontacts",
        element: <Contacts />,
    },
    {
        path: "/cportfolio",
        element: <CPortofolio />,
    },
    {
        path: "/cpools",
        element: <CPools />,
    },
    {
        path: "/cpools/junoirpooldetails/:cpoolId",
        element: <PoolDetails />,
    },
    {
        path: "/cpools/seniorpooldetails/:cpoolId",
        element: <SenoirPoolDetails />,
    },

    {
        path: "/cpoolinvest",
        element: <PoolInvest />,
    },
    {
        path: "/cpoolinterest",
        element: <PoolInterest />,
    },

    {
        path: "/crepayments",
        element: <Repayments />,
    },
    {
        path: "/cdrawdown",
        element: <Drawdown />,
    },
    {
        path: "/cgovernance",
        element: <CGovernance />,
    },
    {
        path: "/cgovernance/proposal/:proposalId",
        element: <SingleProposal />,
    },
    {
        path: "/cmarket",
        element: <Market />,
    },
    {
        path: "/cbid",
        element: <PlaceBid />,
    },
    {
        path: "/chypersign",
        element: <Hypersign />,
    },
]
export default routes;