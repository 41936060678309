import React from 'react'
import './index.scss'
import { Link } from 'react-router-dom';
import { Button, Table } from 'antd';
import NoData from '../../../components/NoData';

const MyAuctions = () => {

  const dataSource = [
    {
      key: '1',
      asset: <>
        <div className="asset_with_icon">
          <div className="icon">
            <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=100%20100w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=200%20200w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=400%20400w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=800%20800w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=1200%201200w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=1600%201600w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=2000%202000w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&" alt="@icon" />
          </div>
          <div className="name">LPTOKEN</div>
        </div>
      </>,
      price: "1,932 USDC",
      time: '4H 58M 52S',
      currentBid: "1,923 USDC"
    },
    {
      key: '2',
      asset: <>
        <div className="asset_with_icon">
          <div className="icon">
            <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=100%20100w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=200%20200w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=400%20400w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=800%20800w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=1200%201200w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=1600%201600w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=2000%202000w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&" alt="@icon" />
          </div>
          <div className="name">LPTOKEN</div>
        </div>
      </>,
      price: "1,932 USDC",
      time: '4H 58M 52S',
      currentBid: "1,923 USDC"
    },
  ];

  const columns = [
    {
      title: 'Asset',
      dataIndex: 'asset',
      key: 'asset',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: 'Current Bid',
      dataIndex: 'currentBid',
      key: 'currentBid',
    },
  ];


  return (
    <div className="open_market_main_container">
      <div className="open_market_container">
        <h3 className='auction_heading'>My Auctions</h3>
        <Table
          dataSource={dataSource}
          columns={columns}
          className='custom_table'
          locale={{ emptyText: <NoData /> }}
          rowClassName={() => 'custom-table-with-hover'}
        // onRow={(record, rowIndex) => {
        //   return {
        //     onClick: () => handelClickAuctionTable(record, rowIndex),
        //   };
        // }}
        />
      </div>
    </div>
  )
}

export default MyAuctions