import { Button, Input, Select, message } from 'antd'
import * as React from "react";
import { amountConversion, amountConversionWithComma, getAmount, getDenomBalance } from '../../../../utils/coin';
import { useDispatch, useSelector } from 'react-redux';
import { comdex } from '../../../../config/network';
import { tokenIcon } from '../../../../utils/icons';
import { commaSeparator } from '../../../../utils/number';
import { useState } from 'react';
import { transactionForRepaymentByJuniorReq } from '../../../../services/pools/poolsWrite';
import { setBalanceRefresh } from '../../../../action/account';

const PoolInterest = ({ poolData, cpoolId, AvailableAssetBalance, address, refreshBalance, balances }) => {

  const dispatch = useDispatch();
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (address, amount, pool_id, denom) => {
    setLoading(true)
    transactionForRepaymentByJuniorReq(address, amount, pool_id, denom, (error, result) => {
      if (error) {
        message.error(error?.rawLog || "Transaction Failed")
        setLoading(false)
        return;
      }
      console.log(result, "result");
      message.success("Transaction Success")
      dispatch(setBalanceRefresh(refreshBalance + 1))
      setLoading(false)
    })
  }


  return (
    <div className="div">
      <div className="div-17">
        <div className="div-18">
          <div className="column">
            <div className="div-19">
              <div className="div-20">
                <div className="div-invest-22">Available {commaSeparator(AvailableAssetBalance || 0)} {poolData?.asset_info?.name}</div>
              </div>
              <div className="div-invest-23">
                <div className="column">
                  <Select suffixIcon={false} placeholder="Select Token" defaultValue="token1" dropdownClassName="comdex_select_dropdown" style={{ width: 180 }} className='comdex_select' >
                    <Select.Option value="token1">
                      <div className="token_with_icons">
                        <div className="icons"><img src="https://dapps-coins.comdex.one/USDCoin.svg" alt="@icon" /></div>
                        <div className="name">
                          <div className="token_name">{poolData?.asset_info?.name}</div>
                          <div className="chain_name">Noble</div>
                        </div>
                      </div>
                    </Select.Option>
                  </Select>
                </div>
                <Input placeholder="0.00" min={0} type='number' className='comdex_input' onChange={(e) => setAmount(e.target.value)} />
              </div>
              <Button type="primary" className='btn-primary pay-button mt-5 mb-2'
                onClick={() => handleSubmit(address, getAmount(amount, poolData?.asset_info?.decimal), Number(cpoolId), poolData?.asset_info?.denom)}
                loading={loading}
                disabled={
                  loading ||
                  !AvailableAssetBalance ||
                  !amount
                }
              >
                Pay
              </Button>
            </div>
          </div>
          <div className="column-2">
            <div className="div-29">
              <div className="div-30">
                <div dangerouslySetInnerHTML={{ __html: tokenIcon }} />
                <div className="div-31">
                  <div className="div-32">Interest Pending </div>
                  <div className="div-33">{amountConversionWithComma(poolData?.interest_pending || 0, comdex?.coinDecimals, poolData?.asset_info?.decimal)} {poolData?.asset_info?.name}</div>
                </div>
              </div>
              <div className="div-34">
                <div dangerouslySetInnerHTML={{ __html: tokenIcon }} />
                <div className="div-35">
                  <div className="div-36">Total Interest Paid</div>
                  <div className="div-37">{amountConversionWithComma(poolData?.interest_paid || 0, comdex?.coinDecimals, poolData?.asset_info?.decimal)} {poolData?.asset_info?.name}</div>
                </div>
              </div>
              <div className="div-34">
                <div dangerouslySetInnerHTML={{ __html: tokenIcon }} />
                <div className="div-35">
                  <div className="div-36">Total Accrued</div>
                  <div className="div-37">{amountConversionWithComma(poolData?.interest_accrued || 0, comdex?.coinDecimals, poolData?.asset_info?.decimal)} {poolData?.asset_info?.name}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default PoolInterest