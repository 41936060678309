import React, { useEffect, useState } from 'react'
import './index.scss';
import { Steps } from 'antd';
import Name from './name';
import Documents from './documents';
import Review from './review';
import Submit from './submit';
import { useDispatch, useSelector } from 'react-redux';
import { setProformaCurrentStep } from '../../../action/proforma';
import ProformaForm from './proforma';
import { getContactInfo } from '../../../services/Proforma/proformaContractRead';
import KYCApproved from './KYCApproved';


const Proforma = () => {

    const address = useSelector(state => state.account.address);
    const [loading, setLoading] = useState(false);
    const [contactinfo, setContactinfo] = useState("");


    // Query 
    const fetchContactInfo = (address) => {
        setLoading(true)
        getContactInfo(address).then((res) => {
            // setTotalReceivable(res?.[0])
            setContactinfo(res)
            setLoading(false)

        }).catch((error) => {
            setLoading(false)
            setContactinfo()
            console.log(error);
        })
    }

    useEffect(() => {
        if (address) {
            fetchContactInfo(address)
        }
    }, [address])

    if (loading) {
        return <h1 style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "75vh" }}>Loading...</h1>
    }

    return (
        <>
            {contactinfo && contactinfo ? <KYCApproved /> : <ProformaForm />}

        </>
    )
}

export default Proforma