import React from 'react'
import './index.scss'
import { Link } from 'react-router-dom';
import { AudioOutlined } from '@ant-design/icons';
import { Input, Space, Select } from 'antd';

const CGovernance = () => {

    const { Search } = Input;
    const suffix = (
        <AudioOutlined
            style={{
                fontSize: 16,
                color: '#1677ff',
            }}
        />
    );
    const onSearch = (value, _e, info) => console.log(info?.source, value);

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    const StatCard = ({ iconSrc, iconAlt, title, value }) => (
        <div className="stat-card">
            <img loading="lazy" src={iconSrc} alt={iconAlt} className="icon" />
            <div className="content">
                <div className="title">{title}</div>
                <div className="value">{value}</div>
            </div>
        </div>
    );

    function ProposalCard({ proposalNumber, status, proposalTitle, votingStarts, votingEnds }) {
        return (
            <section className="proposal-card">
                <header className="proposal-card-header">
                    <h3 className="proposal-number">#{proposalNumber}</h3>
                    <p className={`status ${status.toLowerCase()}`}>{status}</p>
                </header>
                <h4 className="proposal-title">{proposalTitle}</h4>
                <footer className="proposal-dates">
                    <div className="date-info">
                        <p className="date-title">Voting Starts</p>
                        <time className="date">{votingStarts}</time>
                    </div>
                    <div className="date-info">
                        <p className="date-title">Voting Ends</p>
                        <time className="date">{votingEnds}</time>
                    </div>
                </footer>
            </section>
        );
    }

    const proposals = [
        { proposalNumber: 1, status: "Executed", proposalTitle: "Waive Drawdown fee for stAtom vaults for next 60 days", votingStarts: "13-07-2023", votingEnds: "16-07-2023" },
        // { proposalNumber: 43, status: "Executed", proposalTitle: "Waive Drawdown fee for stAtom vaults for next 60 days", votingStarts: "13-07-2023", votingEnds: "16-07-2023" },
        // { proposalNumber: 43, status: "Executed", proposalTitle: "Waive Drawdown fee for stAtom vaults for next 60 days", votingStarts: "13-07-2023", votingEnds: "16-07-2023" },
        // { proposalNumber: 43, status: "Executed", proposalTitle: "Waive Drawdown fee for stAtom vaults for next 60 days", votingStarts: "13-07-2023", votingEnds: "16-07-2023" },
        // Additional proposals would follow the same structure...
    ];

    const stats = [
        {
            iconSrc: 'https://cdn.builder.io/api/v1/image/assets/TEMP/b2883577ffc3f2069db943a2475c74b20d6d7a4c4140c838e5878d39f163b073?apiKey=0d50848586c3436880ef47e44d650c56&',
            iconAlt: 'Total Supply Icon',
            title: 'Total Supply',
            value: '255,892,865 USD',
        },
        {
            iconSrc: 'https://cdn.builder.io/api/v1/image/assets/TEMP/a9dc11d69621499cec493a6067e5fb17f4dc81ab562f0775619574762fd3f8f9?apiKey=0d50848586c3436880ef47e44d650c56&',
            iconAlt: 'Total Proposal Icon',
            title: 'Total Proposal',
            value: '1',
        },
        {
            iconSrc: 'https://cdn.builder.io/api/v1/image/assets/TEMP/58b6f1d0930ee5f4f28aea0931388cabe45e0d8bab04d37a4f21673d830c3c2b?apiKey=0d50848586c3436880ef47e44d650c56&',
            iconAlt: 'Average Participation Icon',
            title: 'Average Participation',
            value: '39.02%',
        },
    ];


    return (
        <div>
            <div className="governance_main_container">
                <div className="governance_container">
                    <section className="dashboard">
                        <div className="stat-cards">
                            {stats.map((stat, index) => (
                                <StatCard key={index} {...stat} />
                            ))}
                        </div>
                    </section>

                    <div className="proposal_main_container">
                        <div className="proposal_container mb-3">
                            <main className="proposals-section">
                                <div className="proposal_header_main_container">
                                    <div className="proposal_header_container">
                                        <div className="left_container">
                                            <h2 className="section-title">Proposal</h2>
                                        </div>
                                        <div className="right_container">
                                            <div className="search_container">
                                                <Search
                                                    placeholder="Search"
                                                    onSearch={onSearch}
                                                    className='comdex_search'
                                                    style={{
                                                        width: 230,
                                                    }}
                                                />
                                            </div>
                                            <div className="filter_container">
                                                <Select defaultValue="all" style={{ width: 120, }}
                                                    onChange={handleChange}
                                                    dropdownClassName="comdex_select_dropdown"
                                                    options={[
                                                        {
                                                            value: 'all',
                                                            label: 'All',
                                                        },
                                                        {
                                                            value: 'open',
                                                            label: 'Open',
                                                        },
                                                        {
                                                            value: 'pending',
                                                            label: 'Pending',
                                                        },
                                                        {
                                                            value: 'passed',
                                                            label: 'Passed',
                                                        },
                                                        {
                                                            value: 'failed',
                                                            label: 'Failed',
                                                        },
                                                        {
                                                            value: 'rejected',
                                                            label: 'Rejected',
                                                        },
                                                    ]}
                                                />
                                            </div>
                                        </div>



                                    </div>
                                </div>
                                <div className="proposals-container">
                                    {proposals.map((proposal, index) => (
                                        <Link to='/cgovernance/proposal/1'><ProposalCard key={index} {...proposal} /></Link>
                                    ))}
                                </div>
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CGovernance