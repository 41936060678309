import { envConfig } from "./envConfig";

export const comdex = {
    chainId: envConfig?.chainId,
    chainName: envConfig?.chainName,
    rpc: envConfig?.rpc,
    rest: envConfig?.rest,
    explorerUrlToTx: envConfig?.explorerUrlToTx,
    walletUrlForStaking: envConfig?.comdexStakingUrl,
    coinGeckoId: envConfig?.coinGeckoId,
    coinDenom: envConfig?.coinDenom,
    coinMinimalDenom: envConfig?.coinMinimalDenom,
    coinDecimals: envConfig?.coinDecimals,
    prefix: envConfig?.prefix,
    coinType: envConfig?.coinType,
    symbol: envConfig?.symbol,
    networkTag: envConfig?.networkTag,
    webSocketApiUrl: envConfig?.webSocketApiUrl,
};

export const cmst = {
    coinDenom: "CMST",
    coinMinimalDenom: "ucmst",
    coinDecimals: 6,
    symbol: "CMST",
};

export const proformaContractAddress = "comdex1p4lqunauqgstt6ydszx59y3pg2tkaxlnujl9m5ldz7nqcrn6tjzqlz9pkm";
export const poolContractAddress = "comdex16e9jwy72pxt5fde8s00r80xwlezs7ty7nxrce7g7gu6wlqz750wqy2shzh";