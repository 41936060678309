import { Button, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getContactInfo } from '../../../services/Proforma/proformaContractRead';
import Lottie from "react-lottie";
import processingAnimation from '../../../assets/Lottefile/processing.json';
import successAnimation from '../../../assets/Lottefile/success.json';
import './index.scss';

const KYCApproved = () => {

    const address = useSelector(state => state.account.address);
    const [loading, setLoading] = useState(false);
    const [contactinfo, setContactinfo] = useState("");


    // Query 
    const fetchContactInfo = (address) => {
        setLoading(true)
        getContactInfo(address).then((res) => {
            // setTotalReceivable(res?.[0])
            setContactinfo(res)
            setLoading(false)

        }).catch((error) => {
            setLoading(false)
            setContactinfo()
            console.log(error);
        })
    }

    useEffect(() => {
        if (address) {
            fetchContactInfo(address)
        }
    }, [address])



    const successOptions = {
        loop: true,
        autoplay: true,
        animationData: successAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    if (loading) {
        return <h1 style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "75vh" }}>Loading...</h1>
    }

    return (
        <div>
            <div className="approved_kyc_main_container">
                <div className="approved_kyc_container">
                    <div className="proforma_main_container proforma_main_container">
                        <div className="proforma_container ">
                            <div className="proforma_review_main_container performa_content">
                                <div className="proforma_review_container">
                                    <div className="row_container">
                                        <div className="approved_kyc_heading">Congratulations!!! You are KYC verified.</div>
                                        <Lottie
                                            options={successOptions}
                                            height={200}
                                            width={200}
                                        />
                                        <div className="single_row">
                                            <div className="title">Name</div>
                                            <div className="value">{contactinfo.name}</div>
                                        </div>
                                        <div className="single_row">
                                            <div className="title">Email</div>
                                            <div className="value">{contactinfo.email_id}</div>
                                        </div>
                                        <div className="single_row">
                                            <div className="title">Phone Number</div>
                                            <div className="value">{contactinfo.phone_number}</div>
                                        </div>
                                        <div className="single_row">
                                            <div className="title">Company Name</div>
                                            <div className="value">{contactinfo.company_name}</div>
                                        </div>
                                        <div className="single_row">
                                            <div className="title">Address</div>
                                            <div className="value">{contactinfo.address}</div>
                                        </div>
                                        {/* <div className="single_row">
                            <div className="title">Incorporation Documents</div>
                            <div className="value">Astra Solutions.pdf</div>
                        </div>
                        <div className="single_row">
                            <div className="title">UBO passport copies</div>
                            <div className="value">Astra Solutions.pdf</div>
                        </div>
                        <div className="single_row">
                            <div className="title">Proof of Business Address</div>
                            <div className="value">Astra Solutions.pdf</div>
                        </div>
                        <div className="single_row">
                            <div className="title">Proof of Business License </div>
                            <div className="value">Astra Solutions.pdf</div>
                        </div> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default KYCApproved