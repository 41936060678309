import React, { useState } from 'react'
import { Button, Form, Input, Select, Upload } from 'antd';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setProformaCurrentStep } from '../../../action/proforma';


const Name = ({ setFormData, formData }) => {
    const dispatch = useDispatch();
    const proformaCurrentStep = useSelector(state => state.proforma.proformaCurrentStep)


    const { Option } = Select;



    const onFinish = (values) => {
        console.log('Success:', values);
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleNext = () => {
        console.log(formData, "formData");
        dispatch(setProformaCurrentStep(proformaCurrentStep + 1))

    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => {
            const updatedFormData = { ...prevFormData, [name]: value };
            return updatedFormData;
        });
    }

    console.log(formData, "formData");

    return (
        <div>
            <div className="proforma_name_main_container">
                <div className="proforma_name_container">
                    <div className="contact-form-container">
                        <Form name="basic"
                            // style={{ maxWidth: 600, }}
                            layout="vertical"
                            initialValues={{ remember: true, }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            // autoComplete="off"
                            className='comdex_form'
                        >
                            <Form.Item
                                label="Full Name"
                                name="fullname"
                                rules={[{ required: true, message: 'Please input your username!' }]}
                                className="custom-form-item"
                                onChange={handleChange}
                            >
                                <Input name="fullname" placeholder="Your Name" value={formData.fullname} defaultValue={formData.fullname} />
                            </Form.Item>

                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[{ required: true, message: 'Please input your username!' }]}
                                className="custom-form-item"
                                onChange={handleChange}
                            >
                                <Input type='email' name="email" placeholder="Your Email" value={formData.email} defaultValue={formData.email} />
                            </Form.Item>

                            <Form.Item
                                label="Phone Number"
                                name="number"
                                rules={[{ required: true, message: 'Please input your username!' }]}
                                className="custom-form-item"
                                onChange={handleChange}
                            >
                                <Input type='number' name="number" placeholder="Phone Number" value={formData.number} defaultValue={formData.number} />
                            </Form.Item>

                            <Form.Item
                                label="Company Name"
                                name="companyName"
                                rules={[{ required: true, message: 'Please input your username!' }]}
                                className="custom-form-item"
                                onChange={handleChange}
                            >
                                <Input name="companyName" placeholder="Company Name" value={formData.companyName} defaultValue={formData.companyName} />
                            </Form.Item>

                            <Form.Item
                                label="Address"
                                name="addressLine1"
                                rules={[{ required: true, message: 'Please input your username!' }]}
                                className="custom-form-item"
                                onChange={handleChange}
                            >
                                <Input.TextArea name="addressLine1" value={formData.addressLine1} defaultValue={formData.addressLine1} style={{ resize: 'none' }} rows={1} resize="false" placeholder="Address Line 1" />
                            </Form.Item>

                            {/* <Form.Item
                                label="Address Line 2"
                                name="caddressLine2"
                                rules={[{ required: true, message: 'Please input your username!' }]}
                                className="custom-form-item"
                            >
                                <Input.TextArea style={{ resize: 'none' }} rows={1} resize="false" placeholder="Address Line 2" />
                            </Form.Item> */}

                            <Form.Item className='btn_container'>
                                {/* <Button type="primary" htmlType="submit" className='btn-primary'> */}
                                <Button type="primary" onClick={handleNext} className='btn-primary'>
                                    Next
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Name