import { Button, Input, Select, message } from 'antd'
import * as React from "react";
import { amountConversion, amountConversionWithComma, getAmount, getDenomBalance } from '../../../../utils/coin';
import { useDispatch, useSelector } from 'react-redux';
import { comdex } from '../../../../config/network';
import { tokenIcon } from '../../../../utils/icons';
import { commaSeparator } from '../../../../utils/number';
import { useState } from 'react';
import { transactionForRepaymentByJuniorReq, transactionForWithdraw, transactionForWithdrawAll } from '../../../../services/pools/poolsWrite';
import { setBalanceRefresh } from '../../../../action/account';


const Withdraw = ({ poolData, cpoolId, AvailableAssetBalance, address, refreshBalance }) => {

    const dispatch = useDispatch();
    const [amount, setAmount] = useState("");
    const [loading, setLoading] = useState(false);

    let tokenId = 0;

    const handleWithdrawAll = (address, pool_id) => {
        setLoading(true)
        transactionForWithdrawAll(address, pool_id, (error, result) => {
            if (error) {
                message.error(error?.rawLog || "Transaction Failed")
                setLoading(false)
                return;
            }
            message.success("Transaction Success")
            console.log(result, "transactionForWithdrawAll");
            dispatch(setBalanceRefresh(refreshBalance + 1))
            setLoading(false)
        })
    }

    const handleWithdraw = (address, token_id, amount) => {
        setLoading(true)
        transactionForWithdraw(address, token_id, amount, (error, result) => {
            if (error) {
                message.error(error?.rawLog || "Transaction Failed")
                setLoading(false)
                return;
            }
            message.success("Transaction Success")
            console.log(result, "transactionForWithdrawAll");
            dispatch(setBalanceRefresh(refreshBalance + 1))
            setLoading(false)
        })
    }


    return (
        <div>
            <div className="div">
                <div className="div-17">
                    <div className="div-18">
                        <div className="column">
                            <div className="div-19">
                                <div className="div-20">
                                    <div className="div-invest-22">Available {commaSeparator(AvailableAssetBalance || 0)} {poolData?.asset_info?.name}</div>
                                </div>
                                <div className="div-invest-23">
                                    <div className="column">
                                        <Select suffixIcon={false} placeholder="Select Token" defaultValue="token1" dropdownClassName="comdex_select_dropdown" style={{ width: 180 }} className='comdex_select' >
                                            <Select.Option value="token1">
                                                <div className="token_with_icons">
                                                    <div className="icons"><img src="https://dapps-coins.comdex.one/USDCoin.svg" alt="@icon" /></div>
                                                    <div className="name">
                                                        <div className="token_name">{poolData?.asset_info?.name}</div>
                                                        <div className="chain_name">Noble</div>
                                                    </div>
                                                </div>
                                            </Select.Option>
                                        </Select>
                                    </div>
                                    <Input placeholder="0.00" disabled min={0} type='number' className='comdex_input' onChange={(e) => setAmount(e.target.value)} />
                                </div>

                                {/* <div className="btn_container" style={{ justifyContent: "flex-end" }}>
                                    <Button type="primary" style={{ margin: "0" }} className='btn-primary pay-button mt-5 mb-2'
                                        onClick={() => handleWithdrawAll(address, Number(cpoolId))}
                                        loading={loading}
                                    // disabled={
                                    //     loading ||
                                    //     !AvailableAssetBalance ||
                                    //     !amount
                                    // }
                                    >
                                        Withdraw All
                                    </Button>
                                    <Button type="primary" style={{ margin: "0" }} className='btn-primary pay-button mt-5 mb-2'
                                        onClick={() => handleWithdraw(address, Number(tokenId), Number(amount))}
                                        loading={loading}
                                    // disabled={
                                    //     loading ||
                                    //     !AvailableAssetBalance ||
                                    //     !amount
                                    // }
                                    >
                                        Withdraw
                                    </Button>
                                </div> */}
                                <Button type="primary" className='btn-primary pay-button mt-5 mb-2'
                                    onClick={() => handleWithdrawAll(address, Number(cpoolId))}
                                    loading={loading}
                                // disabled={
                                //     loading ||
                                //     !AvailableAssetBalance ||
                                //     !amount
                                // }
                                >
                                    Withdraw All
                                </Button>

                            </div>
                        </div>
                        <div className="column-2">
                            <div className="div-29">
                                <div className="div-30">
                                    <div dangerouslySetInnerHTML={{ __html: tokenIcon }} />
                                    <div className="div-31">
                                        <div className="div-32">Interest Pending </div>
                                        <div className="div-33">{amountConversionWithComma(poolData?.interest_pending || 0, comdex?.coinDecimals, poolData?.asset_info?.decimal)} {poolData?.asset_info?.name}</div>
                                    </div>
                                </div>
                                <div className="div-34">
                                    <div dangerouslySetInnerHTML={{ __html: tokenIcon }} />
                                    <div className="div-35">
                                        <div className="div-36">Total Interest Paid</div>
                                        <div className="div-37">{amountConversionWithComma(poolData?.interest_paid || 0, comdex?.coinDecimals, poolData?.asset_info?.decimal)} {poolData?.asset_info?.name}</div>
                                    </div>
                                </div>
                                {/* <div className="div-34">
                                    <div dangerouslySetInnerHTML={{ __html: tokenIcon }} />
                                    <div className="div-35">
                                        <div className="div-36">Total Accrued</div>
                                        <div className="div-37">{amountConversionWithComma(poolData?.interest_accrued || 0, comdex?.coinDecimals, poolData?.asset_info?.decimal)} {poolData?.asset_info?.name}</div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Withdraw