import { Button, Input, message, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { transactionForDepositByJuniorReq } from '../../../../services/pools/poolsWrite';
import { useDispatch, useSelector } from 'react-redux';
import { amountConversion, amountConversionWithComma, getAmount, getDenomBalance } from '../../../../utils/coin';
import { setBalanceRefresh } from '../../../../action/account';

import { tokenIcon } from '../../../../utils/icons';
import { comdex } from '../../../../config/network';
import { commaSeparator } from '../../../../utils/number';

const PoolInvest = ({ poolData, cpoolId, AvailableAssetBalance, address, refreshBalance, balances }) => {
  const dispatch = useDispatch();

  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);


  const handleSubmit = (address, amount, pool_id, denom, tranchId) => {
    setLoading(true)
    transactionForDepositByJuniorReq(address, amount, pool_id, denom, tranchId, (error, result) => {
      if (error) {
        message.error(error?.rawLog || "Transaction Failed")
        setLoading(false)
        return;
      }
      message.success("Transaction Success")
      dispatch(setBalanceRefresh(refreshBalance + 1))
      setLoading(false)
    })
  }

  return (
    <div className="div-invest">
      <div className="div-invest-17">
        <div className="div-invest-18">
          <div className="column-invest">
            <div className="div-invest-19">
              <div className="div-invest-20">
                <div className="div-invest-22">Available {commaSeparator(AvailableAssetBalance || 0)} {poolData?.asset_info?.name}</div>
              </div>
              <div className="div-invest-23">
                <div className="column">
                  <Select suffixIcon={false} placeholder="Select Token" defaultValue="token1" dropdownClassName="comdex_select_dropdown" style={{ width: 180 }} className='comdex_select' >
                    <Select.Option value="token1">
                      <div className="token_with_icons">
                        <div className="icons"><img src="https://dapps-coins.comdex.one/USDCoin.svg" alt="@icon" /></div>
                        <div className="name">
                          <div className="token_name">{poolData?.asset_info?.name}</div>
                          <div className="chain_name">Noble</div>
                        </div>
                      </div>
                    </Select.Option>
                  </Select>
                </div>

                <Input placeholder="0.00" min={0} type='number' className='comdex_input' onChange={(e) => setAmount(e.target.value)} />
              </div>
              <Button type="primary"
                className='btn-primary swap-button mt-5 mb-2'
                onClick={() => handleSubmit(address, getAmount(amount, poolData?.asset_info?.decimal), Number(cpoolId), poolData?.asset_info?.denom, Number(poolData?.tranche_id))}
                loading={loading}
                disabled={
                  loading ||
                  !AvailableAssetBalance ||
                  !amount
                }

              >
                Invest
              </Button>
            </div>
          </div>
          <div className="column-invest--2" style={{ width: "100%" }}>
            <div className="div-invest-29">
              <div className="div-invest-30">
                <div className="column-invest-3">
                  <div dangerouslySetInnerHTML={{ __html: tokenIcon }} />
                </div>
                <div className="column-invest-4">
                  <div className="div-invest-31">
                    <div className="div-invest-32">Total Invested</div>
                    <div className="div-invest-33">{amountConversionWithComma(poolData?.invested || 0, comdex?.coinDecimals, poolData?.asset_info?.decimal)} {poolData?.asset_info?.name}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PoolInvest