import Router from "./Router";
import Header from "./components/Header";
// import AppLayout from "./components/Layout";
import HomePage from "./containers/Homepage";
import { useLocation } from 'react-router-dom';
import routes from "./routes";
import RwaLayout from "./components/Layout/RwaLayout";
import CommoditiesLayout from "./components/Layout/CommoditiesLayout";
import Proforma from "./containers/RWA/Proforma";
import KycForm from "./containers/COMMODITIES/Hypersign/kycForm";

function App() {
  // const location = useLocation();

  // // Access the pathname property to get the current route
  // const currentRoute = location.pathname;
  // const isRoutePresent = routes.find(route => route.path === currentRoute) !== undefined;
  // console.log(isRoutePresent, "isRoutePresent");
  // console.log(currentRoute, "currentRoute");

  // return (
  //   <div className="App">
  //     <Header />
  //     {isRoutePresent && (currentRoute.startsWith("/c") ? <CommoditiesLayout /> : <RwaLayout />)}
  //     {!isRoutePresent && <HomePage />}
  //   </div>
  // );



  const location = useLocation();
  const currentRoute = location.pathname;
  console.log(currentRoute, "currentRoute");

  // Check if the current route matches any layout-specific routes
  const isCommoditiesLayout = routes.some(route => currentRoute.startsWith("/c"));
  const isRwaLayout = routes.some(route => currentRoute.startsWith("/r"));


  return (
    <div className="App">
      <Header />
      {isCommoditiesLayout && <CommoditiesLayout />}
      {isRwaLayout && <RwaLayout />}
      {currentRoute === "/account" && <Proforma />}
      {currentRoute === "/kyc" && <KycForm />}
      {!isCommoditiesLayout && !isRwaLayout && !(currentRoute === "/account") && !(currentRoute === "/kyc") && <HomePage />}

    </div>
  );
}

export default App;

