import { combineReducers } from "redux";
import { ACCOUNT_ADDRESS_SET, ACCOUNT_BALANCES_SET, BALANCE_REFRESH_SET } from "../constants/account";


const address = (state = "", action) => {
    if (action.type === ACCOUNT_ADDRESS_SET) {
        return action.value;
    }

    return state;
};

const balances = (state = [], action) => {
    if (action.type === ACCOUNT_BALANCES_SET) {
        return action.value;
    }

    return state;
};

const refreshBalance = (state = 1, action) => {
    if (action.type === BALANCE_REFRESH_SET) {
        return action.value || state;
    }

    return state;
};

export default combineReducers({
    address,
    balances,
    refreshBalance
});

