import React, { useEffect, useState } from 'react'
import { Button, message, Steps, theme } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from 'antd';
import './index.scss';
import Submit from '../../RWA/Proforma/submit';
import processingAnimation from '../../../assets/Lottefile/processing.json';
import successAnimation from '../../../assets/Lottefile/success.json';
import failedAnimation from '../../../assets/Lottefile/failed.json';
import loadingAnimation from '../../../assets/Lottefile/LOADER.json';
import Lottie from "react-lottie";
import { Link } from 'react-router-dom';
import Hypersign from '.';
import { getContactInfo } from '../../../services/Proforma/proformaContractRead';

const KycForm = () => {
    const dispatch = useDispatch();
    const address = useSelector(state => state.account.address);

    const { token } = theme.useToken();
    const [current, setCurrent] = useState(0);
    const [transactionStatus, setTransactionStatus] = useState("processing")
    const [checked, setChecked] = useState(false)

    const [loading, setLoading] = useState(true);
    const [contactinfo, setContactinfo] = useState("");

    const onChange = (e) => {
        console.log(`checked = ${e.target.checked}`);
        setChecked(e.target.checked)
    };


    const processingOptions = {
        loop: true,
        autoplay: true,
        animationData: processingAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    const successOptions = {
        loop: true,
        autoplay: true,
        animationData: successAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    const failedOptions = {
        loop: true,
        autoplay: true,
        animationData: failedAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    const loadingOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };



    // useEffect(() => {
    //     fetchUserData('vp:hid:z4ZskMa8z9fSuEFC2Q61FkeZuuW9XVxQnhBfFVbyocmsm', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6IjEzZjcwZmFmN2Y1YzVkMDM1MjBiNzExODExMzZiNTk1ZjdjNiIsInVzZXJJZCI6ImNjZWJlOWNlLWM1MzItNDVmNS1hNzlhLWZkZDRjMzAxYTljOCIsImdyYW50VHlwZSI6ImFjY2Vzc19zZXJ2aWNlX2t5YyIsImttc0lkIjoiaHM6ZG9jOnA2bWwyNGVrLTE4aWljNm05cXhpdmJta2llX3ppbmM4amtua3RrZ3l0dGkiLCJ3aGl0ZWxpc3RlZENvcnMiOlsiKiJdLCJzdWJkb21haW4iOiJlbnQtNGM3MTg3NCIsImVkdklkIjoiaHM6ZGV2ZWxvcGVyLWRhc2hib2FyZDphcHA6MTNmNzBmYWY3ZjVjNWQwMzUyMGI3MTE4MTEzNmI1OTVmN2M2IiwiaWF0IjoxNzEwOTI0NDk5LCJleHAiOjE3MTYxMDg0OTl9.QD1uGuI1aFl_4Y5UCxC-It8LL791CPVxe5r5l0Bc1ek');

    // }, [])

    // Query 
    const fetchContactInfo = (address) => {
        setLoading(true)
        getContactInfo(address).then((res) => {
            // setTotalReceivable(res?.[0])
            setContactinfo(res)
            setLoading(false)

        }).catch((error) => {
            setLoading(false)
            setContactinfo()
            console.log(error);
        })
    }

    useEffect(() => {
        if (address) {
            fetchContactInfo(address)
        }
    }, [address])

    console.log(contactinfo, "contactinfo");

    const steps = [
        {
            title: 'First',
            content:
                <>
                    <div className="step_main_container">
                        <div className="steps_container">
                            <div className="content_container">
                                <div className="heading_container"> Connect and link your wallet</div>
                                <div className="para_container">To start, you need to sign a message to verify the wallet. You also need to agree to the data sharing agreement to continue with the identity verification process.</div>
                                <div className="checkbox_container">
                                    <div className="checkbox"><Checkbox onChange={onChange}></Checkbox></div>
                                    <div className="text">I agree to the data sharing agreement</div>
                                </div>
                            </div>
                            <div className=" kyc_button_container_center">
                                {/* <Button type="primary" onClick={() => next()}> Next</Button> */}
                                <Hypersign checked={checked} />
                            </div>
                        </div>
                    </div>
                </>,
        },
        // {
        //     title: 'Second',
        //     content: <>
        //         <div className="step_main_container">
        //             <div className="steps_container">
        //                 <div className="content_container">
        //                     <div className="heading_container"> Start onboarding to Centrifuge</div>
        //                     <div className="para_container">If you are a U.S. investor, it is only possible to onboard when you are an accredited investor.</div>
        //                 </div>
        //                 <div className="button_container">
        //                     <Button onClick={() => prev()} type="primary" > Previous</Button>
        //                     <Hypersign />
        //                 </div>
        //             </div>
        //         </div>
        //     </>,
        // },
        // {
        //     title: 'Last',
        //     content: <>
        //         <div className="step_main_container">
        //             <div className="animation_container">
        //                 {transactionStatus === "processing" && <>
        //                     <Lottie
        //                         options={processingOptions}
        //                         height={200}
        //                         width={200}
        //                     />
        //                 </>
        //                 }
        //                 {transactionStatus === "success" && <>
        //                     <Lottie
        //                         options={successOptions}
        //                         height={200}
        //                         width={200}
        //                     />
        //                 </>
        //                 }
        //                 {transactionStatus === "failed" && <>
        //                     <Lottie
        //                         options={failedOptions}
        //                         height={200}
        //                         width={200}
        //                     />
        //                 </>
        //                 }

        //             </div>
        //             <div className="text_container">
        //                 {transactionStatus === "processing" && <>
        //                     <div className="heading">Please Wait</div>
        //                     <div className="para">Your form is currently being processed.</div>
        //                 </>
        //                 }
        //                 {transactionStatus === "success" && <>
        //                     <div className="heading">Thank You!</div>
        //                     <div className="para">You form have been submitted.</div>
        //                 </>
        //                 }
        //                 {transactionStatus === "failed" && <>
        //                     <div className="heading">Sorry</div>
        //                     <div className="para">Your form has not been submitted.</div>
        //                 </>
        //                 }

        //             </div>
        //             <div className="button_container">
        //                 <Link to='/rdashboard'><Button type='primary' className='btn-primary'>Go To Dashboard</Button></Link>
        //             </div>
        //         </div>
        //     </>,
        // },
    ];


    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));

    if (loading) {
        return <h1 style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "75vh" }}>
            <Lottie
                options={loadingOptions}
                height={200}
                width={200}
            />
        </h1>
    }

    return (
        <div className='hypersign_kyc_main_container'>
            <div className="proforma_main_container approved_kyc_main_container">
                <div className="proforma_container approved_kyc_container">
                    {/* <Steps
                        current={current}
                        items={items}
                        className='comdex_steps'
                        labelPlacement='vertical'
                        size="small"
                    /> */}

                    {contactinfo && contactinfo ?
                        <div className="performa_content">
                            <div className="approved_kyc_heading">Congratulations!!! You are KYC verified.</div>
                            <Lottie
                                options={successOptions}
                                height={200}
                                width={200}
                            />
                        </div>

                        :

                        <div className="performa_content">
                            {steps[current].content}
                        </div>

                    }

                    {/* <div className="performa_content">
                        {steps[current].content}
                    </div> */}

                    {/* <div className="performa_content">
                        <div className="approved_kyc_heading">Congratulations!!! You are KYC verified.</div>
                        <Lottie
                            options={successOptions}
                            height={200}
                            width={200}
                        />
                    </div> */}

                    {/* <div style={{ marginTop: 24, }}>
                        {current < steps.length - 1 && (
                            <Button type="primary" onClick={() => next()}> Next</Button>
                        )}
                        {current === steps.length - 1 && (
                            <Button type="primary" onClick={() => message.success('Processing complete!')}> Done </Button>
                        )}
                        {current > 0 && (
                            <Button style={{ margin: '0 8px', }} onClick={() => prev()} type="primary" > Previous</Button>
                        )}
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default KycForm