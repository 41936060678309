import { Button, Input, Modal, Table } from 'antd';
import React, { useState } from 'react'
import NoData from '../../../components/NoData';

const OpenMarket = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const dataSource = [
        {
            key: '1',
            asset: <>
                <div className="asset_with_icon">
                    <div className="icon">
                        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=100%20100w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=200%20200w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=400%20400w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=800%20800w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=1200%201200w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=1600%201600w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=2000%202000w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&" alt="@icon" />
                    </div>
                    <div className="name">LPTOKEN</div>
                </div>
            </>,
            price: "1,932 USDC",
            time: '4H 58M 52S',
            currentBid: "1,923 USDC",
            action: "Item"
        },
        {
            key: '2',
            asset: <>
                <div className="asset_with_icon">
                    <div className="icon">
                        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=100%20100w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=200%20200w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=400%20400w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=800%20800w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=1200%201200w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=1600%201600w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=2000%202000w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&" alt="@icon" />
                    </div>
                    <div className="name">LPTOKEN</div>
                </div>
            </>,
            price: "1,932 USDC",
            time: '4H 58M 52S',
            currentBid: "1,923 USDC",
            action: "Item"
        },
    ];

    const columns = [
        {
            title: 'Asset',
            dataIndex: 'asset',
            key: 'asset',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
        },
        {
            title: 'Current Bid',
            dataIndex: 'currentBid',
            key: 'currentBid',
        },
    ];

    const handelClickAuctionTable = (e, rowIndex) => {
        console.log(e, rowIndex, "e");
        setSelectedRow(e?.action)
        showModal()
        // return (
        //   <PlaceBidModal
        //     params={params}
        //     auction={e?.action}
        //     discount={params?.auctionDiscountPercent}
        //   />
        // )
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div>
            <div className="open_market_main_container">
                <div className="open_market_container">
                    <h3 className='auction_heading'>Open Market</h3>
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        className='custom_table'
                        rowClassName={() => 'custom-table-with-hover'}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: () => handelClickAuctionTable(record, rowIndex),
                            };
                        }}
                        locale={{ emptyText: <NoData /> }}
                    />
                </div>
            </div>
            <div className="bidding_main_container">
                <div className="bidding_container">
                    <h2 className='auction_heading'>My Bidding </h2>
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        className='custom_table'
                         locale={{ emptyText: <NoData /> }}
                    />
                </div>
            </div>



            <Modal
                centered={true}
                className="palce_bid_modal"
                footer={null}
                header={null}
                open={isModalOpen}
                width={450}
                // closable={(width < 650) ? true : null}
                onOk={handleOk}
                onCancel={handleCancel}
            // closeIcon={null}
            >
                <>
                    {/* <div className="place_bid_modal_main_container">
                        <div className="place_bid_modal_container">
                            <div className="timer_container">
                                <div className="hr_box time_box">
                                    <div className="time">04</div>
                                    <div className="value">Hours</div>
                                </div>
                                <div className="min_box time_box">
                                    <div className="time">24</div>
                                    <div className="value">Minutes</div>
                                </div>
                                <div className="sec_box time_box">
                                    <div className="time">24</div>
                                    <div className="value">Seconds</div>
                                </div>
                            </div>
                            <div className="auction_quantity_container">
                                <div className="price_box">
                                    <div className="text">Current Auction Price</div>
                                    <div className="value">$13.5432</div>
                                </div>
                                <div className="target_cmst_box">
                                    <div className="text">Target CMST</div>
                                    <div className="value">35 CMST</div>
                                </div>
                            </div>
                            <div className="balance_container">
                                <div className="title_text">Quantity Bid For</div>
                                <div className="value_box">
                                    <div className="btn_box">
                                        <Button className='maxhalf'> MAX</Button>
                                    </div>
                                    24 CMST
                                </div>
                            </div>
                            <div className="token_with_input_container">
                                <div className="input_with_icon_container">
                                    <div className="input_container">
                                        <div className="icon_container">
                                            <div className="asset_with_icon">
                                                <div className="icon">
                                                    <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=100%20100w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=200%20200w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=400%20400w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=800%20800w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=1200%201200w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=1600%201600w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&width=2000%202000w,%20https://cdn.builder.io/api/v1/image/assets/TEMP/318a7bb38c418228956d984e33dec6d3ed589377161859e2b88a89bb360abd65?apiKey=78d75311bc524d2699f9343df12846a9&" alt="@icon" />
                                                </div>
                                                <div className="name">LPTOKEN</div>
                                            </div>
                                        </div>
                                        <div>
                                            <Input />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="receiable_amount_box">
                                <div className="text">
                                    You will Receive
                                </div>
                                <div className="value">
                                    50 CMST
                                </div>
                            </div>

                            <div className="button_container">
                                <Button type="primary" className="btn-filled">Place Bid</Button>
                            </div>

                        </div>
                    </div> */}

                    <div className="bid-div">
                        <div className="bid-div-2">
                            <div className="bid-div-3">
                                <div className="bid-column">
                                    <div className="bid-div-4">
                                        <div className="bid-div-5">Place Bid</div>
                                        <div className="bid-div-6">Current Auction Price</div>
                                        <div className="bid-div-7">$0.85253</div>
                                        <div className="bid-div-8">Quantity for Bid</div>
                                    </div>
                                </div>
                                <div className="bid-column-2">
                                    <div className="bid-div-9">
                                        {/* <img
                                            loading="lazy"
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/c25cb148d6dee9a6e64574465ed73dd2535a9ddc615cd09934ea58a835f643bb?apiKey=78d75311bc524d2699f9343df12846a9&"
                                            className="bid-img"
                                        /> */}
                                        <div className="bid-div-10">Time Remaining</div>
                                        <div className="bid-div-11">15H 47M 43S</div>
                                        <div className="bid-div-12">Available 0 USDC</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bid-div-13">
                            <div className="bid-div-14">
                                <img
                                    loading="lazy"
                                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/9afdabde69b97eaf1f1919319771d573d442677d7ae511ac030fb6642afa5721?apiKey=78d75311bc524d2699f9343df12846a9&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/9afdabde69b97eaf1f1919319771d573d442677d7ae511ac030fb6642afa5721?apiKey=78d75311bc524d2699f9343df12846a9&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9afdabde69b97eaf1f1919319771d573d442677d7ae511ac030fb6642afa5721?apiKey=78d75311bc524d2699f9343df12846a9&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/9afdabde69b97eaf1f1919319771d573d442677d7ae511ac030fb6642afa5721?apiKey=78d75311bc524d2699f9343df12846a9&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/9afdabde69b97eaf1f1919319771d573d442677d7ae511ac030fb6642afa5721?apiKey=78d75311bc524d2699f9343df12846a9&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9afdabde69b97eaf1f1919319771d573d442677d7ae511ac030fb6642afa5721?apiKey=78d75311bc524d2699f9343df12846a9&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/9afdabde69b97eaf1f1919319771d573d442677d7ae511ac030fb6642afa5721?apiKey=78d75311bc524d2699f9343df12846a9&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/9afdabde69b97eaf1f1919319771d573d442677d7ae511ac030fb6642afa5721?apiKey=78d75311bc524d2699f9343df12846a9&"
                                    className="bid-img-2"
                                />
                                <div className="bid-div-15">USDC</div>
                            </div>
                            <div className="bid-div-16">
                                <div className="bid-div-17"><Input type='number' className='comdex_input ' placeholder='0.00' /></div>
                                <div className="bid-div-18">$123,890.00</div>
                            </div>
                        </div>
                        <div className="bid-div-19">
                            <div className="bid-div-20">You will Receive</div>
                            <div className="bid-div-21">
                                <div className="bid-div-22">78999 LP</div>
                            </div>
                        </div>
                        <Button type="primary" className='mt-3'>Place Bid</Button>
                    </div>
                </>
            </Modal>

        </div>
    )
}

export default OpenMarket