import React from 'react'
import { Tabs } from 'antd';
import PendingRequest from './pendingRequest';
import RequestSent from './requestSent';
import AllContacts from './allContacts';
import './index.scss';
import AddContacts from './addContacts';
import NoData from '../../../components/NoData';

const Contacts = () => {

    const onChange = (key) => {
        console.log(key);
    };
    const items = [
        {
            key: '1',
            label: 'Pending Request',
            children: <PendingRequest />,
        },
        {
            key: '2',
            label: 'Request Sent',
            children: <RequestSent />,
        },
        {
            key: '3',
            label: 'All Contacts',
            children: <AllContacts />,
        },
        {
            key: '4',
            label: 'Add Contacts',
            children: <AddContacts />,
        },
    ];

    return (
        <div>
            <div className="contact_main_container">
                <div className="contact_container">
                    <Tabs
                        defaultActiveKey="1"
                        items={items}
                        onChange={onChange}
                        className='comdex_tabs'
                        noContentRender={() => <NoData />}
                    />

                </div>
            </div>
        </div>
    )
}

export default Contacts