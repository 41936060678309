import { Card, Col, Row } from 'antd'
import React from 'react'
import { fiatIcon, payableIcon, receiveableIcon } from "../../../../utils/icons";
import { commaSeparator, formatNumber } from '../../../../utils/number';
import { amountConversion } from '../../../../utils/coin';
import { comdex } from '../../../../config/network';


const About = () => {
  return (
    <div>
      <div className="pool_about_main_container">
        <div className="pool_about_container">
          <div className="pool_about_heading">Treasury Fund</div>
          <div className="pool_about_para">The Senior Pool gives Liquidity Providers (LPs) exposure to a variety of borrowers on the protocol. LPs who provide capital into the Senior Pool are capital providers in search of diversified exposure, diversified exposure across any Borrower Pool with Senior Pool</div>
          <div className="pool_about_card_container">
            <div className="top_container">
              <div className="cards_container">
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={12} lg={8}>
                    <Card  bordered={false}>
                      <div className="dashboard_stats_card_container">
                        <div className="dashboard_stats_card">
                          <div className="left">
                            <div className="icon">
                              <div dangerouslySetInnerHTML={{ __html: fiatIcon }} />
                            </div>
                          </div>
                          <div className="right">
                            <div className="name">Variable USDC APY</div>
                            <div className="value">8.17%</div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={8}>
                    <Card  bordered={false}>
                      <div className="dashboard_stats_card_container">
                        <div className="dashboard_stats_card">
                          <div className="left">
                            <div className="icon">
                              <div dangerouslySetInnerHTML={{ __html: fiatIcon }} />
                            </div>
                          </div>
                          <div className="right">
                            <div className="name">Variable APY</div>
                            <div className="value">10.00%</div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={8}>
                    <Card  bordered={false}>
                      <div className="dashboard_stats_card_container">
                        <div className="dashboard_stats_card">
                          <div className="left">
                            <div className="icon">
                              <div dangerouslySetInnerHTML={{ __html: payableIcon }} />
                            </div>
                          </div>
                          <div className="right">
                            <div className="name">Loan Term</div>
                            <div className="value">Open-Ended</div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={8}>
                    <Card  bordered={false}>
                      <div className="dashboard_stats_card_container">
                        <div className="dashboard_stats_card">
                          <div className="left">
                            <div className="icon">
                              <div dangerouslySetInnerHTML={{ __html: receiveableIcon }} />
                            </div>
                          </div>
                          <div className="right">
                            <div className="name">Liquidity</div>
                            <div className="value">34.90%</div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default About