import { Button, Input, message } from 'antd'
import React, { useState } from 'react'
import { transactionForAddContacts } from '../../../services/contacts/contactsContractWrite'
import { useSelector } from 'react-redux';

const AddContacts = () => {
    const address = useSelector(state => state.account.address);
    const [contactAddress, setContactAddress] = useState("")



    const handleSubmit = (address, contactAddress) => {
        transactionForAddContacts(address, contactAddress, (error, result) => {
            if (error) {
                message.error(error?.rawLog || "Transaction Failed")
                // setLoading(false)
                return;
            }
            message.success(
                // < Snack
                //     message={variables[lang].tx_success}
                //     explorerUrlToTx={comdex?.explorerUrlToTx}
                //     hash={result?.transactionHash}
                // />
                "Transaction Success"
            )
            console.log(result, "result");
        })
    }


    return (
        <>
            <div className="add_contacts_main_container">
                <div className="add_contacts_contaainer">
                    <div className="input_container">
                        <Input className="custom_input" placeholder='Enter Wallet Address' onChange={(e) => setContactAddress(e.target.value)} />
                    </div>
                    <div className="button_container mt-4">
                        <Button type='primary' disabled={!contactAddress || !address} onClick={() => handleSubmit(address, contactAddress)}>Send Request</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddContacts