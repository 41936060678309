import { combineReducers } from "redux";
import { SET_PROFORMA_CURRENT_STEP } from "../constants/proforma";


const proformaCurrentStep = (state = 0, action) => {
    if (action.type === SET_PROFORMA_CURRENT_STEP) {
        return action.value;
    }

    return state;
};


export default combineReducers({
    proformaCurrentStep,
});