import { Card, Col, Row } from 'antd'
import React from 'react'
import { amountConversionWithComma } from '../../../../utils/coin';
import { comdex } from '../../../../config/network';
import { Link } from 'react-router-dom';

const JuniorPoolList = ({ poolList }) => {

    const InfoSection = ({ title, content }) => (
        <div className="info-section">
            <div className="info-title">{title}</div>
            <div className="info-content">{content}</div>
        </div>
    );

    return (
        <div>
            <div className="senior_pool_single_card_main_container">
                <div className="senior_pool_single_card_container">
                    <Row gutter={[16, 16]}>


                        {poolList && poolList?.length > 0 && poolList?.map((item, index) => {
                            return <React.Fragment key={index}>
                                <Col xs={24} sm={24} md={12} lg={12}>
                                    <Link to={"/cpools/junoirpooldetails/" + item?.pool_id}>
                                        <Card hoverable className='pool_card'>
                                            <div className="single_pool_cards">
                                                <section className="card">
                                                    <header className="card-header">
                                                        <div className="pool-info">
                                                            <img loading="lazy" srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/630811a377593be83c3c2554ccdea99bd6680dc03b9a5d8f7352490a33c5655b?apiKey=0d50848586c3436880ef47e44d650c56&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/630811a377593be83c3c2554ccdea99bd6680dc03b9a5d8f7352490a33c5655b?apiKey=0d50848586c3436880ef47e44d650c56&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/630811a377593be83c3c2554ccdea99bd6680dc03b9a5d8f7352490a33c5655b?apiKey=0d50848586c3436880ef47e44d650c56&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/630811a377593be83c3c2554ccdea99bd6680dc03b9a5d8f7352490a33c5655b?apiKey=0d50848586c3436880ef47e44d650c56&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/630811a377593be83c3c2554ccdea99bd6680dc03b9a5d8f7352490a33c5655b?apiKey=0d50848586c3436880ef47e44d650c56&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/630811a377593be83c3c2554ccdea99bd6680dc03b9a5d8f7352490a33c5655b?apiKey=0d50848586c3436880ef47e44d650c56&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/630811a377593be83c3c2554ccdea99bd6680dc03b9a5d8f7352490a33c5655b?apiKey=0d50848586c3436880ef47e44d650c56&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/630811a377593be83c3c2554ccdea99bd6680dc03b9a5d8f7352490a33c5655b?apiKey=0d50848586c3436880ef47e44d650c56&" alt="Pool emblem" className="pool-img" />
                                                            <div className="pool-details">
                                                                <h2 className="pool-name"> {item?.pool_name}</h2>
                                                                <p className="pool-category">{item?.borrower_name}</p>
                                                            </div>
                                                        </div>
                                                        <div className="pool-status">
                                                            {/* <span className="status-label">{item?.status}</span> */}
                                                            {/* <span className="status-label status_close">{index === 3 ? "OPEN" : "CLOSED"}</span> */}
                                                            <span className={index === 3 ? "status-label " : "status-label status_close"}>{index === 3 ? "OPEN" : "CLOSED"}</span>
                                                        </div>
                                                    </header>
                                                    <div className="card-content">
                                                        {/* <InfoSection title="Value Locked" content={`${amountConversionWithComma(Number(item?.junior_tranche?.principal_deposited) + Number(item?.senior_tranche?.principal_deposited), comdex?.coinDecimals) || 0} USDC`} /> */}
                                                        <InfoSection title="Value Locked" content={`${amountConversionWithComma(Number(item?.assets), comdex?.coinDecimals) || 0} USDC`} />
                                                        <InfoSection title="" content="" />
                                                        <InfoSection title="APR" content={`${Number(item?.apr)}%`} />
                                                    </div>
                                                </section>
                                            </div>
                                        </Card>
                                    </Link>
                                </Col>
                            </React.Fragment>
                        })}


                    </Row>
                </div>
            </div>
        </div>
    )
}

export default JuniorPoolList