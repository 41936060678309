import React from 'react'
import './index.scss'
import { Link } from 'react-router-dom';
import { Button } from 'antd';

const PlaceBid = () => {
  return (
    <>
      <div className="bid-div">
        <div className="bid-div-2">
          <div className="bid-div-3">
            <div className="bid-column">
              <div className="bid-div-4">
                <div className="bid-div-5">Place Bid</div>
                <div className="bid-div-6">Current Auction Price</div>
                <div className="bid-div-7">$0.85253</div>
                <div className="bid-div-8">Quantity for Bid</div>
              </div>
            </div>
            <div className="bid-column-2">
              <div className="bid-div-9">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/c25cb148d6dee9a6e64574465ed73dd2535a9ddc615cd09934ea58a835f643bb?apiKey=78d75311bc524d2699f9343df12846a9&"
                  className="bid-img"
                />
                <div className="bid-div-10">Time Remaining</div>
                <div className="bid-div-11">15H 47M 43S</div>
                <div className="bid-div-12">Available 0 USDC</div>
              </div>
            </div>
          </div>
        </div>
        <div className="bid-div-13">
          <div className="bid-div-14">
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/9afdabde69b97eaf1f1919319771d573d442677d7ae511ac030fb6642afa5721?apiKey=78d75311bc524d2699f9343df12846a9&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/9afdabde69b97eaf1f1919319771d573d442677d7ae511ac030fb6642afa5721?apiKey=78d75311bc524d2699f9343df12846a9&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9afdabde69b97eaf1f1919319771d573d442677d7ae511ac030fb6642afa5721?apiKey=78d75311bc524d2699f9343df12846a9&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/9afdabde69b97eaf1f1919319771d573d442677d7ae511ac030fb6642afa5721?apiKey=78d75311bc524d2699f9343df12846a9&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/9afdabde69b97eaf1f1919319771d573d442677d7ae511ac030fb6642afa5721?apiKey=78d75311bc524d2699f9343df12846a9&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9afdabde69b97eaf1f1919319771d573d442677d7ae511ac030fb6642afa5721?apiKey=78d75311bc524d2699f9343df12846a9&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/9afdabde69b97eaf1f1919319771d573d442677d7ae511ac030fb6642afa5721?apiKey=78d75311bc524d2699f9343df12846a9&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/9afdabde69b97eaf1f1919319771d573d442677d7ae511ac030fb6642afa5721?apiKey=78d75311bc524d2699f9343df12846a9&"
              className="bid-img-2"
            />
            <div className="bid-div-15">USDC</div>
          </div>
          <div className="bid-div-16">
            <div className="bid-div-17">0.00</div>
            <div className="bid-div-18">$123,890.00</div>
          </div>
        </div>
        <div className="bid-div-19">
          <div className="bid-div-20">You will Receive</div>
          <div className="bid-div-21">
            <div className="bid-div-22">78999 LP</div>
            <Button type="primary">Place Bid</Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default PlaceBid
