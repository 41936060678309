import { CosmWasmClient } from "cosmwasm";
import { comdex, poolContractAddress } from '../../config/network'

const configin = {
    chainId: comdex?.chainId,
    rpcEndpoint: comdex?.rpc,
};

export const getAllPools = async () => {
    const client = await CosmWasmClient.connect(configin.rpcEndpoint);
    const config = await client.queryContractSmart(poolContractAddress, { "get_all_pools": {} });
    return await config;
}
export const getPoolInfoRequest = async (id) => {
    const client = await CosmWasmClient.connect(configin.rpcEndpoint);
    const config = await client.queryContractSmart(poolContractAddress, { "get_pool_info": { "id": id } });
    return await config;
}
export const getPoolRepaymentInfo = async (id) => {
    const client = await CosmWasmClient.connect(configin.rpcEndpoint);
    const config = await client.queryContractSmart(poolContractAddress, { "repayment_info": { "id": id } });
    return await config;
}