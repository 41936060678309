import { SigningCosmWasmClient } from "@cosmjs/cosmwasm-stargate";
import { comdex, proformaContractAddress } from "../../config/network";
import { KeplrWallet } from "../helper";


const customFees = {
    exec: {
        amount: [{ amount: "500000", denom: "ucmdx" }],
        gas: "300000",
    },
}

export const transactionForAddContacts = async (address, contactAddress, callback) => {

    const httpUrl = comdex?.rpc;
    let walletAddress = address;
    const handleMsg = {
        "create_request":
        {
            "address": contactAddress,
        }
    };


    // const fundsValues = [
    //     {
    //         "denom": "uharbor",
    //         "amount": getAmount(amount)
    //     }
    // ]


    const [offlineSigner] = await KeplrWallet(comdex?.chainId);

    await SigningCosmWasmClient.connectWithSigner(
        httpUrl,
        offlineSigner)
        .then((client) => {
            client.signAndBroadcast(
                walletAddress,
                [{
                    typeUrl: "/cosmwasm.wasm.v1.MsgExecuteContract",
                    value: {
                        sender: walletAddress,
                        contract: proformaContractAddress,
                        msg: new TextEncoder().encode(JSON.stringify(handleMsg)),
                        // funds: fundsValues
                    }
                }],
                customFees.exec,
            ).then((response) => {
                if (!response?.code) {
                    callback(null, response)

                }
                else {
                    console.log(response?.rawLog);
                    callback(response)

                }

            }).catch((err) => {
                console.log(err);
                callback(err)
            })
        }).catch((error) => {
            callback(error)
        });

}

export const transactionForAddPendingReq = async (address, requesterAddress, callback) => {

    const httpUrl = comdex?.rpc;
    let walletAddress = address;
    const handleMsg = {
        "accept_request":
        {
            "address": requesterAddress,
        }
    };



    // const fundsValues = [
    //     {
    //         "denom": "uharbor",
    //         "amount": getAmount(amount)
    //     }
    // ]


    const [offlineSigner] = await KeplrWallet(comdex?.chainId);

    await SigningCosmWasmClient.connectWithSigner(
        httpUrl,
        offlineSigner)
        .then((client) => {
            client.signAndBroadcast(
                walletAddress,
                [{
                    typeUrl: "/cosmwasm.wasm.v1.MsgExecuteContract",
                    value: {
                        sender: walletAddress,
                        contract: proformaContractAddress,
                        msg: new TextEncoder().encode(JSON.stringify(handleMsg)),
                        // funds: fundsValues
                    }
                }],
                customFees.exec,
            ).then((response) => {
                if (!response?.code) {
                    callback(null, response)

                }
                else {
                    console.log(response?.rawLog);
                    callback(response)

                }

            }).catch((err) => {
                console.log(err);
                callback(err)
            })
        }).catch((error) => {
            callback(error)
        });

}
