import React, { useState } from 'react'
import './index.scss'
import { Button, Modal } from 'antd';

const SingleProposal = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const proposalDetails = [
        { label: 'Voting Starts', value: '13-07-2023' },
        { label: 'Voting Ends', value: '16-07-2023' },
        { label: 'Proposer', value: 'comdex...0xjura' },
        { label: 'My Voting power', value: '56.90%' },
    ];

    function DetailSection({ label, value }) {
        return (
            <div className="detail-section">
                <div className="label">{label}</div>
                <div className="value">{value}</div>
            </div>
        );
    }

    const VoteDetail = ({ title, value }) => (
        <div className="detail">
            <div className="title">{title}</div>
            <div className="value">{value}</div>
        </div>
    );

    const OptionBar = ({ color, option, percentage }) => (
        <div className="option">
            <div className="indicator" style={{ backgroundColor: color }} />
            <div className="optionDetails">
                <div className="optionName">{option}</div>
                <div className="optionPercentage">{percentage}</div>
            </div>
        </div>
    );


    const VoteResultSection = () => {
        const options = [
            { color: '#03bc0a', option: 'Yes', percentage: '56.90%' },
            { color: '#e30b0b', option: 'No', percentage: '0.00%' },
            { color: '#ffa5a5', option: 'No With Veto', percentage: '6.00%' },
            { color: '#c7c7c7', option: 'Abstain', percentage: '4.00%' },
        ];

        return (
            <div className='vote_bar_main_container'>
                <section className="optionsSection">
                    {options.map((opt, idx) => (
                        <OptionBar key={idx} {...opt} />
                    ))}
                </section>
                <div className="progress_container"></div>

            </div>
        );
    };

    // Vote Modal 
    const OptionButton = ({ optionText }) => (
        <button className="option-button">{optionText}</button>
    );

    const VotingOptions = [
        { id: 'yes', text: 'Yes' },
        { id: 'no', text: 'No' },
        { id: 'noWithVeto', text: 'No with Veto' },
        { id: 'abstain', text: 'Abstain' }
    ];

    return (
        <div>
            <div className="single_proposal_main_container">
                <div className="single_proposal_container">
                    <article className="card">
                        <header className="card-header">
                            <h2>#43 Waive Drawdown fee for stAtom vaults for next 60 days</h2>
                        </header>
                        <section className="card-content">
                            {proposalDetails.map((detail, index) => (
                                <DetailSection key={index} label={detail.label} value={detail.value} />
                            ))}
                        </section>
                    </article>

                    <div className="middle_container">
                        <div className="card">
                            <section className="voteInfo">
                                <VoteDetail title="Vote Details" value="" />
                                <VoteDetail title="Total Votes" value="56.06M CMDX" />
                                <VoteDetail title="Current Quorum" value="100%" />
                            </section>
                            <VoteResultSection />
                            <div className="voteAction">
                                {/* <div className="progressBar" /> */}
                                <Button type='primary' onClick={showModal}>Vote</Button>
                            </div>

                        </div>
                    </div>

                    <div className="bottom_container mb-3">
                        <div className="card">
                            <article className="proposal-container">
                                <header className="proposal-header">Description</header>
                                <section className="proposal-description">
                                    This proposal introduces a strategic initiative to burn 50% of the CMDX generated from transaction fees on the Comdex chain.\nDetails - https://docs.google.com/document/d/1QBpZLLe-nAG_LSOB4BehAEW0fMqqaWrX7OlWmB5L42E/edit?usp=sharing                                    <p>
                                        <br />
                                        <p>
                                            No other parameters are being changed.
                                        </p>
                                        <br />
                                        <div className="vote_option_main_container">
                                            Vote <span className="vote-option vote-option-yes">Yes</span> to approve the increase of debt ceiling.
                                        </div>
                                        <div className="vote_option_main_container">
                                            Vote <span className="vote-option vote-option-no">No</span> to disapprove the increase of debt ceiling.
                                        </div>
                                        <div className="vote_option_main_container">
                                            Vote <span className="vote-option vote-option-veto">No With Veto</span> expresses that you strongly disagree and would like to see depositors penalised by revocation of their proposal deposit.
                                        </div>
                                        <div className="vote_option_main_container">
                                            Vote <span className="vote-option vote-option-abstain">Abstain</span> to express no interest in the proposal.
                                        </div>
                                    </p>
                                </section>
                            </article>
                        </div>
                    </div>
                </div>
            </div>

            {/* Vote Modal  */}
            <Modal
                title="Vote Now"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                centered={true}
                className='comdex_modal vote_modal'
                footer={false}
                width={450}
            >
                <section className="voting-panel">
                    <div className="voting-content">
                        {VotingOptions.map(option => (
                            <OptionButton key={option.id} optionText={option.text} />
                        ))}
                        {/* <button className="vote-submit">Vote</button> */}
                        <Button type='primary' className="vote-submit mt-2">Vote</Button>
                    </div>
                </section>
            </Modal>
        </div>
    )
}

export default SingleProposal