
import * as React from "react";
import { Link } from "react-router-dom";

const Banner = (props) => {
  return (
    <>
      <div className="div main_div">
        <div className="div-2 left_section" >
          <div className="div-3">Complete your KYC to Start</div>
          <div className="div-4">
            KYC is mandatory because it helps financial institutions verify the
            identity of their customers, assess potential risks and prevent
            fraud.
          </div>
        </div>
        <div className="right_section">
          <Link to="/kyc"> <div className="div-5">Complete Now</div></Link>
        </div>
      </div>
      <style jsx>{`
        .main_div {
          border-radius: 5px;
          border: 1px solid #2d60ff;
          background: linear-gradient(90deg, #2d60ff -1.26%, #539bff 99.37%);
          display: flex;
          justify-content: space-between;
          gap: 20px;
          padding: 19px 25px;
          flex-direction:row;
          align-items:center;
        }
        @media (max-width: 991px) {
          .div {
            flex-wrap: wrap;
            padding: 19px 20px;
          }
        }
        .div-2 {
          align-self: start;
          display: flex;
          flex-grow: 1;
          flex-basis: 0%;
          flex-direction: column;
          color: #fff;
          gap:0px;
        }
        @media (max-width: 991px) {
          .div-2 {
            max-width: 100%;
          }
        }
        .div-3 {
          font: 600 17px Inter, sans-serif;
          padding:0;
        }
        @media (max-width: 991px) {
          .div-3 {
            max-width: 100%;
          }
        }
        .div-4 {
          margin-top: 9px;
          font: 400 12px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .div-4 {
            max-width: 100%;
          }
        }
        .div-5 {
          border-radius: 5px;
          border: 1px solid #fff;
          background-color: #fff;
          justify-content: center;
          color: #2d60ff;
          white-space: nowrap;
          text-align: center;
          padding: 13px 20px;
          font: 700 14px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .div-5 {
            white-space: initial;
          }
        }
      `}</style>
    </>
  );
}

export default Banner;
