import React, { useEffect, useState } from 'react'
import { Button, Table, message } from 'antd';
import { getPendingInvoice } from '../../../services/inovice/invoiceContractRead';
import { useDispatch, useSelector } from 'react-redux';
import { truncateString } from '../../../utils/string';
import { commaSeparator } from '../../../utils/number';
import { amountConversion } from '../../../utils/coin';
import { comdex } from '../../../config/network';
import { transactionForAcceptInovice, transactionForPayInovice } from '../../../services/inovice/inovicecontractWrite';
import { setBalanceRefresh } from '../../../action/account';
import NoData from '../../../components/NoData';


const PendingInvoice = () => {
  const dispatch = useDispatch();

  const address = useSelector(state => state.account.address);
  const refreshBalance = useSelector(state => state.account.refreshBalance);
  const [totalSendInvoice, setTotalSendInvoice] = useState([])
  const [totalReceiveInvoice, setTotalReceiveInvoice] = useState([])
  const [loading, setLoading] = useState(false)
  const IpfsUrl = "https://gateway.pinata.cloud/ipfs/"

  // Query 
  const fetchTotalPendingInvoice = (address) => {
    setLoading(true)
    getPendingInvoice(address).then((res) => {
      console.log(res, "Pending res");
      // setTotalReceivable(res?.[0])
      setTotalSendInvoice(res?.sent_invoices)
      setTotalReceiveInvoice(res?.received_invoices)
      setLoading(false)

    }).catch((error) => {
      setLoading(false)
      console.log(error);

    })
  }




  const handleAccept = (address, invoiceId) => {
    transactionForAcceptInovice(address, invoiceId, (error, result) => {
      if (error) {
        message.error(error?.rawLog || "Transaction Failed")
        // setLoading(false)
        return;
      }

      message.success(
        // < Snack
        //     message={variables[lang].tx_success}
        //     explorerUrlToTx={comdex?.explorerUrlToTx}
        //     hash={result?.transactionHash}
        // />
        "Invoice Accepted"
      )
      dispatch(setBalanceRefresh(refreshBalance + 1))
      console.log(result, "result");
    })
  }

  const handlePay = (address, invoiceId, payableAmount, denom) => {
    transactionForPayInovice(address, invoiceId, payableAmount, denom, (error, result) => {
      if (error) {
        message.error(error?.rawLog || "Transaction Failed")
        // setLoading(false)
        return;
      }
      message.success(
        // < Snack
        //     message={variables[lang].tx_success}
        //     explorerUrlToTx={comdex?.explorerUrlToTx}
        //     hash={result?.transactionHash}
        // />
        "Transaction Success"
      )
      dispatch(setBalanceRefresh(refreshBalance + 1))
    })
  }

  const sentDataSource = totalSendInvoice && totalSendInvoice?.map((item, index) => {
    return {
      key: index,
      status: <>
        <div className="status_main_container">
          <div className={item?.invoice?.status === "raised" ? "status_container" : "reveived_status_container"}>
            <p style={{ textTransform: "uppercase" }}>{item?.invoice?.status}</p>
          </div>
        </div>
      </>,
      wallet: truncateString(item?.contact?.address, 6, 6),
      contact: item?.contact?.name,
      company: item?.contact?.company_name,
      serviceType: <p style={{ textTransform: "uppercase" }}>{item?.invoice?.service_type}</p>,
      advancePayment: `${commaSeparator(amountConversion(item?.invoice?.amount_paid?.amount || 0, comdex?.coinDecimals, 1000000))} USDC`,
      due: `${commaSeparator(amountConversion(item?.invoice?.due_amount?.amount || 0, comdex?.coinDecimals, 1000000))} USDC`,
      invoice: <a href={IpfsUrl + item?.invoice?.doc_uri} target='_blank'><p style={{ color: "#0287EA" }}>View</p> </a>,
    }
  })


  const receivedDataSource = totalReceiveInvoice && totalReceiveInvoice?.map((item, index) => {
    return {
      key: index,
      status: <>
        <div className="status_main_container">
          <div className={item?.invoice?.status === "raised" ? "status_container" : "reveived_status_container"}>
            <p style={{ textTransform: "uppercase" }}>{item?.invoice?.status}</p>
          </div>
        </div>
      </>,
      wallet: truncateString(item?.contact?.address, 6, 6),
      contact: item?.contact?.name,
      company: item?.contact?.company_name,
      serviceType: <p style={{ textTransform: "uppercase" }}>{item?.invoice?.service_type}</p>,
      advancePayment: `${commaSeparator(amountConversion(item?.invoice?.amount_paid?.amount || 0, comdex?.coinDecimals, 1000000))} USDC`,
      due: `${commaSeparator(amountConversion(item?.invoice?.due_amount?.amount || 0, comdex?.coinDecimals, 1000000))} USDC`,
      invoice: <a href={IpfsUrl + item?.invoice?.doc_uri} target='_blank'><p style={{ color: "#0287EA" }}>View</p> </a>,
      pay: <>
        <div className="pay_btn_container">
          <div className="pay_btn">
            <Button className="ant-btn-sm "
              onClick={() => {
                if (item?.invoice?.status === "raised") {
                  handleAccept(address, item?.invoice?.id)
                } else {
                  handlePay(address, item?.invoice?.id, item?.invoice?.due_amount?.amount, item?.invoice?.due_amount?.denom)
                }
              }
              }>
              {item?.invoice?.status === "raised" ? "Accept" : "Pay"}
            </Button>
          </div>
        </div>
      </>
    }
  })

  const columns = [
    {
      title: "Status",
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: "Wallet",
      dataIndex: 'wallet',
      key: 'wallet',
    },
    {
      title: "Contact",
      dataIndex: 'contact',
      key: 'contact',
    },
    {
      title: "Company",
      dataIndex: 'company',
      key: 'company',
    },

    {
      title: "Service Type",
      dataIndex: 'serviceType',
      key: 'serviceType',
    },

    {
      title: "Advance Payment",
      dataIndex: 'advancePayment',
      key: 'advancePayment',
    },
    {
      title: "Due",
      dataIndex: 'due',
      key: 'due',
    },
    {
      title: "Invoice",
      dataIndex: 'invoice',
      key: 'invoice',
    },

  ];

  const receivedColumns = [
    {
      title: "Status",
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: "Wallet",
      dataIndex: 'wallet',
      key: 'wallet',
    },
    {
      title: "Contact",
      dataIndex: 'contact',
      key: 'contact',
    },
    {
      title: "Company",
      dataIndex: 'company',
      key: 'company',
    },

    {
      title: "Service Type",
      dataIndex: 'serviceType',
      key: 'serviceType',
    },

    {
      title: "Advance Payment",
      dataIndex: 'advancePayment',
      key: 'advancePayment',
    },
    {
      title: "Due",
      dataIndex: 'due',
      key: 'due',
    },
    {
      title: "Invoice",
      dataIndex: 'invoice',
      key: 'invoice',
    },
    {
      title: "Pay",
      dataIndex: 'pay',
      key: 'pay',
    },

  ];


  useEffect(() => {
    if (address) {
      fetchTotalPendingInvoice(address)
    }
  }, [address, refreshBalance])



  return (
    <div>
      <div className="pending_invoice_main_container">
        <div className="pending_invoice_container">
          <div className="pending_inovice_sent_container">
            <h2>Sent</h2>
            <Table
              dataSource={sentDataSource}
              columns={columns}
              pagination={false}
              loading={loading}
              className='custom_table'
              locale={{ emptyText: <NoData /> }}
            />
          </div>
          <div className="pending_inovice_receive_container">
            <h2>Received</h2>
            <Table
              dataSource={receivedDataSource}
              columns={receivedColumns}
              pagination={false}
              loading={loading}
              className='custom_table'
              locale={{ emptyText: <NoData /> }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PendingInvoice