import React, { useEffect, useState } from "react";
import "./index.scss";
import { AudioOutlined } from "@ant-design/icons";
import { Card, Dropdown, Input, Space, Spin, message } from "antd";
import { Button, Drawer, Modal } from "antd";
import { Link, useLocation } from "react-router-dom";
import keplrIcon from "../../assets/images/keplrIcon.svg";
import leapIcon from "../../assets/images/leapIcon.png";
import metaMaskIcon from "../../assets/images/metamaskIcon.png";
import ledgerIcon from "../../assets/images/ledgerIcon.png";
import { initializeChain } from "../../services/keplr";
import { setAccountAddress, setAccountBalances } from "../../action/account";
import { encode, decode } from "js-base64";
import { useDispatch, useSelector } from "react-redux";
import { truncateString } from "../../utils/string";
// import HomeIcon from '../../assets/images/Home.svg'
// import HomeIcon from '../../assets/images/logo.png'
import HomeIcon from '../../assets/images/shipFi_logo.svg'
import RwaIcon from '../../assets/images/RWA_logo.svg'
import commoditiesIcon from '../../assets/images/comodities_logo.svg'
import { fetchAllBalances, fetchCoingeckoPrices, getWhiteListedAsset } from "../../services/bank/query";
import { AppSwitchIcon, copyIcon, disconnectIcon, product1Logo, product1LogoDark, product2Logo, product2LogoDark } from "../../utils/icons";
import { amountConversion, amountConversionWithComma, getDenomBalance } from "../../utils/coin";
import { comdex } from "../../config/network";
import { setAssetList } from "../../action/assets";
import AnatraCredLogo from '../../assets/images/app_logo_1.svg'
import AnatraCredLogoLight from '../../assets/images/app_logo_1_light.svg'
import AnatraFiLogoLight from '../../assets/images/app_logo_2_light.svg'
import AnatraFiLogo from '../../assets/images/app_logo_2.svg'
import { DownOutlined, SmileOutlined } from '@ant-design/icons';

const Header = () => {
    const location = useLocation();
    const currentRoute = location.pathname;

    const { Meta } = Card;
    const { Search } = Input;
    const dispatch = useDispatch();
    const refreshBalance = useSelector(state => state.account.refreshBalance);
    const balances = useSelector(state => state.account.balances);
    const address = useSelector(state => state.account.address)

    const [open, setOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDisconnectModalOpen, setDisconnectIsModalOpen] = useState(false);
    const [inProgress, setInProgress] = useState(false);

    const AvailableAssetBalance = amountConversionWithComma(getDenomBalance(balances, "ucmdx") || 0, comdex?.coinDecimals, 1000000);


    window.addEventListener("keplr_keystorechange", () => {
        let walletType = localStorage.getItem("loginType");
        if (walletType === "keplr") {
            handleConnectToWallet(walletType);
        }

    });

    window.addEventListener("leap_keystorechange", () => {
        let walletType = localStorage.getItem("loginType");
        if (walletType === "leap") {
            handleConnectToWallet(walletType);
        }
    });

    // Drawrer
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    // Modal
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    // Disconnect Modal
    const showDisconnectModal = () => {
        setDisconnectIsModalOpen(true);
    };
    const handleDisconnectOk = () => {
        setDisconnectIsModalOpen(false);
    };
    const handleDisconnectCancel = () => {
        setDisconnectIsModalOpen(false);
    };


    const handleDisconnect = () => {
        setDisconnectIsModalOpen(false);
        setIsModalOpen(false);
        dispatch(setAccountAddress(""));
        localStorage.removeItem("ac");
    };

    const onSearch = (value, _e, info) => console.log(info?.source, value);

    const suffix = (
        <AudioOutlined
            style={{
                fontSize: 16,
                color: "#1677ff",
            }}
        />
    );


    const homeDropDown = [
        {
            key: '1',
            label: (
                <Link to="/rdashboard">
                    <div className="app_change_logo_main_container">
                        <div className="app_chain_logo_container">
                            <img src={AnatraCredLogoLight} alt="Icon" />
                        </div>
                    </div>
                </Link>
            ),
        },
        {
            key: '2',
            label: (
                <Link to="/cportfolio">
                    <div className="app_change_logo_main_container">
                        <div className="app_chain_logo_container">
                            <img src={AnatraFiLogoLight} alt="Icon" />
                        </div>
                    </div>
                </Link>
            ),
        },

    ];

    const antraFiDropDown = [
        {
            key: '1',
            label: (
                <Link to="/rdashboard">
                    <div className="app_change_logo_main_container">
                        <div className="app_chain_logo_container">
                            <img src={AnatraCredLogoLight} alt="Icon" />
                        </div>
                    </div>
                </Link>

            ),
        },

    ];

    const antraCredDropDown = [
        {
            key: '1',
            label: (
                <Link to="/cportfolio">
                    <div className="app_change_logo_main_container">
                        <div className="app_chain_logo_container">
                            <img src={AnatraFiLogoLight} alt="Icon" />
                        </div>
                    </div>
                </Link>

            ),
        },

    ];

    const handleToggle = () => {
        const navLinks = document.querySelector(".nav-links");

        navLinks.classList.toggle("show-links");
    };

    const handleConnectToWallet = (walletType) => {
        setInProgress(true);

        initializeChain(walletType, (error, account) => {
            setInProgress(false);
            if (error) {
                message.error(error);
                return;
            }
            dispatch(setAccountAddress(account.address));
            // if (walletType === "metamask") {
            //     setAccountName("Metamask");
            // } else {
            //     fetchKeplrAccountName().then((name) => {
            //         setAccountName(name);
            //     });
            // }

            localStorage.setItem('ac', encode(account.address));
            localStorage.setItem('loginType', walletType || 'keplr');
            // showAccountConnectModal(false);
        });
    };

    const queryAllBalances = (address) => {
        fetchAllBalances(address, (error, result) => {
            if (error) {
                console.log(error, "Error in fetch Balances");
                return;
            }
            if (result) {
                // setCoingekoPrice(result)
                dispatch(setAccountBalances(result?.balances))
            }
        });
    };

    const fetchCoingeckoPrice = () => {
        fetchCoingeckoPrices((error, result) => {
            if (error) {
                console.log(error, "Error in fetch Prices");
                return;
            }
            if (result) {
                // setCoingekoPrice(result)
                console.log(result, "fetchCoingeckoPrices");
            }
        });
    };

    const fetchWhiteListedAsset = () => {
        getWhiteListedAsset().then((res) => {
            console.log(res, "WhiteListedAsset");
            // setTotalReceivable(res?.[0])
            dispatch(setAssetList(res?.accepted_assets));
        }).catch((error) => {
            console.log(error);

        })
    }

    useEffect(() => {
        const savedAddress = localStorage.getItem("ac");
        const userAddress = savedAddress ? decode(savedAddress) : address;
        if (userAddress) {
            dispatch(setAccountAddress(userAddress));
        }
        // fetchCoingeckoPrice();
        fetchWhiteListedAsset();
    }, [])

    useEffect(() => {
        if (address) {
            queryAllBalances(address)
        }
    }, [address, refreshBalance])



    return (
        <>
            <div className="header_main_container">
                <div className="flex_container ">
                    {/* <nav className="navbar max_width_container"> */}
                    <nav className="navbar ">
                        <div className="logo">
                            <Link to={"/"}>
                                {currentRoute === "/" ?
                                    <div className="logo_container">
                                        <img src={HomeIcon} alt="Logo" />
                                    </div>
                                    : currentRoute.startsWith("/c") ?
                                        <div className="logo_container">
                                            <img src={AnatraFiLogo} alt="Logo" />
                                            {/* <div dangerouslySetInnerHTML={{ __html: product2LogoDark }} /> */}
                                        </div> :
                                        <div className="logo_container">
                                            <img src={AnatraCredLogo} alt="Logo" />
                                            {/* <div dangerouslySetInnerHTML={{ __html: product1LogoDark }} /> */}
                                        </div>}
                            </Link>{" "}
                        </div>

                        <div className="nav-links">
                            {/* <div className="search_container">
                                <Search
                                    placeholder="input search text"
                                    allowClear
                                    onSearch={onSearch}
                                    style={{
                                        width: 200,
                                    }}
                                // suffix={suffix}
                                />
                            </div>
                            <div className="notification_container">
                                <Button type="primary" onClick={showDrawer}>
                                    Notification
                                </Button>
                                <Drawer title="Basic Drawer" onClose={onClose} open={open}>
                                    <p>Some contents...</p>
                                    <p>Some contents...</p>
                                    <p>Some contents...</p>
                                </Drawer>
                            </div> */}
                            {/* {address && <div className="account_main_container">
                                <div className="account_container">
                                    <Link to="/account"><Button type="primary" >Account</Button></Link>
                                </div>
                            </div>
                            } */}

                            {address && currentRoute === "/" ?
                                <div className="account_main_container">
                                    <div className="account_container" >
                                        {/* <div dangerouslySetInnerHTML={{ __html: AppSwitchIcon }} /> */}

                                        <Dropdown
                                            menu={{
                                                items: homeDropDown,
                                            }}
                                            className="comdex_dropdown"
                                            overlayClassName="comdex_dropdown_overlay"
                                        >
                                            <div dangerouslySetInnerHTML={{ __html: AppSwitchIcon }} />
                                        </Dropdown>
                                    </div>
                                </div>
                                :
                                address && currentRoute.startsWith("/c") ?
                                    <div className="account_main_container">
                                        <div className="account_container" >
                                            <Dropdown
                                                menu={{
                                                    items: antraFiDropDown,
                                                }}
                                                className="comdex_dropdown"
                                                overlayClassName="comdex_dropdown_overlay"

                                            >
                                                <div dangerouslySetInnerHTML={{ __html: AppSwitchIcon }} />
                                            </Dropdown>
                                        </div>
                                    </div>
                                    :
                                    address && <div className="account_main_container">
                                        <div className="account_container" >

                                            <Dropdown
                                                menu={{
                                                    items: antraCredDropDown,
                                                }}
                                                className="comdex_dropdown"
                                                overlayClassName="comdex_dropdown_overlay"

                                            >
                                                <div dangerouslySetInnerHTML={{ __html: AppSwitchIcon }} />
                                            </Dropdown>
                                        </div>
                                    </div>

                            }

                            {address && <div className="account_main_container">
                                <div className="account_container">
                                    <Link to="/kyc"><Button type="primary" >Account</Button></Link>
                                </div>
                            </div>}

                            <div className="wallet_container">
                                {address ? <>

                                    <Button type="primary" className="connected_wallet_btn" onClick={showDisconnectModal}>
                                        {truncateString(address, 6, 6)}
                                    </Button>
                                    <Modal
                                        title={false}
                                        open={isDisconnectModalOpen}
                                        centered={true}
                                        width={420}
                                        onOk={handleDisconnectOk}
                                        footer={false}
                                        onCancel={handleDisconnectCancel}
                                    >

                                        <div className="dicconnect_modal_main_container">
                                            <div className="disconnect_modal_container">
                                                <div className="profile_container">
                                                    <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/9a3d5ab2904d3fbc093866d5863ecca8037ad819760a1eeb74847bdc2e443ae8?apiKey=0d50848586c3436880ef47e44d650c56&" alt="Profile" />
                                                </div>
                                                <div className="address_container">{truncateString(address, 6, 6)}</div>
                                                <div className="balance_container">{AvailableAssetBalance || 0} CMDX</div>
                                                <div className="button_container">
                                                    <div className="copy_btn_container" onClick={() => { navigator.clipboard.writeText(address) }}>
                                                        {/* <Button type="primary" >Copy Address</Button> */}
                                                        <div className="icon">
                                                            <div dangerouslySetInnerHTML={{ __html: copyIcon }} />                       </div>
                                                        <div className="text">Copy</div>
                                                    </div>
                                                    <div className="disconnect_btn_container" onClick={handleDisconnect}>
                                                        {/* <Button type="primary" onClick={handleDisconnect}>Disconect</Button> */}
                                                        <div className="icon" >
                                                            <div dangerouslySetInnerHTML={{ __html: disconnectIcon }} />                       </div>
                                                        <div className="text">Disconnect</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal>
                                </>
                                    :
                                    <>

                                        <Button type="primary" onClick={showModal}>
                                            Connect
                                        </Button>
                                        <Modal
                                            title={false}
                                            open={isModalOpen}
                                            // open={false}
                                            centered={true}
                                            width="auto"
                                            onOk={handleOk}
                                            footer={false}
                                            onCancel={handleCancel}
                                        >
                                            <Spin spinning={inProgress}>
                                                <div className="connect_wallet_main_container">
                                                    <div className="connect_wallet_container">
                                                        <div className="heading_main_container">
                                                            <div className="heading">Connect Wallet</div>
                                                            <div className="para">
                                                                Choose your network and wallet to connect with
                                                                Truffles
                                                            </div>
                                                        </div>
                                                        <div className="wallet_main_container">
                                                            <div className="wallet_containers">
                                                                <div className="wallet_box">
                                                                    <Card
                                                                        hoverable
                                                                        style={{
                                                                            width: 115,
                                                                            height: 115,
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                        }}
                                                                        onClick={() => handleConnectToWallet('keplr')}
                                                                    >
                                                                        <Meta
                                                                            title={
                                                                                <>
                                                                                    <div className="icon">
                                                                                        <img src={keplrIcon} alt="Keplr" />{" "}
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                            description="Keplr"
                                                                            style={{ textAlign: "center" }}

                                                                        />
                                                                    </Card>
                                                                </div>
                                                                <div className="wallet_box">
                                                                    <Card
                                                                        hoverable
                                                                        style={{
                                                                            width: 115,
                                                                            height: 115,
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                        }}
                                                                        onClick={() => handleConnectToWallet('leap')}
                                                                    >
                                                                        <Meta
                                                                            title={
                                                                                <>
                                                                                    <div className="icon">
                                                                                        <img src={leapIcon} alt="Leap" />{" "}
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                            description="Leap"
                                                                            style={{ textAlign: "center" }}

                                                                        />
                                                                    </Card>
                                                                </div>
                                                                <div className="wallet_box">
                                                                    <Card
                                                                        hoverable
                                                                        style={{
                                                                            width: 115,
                                                                            height: 115,
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                        }}
                                                                        onClick={() => handleConnectToWallet('metamask')}
                                                                    >
                                                                        <Meta
                                                                            title={
                                                                                <>
                                                                                    <div className="icon">
                                                                                        <img src={metaMaskIcon} alt="MetaMask" />{" "}
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                            description="Metamask"
                                                                            style={{ textAlign: "center" }}

                                                                        />
                                                                    </Card>
                                                                </div>
                                                                <div className="wallet_box">
                                                                    <Card
                                                                        hoverable
                                                                        style={{
                                                                            width: 115,
                                                                            height: 115,
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                        }}
                                                                        onClick={() => console.log("Legde")}
                                                                    >
                                                                        <Meta
                                                                            title={
                                                                                <>
                                                                                    <div className="icon">
                                                                                        <img src={ledgerIcon} alt="Keplr" />{" "}
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                            description="Ledger"
                                                                            style={{ textAlign: "center" }}
                                                                        />
                                                                    </Card>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Spin>
                                        </Modal>
                                    </>
                                }

                            </div>
                        </div>
                    </nav>
                    <button className="toggle-button" onClick={() => handleToggle()}>
                        &#9776; Menu
                    </button>
                </div>
            </div>
        </>
    );
};

export default Header;
