import React from 'react'
import './index.scss';
import { Select, Input, Button } from 'antd';
import { SwapOutlined } from '@ant-design/icons';
import { swapIcon } from '../../../utils/icons';

const Swap = () => {
    return (
        <>
            <div className="swap_main_container">
                <div className="swap_container">
                    <h3>Swap</h3>
                    <div className="swap-container">
                        <div className="row_container_with_border">
                            <div className="row">
                                <div className="column">
                                    <Select placeholder="Select Token" defaultValue="token1" popupClassName="comdex_select_dropdown" style={{ width: 180 }} className='comdex_select' >
                                        <Select.Option value="token1">
                                            <div className="token_with_icons">
                                                <div className="icons"><img src="https://dapps-coins.comdex.one/atom.svg" alt="@icon" /></div>
                                                <div className="name">
                                                    <div className="token_name">Atom</div>
                                                    <div className="chain_name">Cosmos Hub</div>
                                                </div>
                                            </div>
                                        </Select.Option>
                                        <Select.Option value="token2">
                                            <div className="token_with_icons">
                                                <div className="icons"><img src="https://dapps-coins.comdex.one/osmo.svg" alt="@icon" /></div>
                                                <div className="name">
                                                    <div className="token_name">Osmo</div>
                                                    <div className="chain_name">Osmosis</div>
                                                </div>
                                            </div>
                                        </Select.Option>
                                    </Select>
                                </div>
                                <div className="column">
                                    <Input placeholder="0.00" min={0} type='number' className='comdex_input' />
                                    <div className="dollor_value">
                                        $1234
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row swap-row">
                            <div className='icon' dangerouslySetInnerHTML={{ __html: swapIcon }} />

                        </div>
                        <div className="row_container_with_border">
                            <div className="row">
                                <div className="column">
                                    <Select placeholder="Select Token" defaultValue="token2" popupClassName="comdex_select_dropdown" style={{ width: 180 }} className='comdex_select' >
                                        <Select.Option value="token1">
                                            <div className="token_with_icons">
                                                <div className="icons"><img src="https://dapps-coins.comdex.one/atom.svg" alt="@icon" /></div>
                                                <div className="name">
                                                    <div className="token_name">Atom</div>
                                                    <div className="chain_name">Cosmos Hub</div>
                                                </div>
                                            </div>
                                        </Select.Option>
                                        <Select.Option value="token2">
                                            <div className="token_with_icons">
                                                <div className="icons"><img src="https://dapps-coins.comdex.one/osmo.svg" alt="@icon" /></div>
                                                <div className="name">
                                                    <div className="token_name">Osmo</div>
                                                    <div className="chain_name">Osmosis</div>
                                                </div>
                                            </div>
                                        </Select.Option>
                                    </Select>
                                </div>
                                <div className="column">
                                    <Input placeholder="0.00" min={0} type='number' className='comdex_input' />
                                    <div className="dollor_value">
                                        $1234
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="selected_asset_details">
                            <div className="left">1 ATOM</div>
                            <div className="right">46677 AKT</div>
                        </div>

                        <Button type="primary" className='btn-primary swap-button'>
                            Swap
                        </Button>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Swap