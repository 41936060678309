import React, { useEffect, useState } from 'react'
import './index.scss';
import { Steps } from 'antd';
import Name from './name';
import Documents from './documents';
import Review from './review';
import Submit from './submit';
import { useDispatch, useSelector } from 'react-redux';
import { setProformaCurrentStep } from '../../../action/proforma';


const Proforma = () => {

    const dispatch = useDispatch();
    const proformaCurrentStep = useSelector(state => state.proforma.proformaCurrentStep);
    const [transactionStatus,setTransactionStatus]=useState("processing")
    const [formData, setFormData] = useState({
        fullname: '',
        email: '',
        number: '',
        companyName: '',
        addressLine1: '',
    });


    const items = [
        {
            title: "Name",
            content: "Hello"
        },
        // {
        //     title: "Docummants",
        //     content: "Doc"
        // },
        {
            title: "Review",
            content: "Review"
        },
        {
            title: "Submit",
            content: "Submit"
        },

    ]

    const steps = [
        {
            title: 'First',
            content: <Name setFormData={setFormData} formData={formData} />,
            // content: <Name />,
        },
        // {
        //     title: 'Second',
        //     content: <Documents />,
        // },
        {
            title: 'Third',
            content: <Review setFormData={setFormData} formData={formData} transactionStatus={transactionStatus} setTransactionStatus={setTransactionStatus}/>,
            // content: <Review />,
        },
        {
            title: 'Last',
            content: <Submit setFormData={setFormData} formData={formData} transactionStatus={transactionStatus} setTransactionStatus={setTransactionStatus} />,
            // content: <Submit />,
        },
    ];

    useEffect(() => {
        dispatch(setProformaCurrentStep(0))
    }, [])


    return (
        <div>
            <div className="proforma_main_container">
                <div className="proforma_container">
                    <Steps
                        size="small"
                        current={proformaCurrentStep}
                        className='comdex_steps'
                        labelPlacement='vertical'
                        items={items}
                    />
                    <div className="performa_content">
                        {steps?.[proformaCurrentStep]?.content || null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Proforma